/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import React from 'react';

interface CountItemProps {
  name: string;
  count: number;
  countCss?: SerializedStyles
}

const containerCss = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const nameCss = css`
  text-transform: capitalize;
  flex-shrink: 0;
  flex-grow: 3;
  width: 60%;
`;

export default function CountItem({ name, count, countCss}: CountItemProps) {
  const countDefaultCss = React.useMemo(() => css`
        display: block;
        margin: 0;
        ${countCss}
    `, [countCss]);

  return (
    <div css={containerCss}>
      <div css={nameCss}>{name}</div>
      <div css={countDefaultCss}>{`(${count})`}</div>
    </div>
  );
}
