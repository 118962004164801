import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "../textFormat.style";
import { TextFormatParser } from "../useTextParser";

export const urlParser : TextFormatParser = {
    type: "URL",
    regex: /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/i,
    render: (url: string) => {
        return <a href={url} target="_blank" css={style.link}>{url}</a>;
    }
}
