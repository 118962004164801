/** @jsx jsx */

import React, { useState } from "react";
import { RenderMarket, computeCategoryLabel, checkClimateAligned } from "./row";
import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import PriceColumn from "./price";
import Props from "../base/props";
import SearchSecurity from "../../../../../interfaces/search/search-security";
import Type from "../type";
import { formatDate } from "../../../../../utils/date";
import { goToSecurityDetailPage } from "../../../SecurityCard";
import { style } from "../base/card-mobile.style";
import { formatToThousandsSeparator } from "../../../../../utils";
import { Collapse } from "react-collapse";
import SearchIssuer from "../../../../../interfaces/search/search-issuer";
import { isMarketSegment, marketSegmentName } from "../../../../../enums/market-segment";
import { computeLabel, marketRuleLabel } from "../../../../../enums";

export const SecuritySearchCard = (
  props: Props<SearchSecurity>
): JSX.Element => {
  const { card } = props;

  const [multipleIssuerOpen, setMultipleIssuerOpen] = useState<boolean>(true);

  if (!card) return <></>;

  const onRowClickHandlerSecurity = (isin: any, id: any) => {
    goToSecurityDetailPage(isin, id);
  };

  const bestOrders = card.marketRealtime?.bestOrders;

  return (
    <div
      css={[style.container, card.status == "RETR" && style.containerDisable]}
    >
      <div css={style.top}>
        <div css={style.left}>
          <Type
            isGreen={card.lgxDisplay || checkClimateAligned(card.issuers)}
            label={computeCategoryLabel(card.category)}
          />
        </div>
        <div css={style.right}>
          <RenderMarket markets={card.marketRule} segment={card.marketSegment} />
        </div>
      </div>
      <div
        onClick={() => onRowClickHandlerSecurity(card.isinCode, card.id)}
        css={style.title}
      >
        {card.name || "-"}
      </div>
      <div css={{ width: "100%" }}>
        <div css={style.label}>
        { ! card.issuers?.length ?
          <span css={[style.dateValue, style.noIssuer]}>No issuer</span>
          :
          <>
            { (card.issuers?.length == 1) ?
              <span css={style.dateValue}>{card.issuers[0].name}</span>
              :
              <>
                <div css={[style.dateValue, style.multipleIssuer]} onClick={() => setMultipleIssuerOpen(!multipleIssuerOpen)}>
                  <span>Multiple issuers <Icon icon={multipleIssuerOpen ? 'chevron-up' : 'chevron-down'}/></span>
                </div>
                <Collapse isOpened={! multipleIssuerOpen}>
                  <div>
                    <ul css={style.mutipleIssuerList}>
                      { card.issuers.map((issuer: SearchIssuer, index: number) => (
                        <li css={style.mutipleIssuerItem}>{issuer.name}</li>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              </>
            }
          </>
        }
        </div>
      </div>
      <div css={style.table}>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>ISIN</div>
            <div css={style.colBottom}>{card.isinCode || "-"}</div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Market/Listing/Segment</div>
            <div css={style.colBottom}>
              {
                isMarketSegment(card?.marketSegment) ? (
                  <div css={style.market}>{marketSegmentName(card?.marketSegment)}</div>
                ) : 
                card?.marketRule ? (
                  <div css={style.market}>{marketRuleLabel(card?.marketRule)}</div>
                ) : (
                  "-"
                ) 
              }
              
            </div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col]}>
            <div css={style.colTop}>Listing date</div>
            <div css={style.colBottom}>{formatDate(card.listingDate)}</div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Amount</div>
            <div css={style.colBottom}>{card?.issuedNominalAmount ? `${formatToThousandsSeparator(card.issuedNominalAmount)}`: "-"}</div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>CCY</div>
            <div css={style.colBottom}>
              <div css={style.colBottom}>{card.currency || "-"}</div>
            </div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Last Price</div>
            <div css={style.colBottom}>
              {card?.marketData?.lastPrice ? (
                <PriceColumn {...card.marketData?.lastPrice} color="#354450" />
              ) : (
                "-"
              )}
            </div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Vari. 24h</div>
            <div css={style.colBottom}>
              {card?.marketData?.lastPrice?.changePercent ? (
                <>
                  <Icon
                    icon={
                      card.marketData.lastPrice.changePercent < 0
                        ? "chevron-down"
                        : "chevron-up"
                    }
                  />
                  {` ${card.marketData.lastPrice.changePercent} %`}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>

        <div
          css={[
            style.row,
            css`
              border-bottom: none;
            `,
          ]}
        >
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopRight}>Coupon</div>
            <div css={style.colBottomRight}>
              {(card?.interestRate && `${card.interestRate} %`) || "-"}
            </div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopLeft}>Yield</div>
            <div css={style.colBottomLeft}>
              {card?.marketData?.yieldToMaturity
                ? `${card.marketData.yieldToMaturity} %`
                : "-"}
            </div>
          </div>
        </div>
        <div
          css={[
            style.row,
            css`
              background: rgba(246, 248, 249, 1);
            `,
          ]}
        >
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopRight}>BID</div>
            <div css={style.colBottomRight}>
              {bestOrders?.bid?.length && bestOrders.bid[0] ? (
                <>
                  <div>
                    {formatToThousandsSeparator(
                      parseFloat(bestOrders.bid[0].price)
                    )}
                  </div>
                  <div>{bestOrders.bid[0].quantity}</div>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopLeft}>ASK</div>
            <div css={style.colBottomLeft}>
              {bestOrders?.ask?.length && bestOrders.ask[0] ? (
                <>
                  <div>
                    {formatToThousandsSeparator(
                      parseFloat(bestOrders.ask[0].price)
                    )}
                  </div>
                  <div>{bestOrders.ask[0].quantity}</div>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySearchCard;