import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "../textFormat.style";
import { TextFormatParser } from "../useTextParser";

export const emailParser : TextFormatParser = {
    type: "EMAIL",
    regex: /([\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+)/i,
    render: (email: string) => {
        return <a href={`mailto:${email}`} css={style.link}>{email}</a>;
    }
}
