
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue, RichText } from '@sitecore-jss/sitecore-jss-react';
import { SitecoreGenericTextField } from '../../interfaces';
import { breakpointMax } from "../../style";

export interface CaseStudiesTextProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
export const CaseStudiesText: React.FunctionComponent<CaseStudiesTextProps> = (props: CaseStudiesTextProps): React.ReactElement => {
    const { fields } = props;

    const title: string = getFieldValue<string>(fields, "title", "");

    const container = css`
        width: 1152px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 19px;
        align-self: stretch;
        margin-top: 50px;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            padding: 8px;
            margin-top: 0px;
        }
    `;
    const mainTitle = css`
        color: #425563;
        font-size: 48px;
        font-weight: 700;
        line-height: normal;
        margin-top: 32px;
        align-self: flex-start;
        @media (max-width: ${breakpointMax}) {
            font-size: 34px;
        }
    `;
    const mainDescription = css`
        color: #425563;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.16px;
        margin-bottom: 16px;
        @media (max-width: ${breakpointMax}) {
            font-size: 16px;
        }
    `;

    return (
        <div css={container}>
            <div css={mainTitle}>
                {title}
            </div>
            <div css={mainDescription}>
                <RichText
                    field={fields && fields.description as SitecoreGenericTextField}
                />
            </div>
        </div>
    );
}
    