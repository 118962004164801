import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./textFormat.style";
import useTextParser from "./useTextParser";

export interface TextFormatProps {
  text?: string;
}

const TextFormat: React.FunctionComponent<TextFormatProps> = ({text}: TextFormatProps) => {

  const {parseText} = useTextParser();

  return (
    <div css={style.text}>
      {parseText(text)?.map(item => item.parser.render(item.text))}
    </div>
  );
};

export default TextFormat;
