/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpointDesktopLarge, breakpointDesktopLarger, breakpointDesktopLargest, breakpointDesktopMedium, breakpointDesktopXLarge, breakpointMax } from "../../style";
import { Button, Loader } from "@bdl-cmn-shared-packages-npm/design-system";
import { fetchChildPages, fetchLayoutData, renderLink } from "../../utils/helper";

export interface CaseStudiesLatestStoriesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const CaseStudiesLatestStories: React.FunctionComponent<CaseStudiesLatestStoriesProps> =
  (props: CaseStudiesLatestStoriesProps): React.ReactElement => {
    const { fields } = props;

    const title: string = getFieldValue<string>(fields, "title", "");
    const [caseStudies, setCaseStudies] = React.useState<any[]>([]);
    const [isLoadingCaseStudies, setIsLoadingCaseStudies] = React.useState<boolean>(true);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [transformX, setTransformX] = React.useState(0);

    const handleLeftClick = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
        setTransformX(transformX + 391);
      }
    };

    const handleRightClick = () => {
      if (currentIndex < caseStudies.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setTransformX(transformX - 391);
      } else if (transformX === -2492) {
        setTransformX(transformX - 391);
      }
    };

    const parentItemId = React.useMemo(() => "8DF978DC-CA76-451C-B57F-75BC7A5AF26D", [] );
    const fetchChildPage = React.useCallback(() => fetchChildPages(parentItemId), [parentItemId] );

    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const childItemIds = await fetchChildPage();

          if (childItemIds.length === 0) {
            setIsLoadingCaseStudies(false);
            return;
          }

          const allExtractedFields: any[] = [];
          for (const itemId of childItemIds) {
            const extractedFields = await fetchLayoutData(itemId);
            allExtractedFields.push(...extractedFields);
          }
          setCaseStudies(allExtractedFields);
          setIsLoadingCaseStudies(false);
        } catch (err) {
          setIsLoadingCaseStudies(false);
        }
      };
      fetchData();
    }, []);

    const parentContainer = css`
      width: 100%;
      max-width: 1152px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: flex-start;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
        flex-direction: column;
      }
    `;
    const container = css`
      width: 100%;
      padding-top: 80px;
      padding-bottom: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 0px;
      }
    `;
    const topSection = css`
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
    `;
    const navigationButtons = css`
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: ${breakpointMax}) {
        display: none;
      }
    `;
    const buttonArrows = css`
      width: 40px;
      height: 40px;
      bottom: 0;
      right: 0;
      display: flex;
      background: #238750 !important;
      border-radius: 50%;
      text-align: center;
      padding: 0 !important;
      box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 3px 5px -1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.25s ease-out;
      :hover {
        background: #f6f8f9;
        opacity: 0.6;
      }
      :disabled {
        ackground-color: #238750 !important;
        opacity: 0.3;
        border-radius: 50%;
        :hover {
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        }
      }
      svg {
        color: #ffffff;
        width: 18px !important;
        height: 18px !important;
      }
      .css-1bh9syo {
        margin-left: 0;
      }
    `;
    const mainTitle = css`
      flex: 1 0 0;
      color: #253845;
      font-size: 36px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 32px;
      align-self: flex-start;
      @media (max-width: ${breakpointMax}) {
        font-size: 36px;
      }
    `;
    const storiesContainer = css`
      display: flex;
      flex-direction: row;
      gap: 24px;
      position: relative;
      overflow: hidden;
      width: inherit;
      transition: transform 0.5s ease;
      justify-content: flex-start !important;
      transform: translateX(${transformX}px);
      overflow: ${transformX < 0 ? "visible" : "hidden"};

      ${transformX === 0 &&
      `
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                    }
                }
            `}

      ${transformX === -391 &&
      `
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -65%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                    }
                  @media (min-width: ${breakpointDesktopMedium}) {
                    right: -66%;
                    }
                  @media (min-width: ${breakpointDesktopLarge}) {
                    right: -85%;
                    }
                  @media (min-width: ${breakpointDesktopLarger}) {
                    right: -88%;
                    }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    right: -112%;
                    }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    right: -163%;
                    }
                }
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 2%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                    }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    left: 0%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                    }
                }
            `}
            ${transformX === -782 &&
      `
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -93%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  } 
                  @media (min-width: ${breakpointDesktopLarge}) {
                    right: -137%;
                 }
                  @media (min-width: ${breakpointDesktopLarger}) {
                    right: -141%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    right: -164%;
                  }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    right: -214%;
                  }
                }
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 36%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  }
                 @media (min-width: ${breakpointDesktopLarge}) {
                    left: 72%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    left: 1%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    left: 0%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                }
            `}
    
            ${transformX === -1173 &&
      `
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 70.5%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  }
                  @media (min-width: ${breakpointDesktopLarge}) {
                    left: 124.5%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    left: 34.5%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    left: 0%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                }  
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -126%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  } 
                  @media (min-width: ${breakpointDesktopLarge}) {
                    right: -189%;
                 }
                  @media (min-width: ${breakpointDesktopLarger}) {
                    right: -191%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    right: -218%;
                  }
                  // @media (min-width: ${breakpointDesktopXLarge}) {
                  //   right: -240%;
                  // }
                }
            `}
    
            ${transformX === -1564 &&
      `
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 104.5%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  }
                  @media (min-width: ${breakpointDesktopLarge}) {
                    left: 177.5%;
                  }
                  @media (min-width: ${breakpointDesktopLarger}) {
                    left: 176.5%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    left: 67.5%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  } 
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    left: 1.5%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                    
                  }
                }
               &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -216%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  } 
                  @media (min-width: ${breakpointDesktopLarge}) {
                    right: -212%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    right: unset;
                  }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    right: -240%;
                  }
                }     
            `}
            ${transformX === -1955 &&
      `
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 138.5%;
                  bottom: 0;
                  width: 30%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  @media (max-width: ${breakpointMax}) {
                    display: none;
                  }
                  @media (min-width: ${breakpointDesktopLarge}) {
                    left: 228.5%;
                  }
                  @media (min-width: ${breakpointDesktopLarger}) {
                    left: 229.5%;
                  }
                  @media (min-width: ${breakpointDesktopLargest}) {
                    left: 99.5%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                  @media (min-width: ${breakpointDesktopXLarge}) {
                    left: 34.5%;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                  }
                }
                }  
            `}
            @media (max-width: ${breakpointMax}) {
              flex-direction: row;
              width: 100%;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              transform: none;
            }
    `;
    const storyCard = css`
      display: flex;
      min-width: 368px;
      max-width: 368px;
      height: 483px;
      padding-top: 96px;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 32px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #e1e3e5;
      background: #ffffff;
      text-overflow: ellipsis;
      overflow: hidden;
      :hover {
        opacity: 0.6;
      }
    `;
    const logo = css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: ${breakpointMax}) {
      }
    `;
    const storyTitle = css`
      align-self: stretch;
      color: #2e3943;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
    `;
    const storyDescription = css`
      align-self: stretch;
      color: #2e3943;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    `;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={topSection}>
            <div css={mainTitle}>{title}</div>
            {isLoadingCaseStudies === false && (
              <div css={navigationButtons}>
                <Button
                  css={buttonArrows}
                  iconRight={"chevron-left"}
                  variant={undefined}
                  onClick={handleLeftClick}
                  disabled={currentIndex === 0}
                />
                <Button
                  css={buttonArrows}
                  iconRight={"chevron-right"}
                  variant="primary"
                  onClick={handleRightClick}
                  disabled={transformX === -1840}
                />
              </div>
            )}
          </div>
          <Loader loading={isLoadingCaseStudies} centered css={{ minHeight: 400, gap: 24 }}>
            <div css={storiesContainer} id="caseStudies">
              {caseStudies?.map((story, index) => (
                <div key={index} css={storyCard} style={{background: `linear-gradient(0deg, #FFF 48.55%, rgba(255, 255, 255, 0.00) 81.65%), url(${renderLink(story.caseStudiesMainImage.src)}) lightgray 50% / cover no-repeat`,}}>
                  <div css={{ marginRight: "auto" }}>
                    <div css={logo}>
                      {story.caseStudiesLogo && (
                        <img
                          src={renderLink(story.caseStudiesLogo.src)}
                          height="105px"
                          width="185px"
                          style={{
                            background: "#FFFFFF",
                            borderRadius: "16px",
                            paddingTop: "16px",
                            paddingBottom: "16px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div css={storyTitle}>{story.caseStudiesTitle}</div>
                  <div css={storyDescription}>
                    {story.caseStudiesShortDescription}
                  </div>
                </div>
              ))}
            </div>
          </Loader>
        </div>
      </div>
    );
  };
