import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Td, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../base/index.style';
import Props from '../base/props';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import lgxLogo from './lgxlogo.png';
import { data as dataMapper } from '../../../Search/results/dataMapper';
import PriceColumn from './price';
import { formatDate } from '../../../../../utils/date';
import Type from '../type';
import { computeLabel } from '../../../../../enums';
import { renderLink } from '../../../../../utils/helper';
import { goToSecurityDetailPage } from '../../../SecurityCard';
import SearchIssuer from '../../../../../interfaces/search/search-issuer';
import { formatToThousandsSeparator } from '../../../../../utils';
import ReactTooltip from 'react-tooltip';
import { isMarketSegment, marketSegmentName } from '../../../../../enums/market-segment';

export const RenderMarket = ({ markets, segment }: any) => {
  let label = "";
  if(isMarketSegment(segment))
    label = marketSegmentName(segment);
  else
    label = computeLabel(markets);
  return label ? <div css={style.market}>{label}</div> : <div>N/A</div>;
};

export const SearchSecurityGreen = ({ security }: { security: SearchSecurity }) => {
  return security?.lgxDisplay ? (
    // <div css={[style.rowTag, style.rowTagGreen]}>
    //   <img css={style.rowTagImg} src={renderLink(lgxLogo)} />
    //   <span css={style.rowTagText}>LGX</span>
    // </div>
    <div
      css={[
        style.market,
        css`
          margin-top: 10px;
          font-size: 0.813em;
        `,
      ]}
    >
      <img css={style.rowTagImg} src={renderLink(lgxLogo)} alt={"image"} />
      LGX
    </div>
  ) : (
    <></>
  );
};

export const computeCategoryLabel = (category: string) => {
  switch (category) {
    case 'ACT':
    case 'EQUITY':
      return 'E';
    case 'WAR':
    case 'WARRANT':
      return 'W';
    case 'OBL':
    case 'DEBT':
      return 'B';
    case 'CER':
    case 'CERTIFICATE':
      return 'C';
    case 'OPC':
    case 'FUND':
      return 'F';
    default:
      return category;
  }
};

export const computeCategoryTitle = (category: string) => {
  switch (category) {
    case 'ACT':
        return'Equity';
    case 'EQUITY':
      return 'Equity';
    case 'WAR':
    case 'WARRANT':
      return 'Warrant';
    case 'OBL':
        return 'Bond';
    case 'DEBT':
      return 'Bond';
    case 'CER':
    case 'CERTIFICATE':
      return 'Certificate';
    case 'OPC':
        return 'Fund'
    case 'FUND':
      return 'Fund';
    default:
      return category;
  }
};

export const checkClimateAligned = ( issuers: SearchIssuer[] ) => {
  if( ! issuers || ! issuers.length )
    return false;

  let cai = false;
  try{
    issuers.forEach(( issuer: SearchIssuer ) => {
      if( issuer.climateAligned )
        cai = true;
    });
  }catch( error ){

  }

  return cai;
};

export const SecurityRow = ({ row, selectedCols, color, rowKey }: Props<SearchSecurity>) => {
  const onRowClickHandlerSecurity = (isin: any, id: any) => {
    goToSecurityDetailPage(isin, id);
  };

  return (
    <Tr css={[style.actionsRow, row.status == "RETR" && style.disableRow]} 
        onRowClick={() => onRowClickHandlerSecurity(row.isinCode, row.id)} 
        key={rowKey? rowKey : row.id}>
      <Td>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Type isGreen={row.lgxDisplay} title={computeCategoryTitle(row.category)} label={computeCategoryLabel(row.category)} />
          <SearchSecurityGreen security={row} />
        </div>
      </Td>
      <Td>
          <RenderMarket markets={row.marketRule} segment={row.marketSegment}/>
      </Td>
      <Td>
        { ! row.issuers?.length ?
          <div css={[style.align, style.noIssuer]}>No issuer</div>
          :
          <>
            { (row.issuers?.length == 1) ?
              <div css={style.align}>{row.issuers[0].name}</div>
              :
              <>
                <div css={[style.align, style.multipleIssuer]} data-tip="" data-for={`programme-${row.id}-multi-issuer`}>
                  <span>Multiple issuers <Icon icon={'chevron-down'}/></span>
                </div>
                <ReactTooltip
                  id={`programme-${row.id}-multi-issuer`}
                  css={style.tooltip}
                  data-html={true}
                  insecure={true}
                  multiline={true}
                  place="bottom"
                  effect="solid">
                  <ul css={style.tooltipList}>
                    { row.issuers.map((issuer: SearchIssuer, index: number) => (
                      <li>{issuer.name}</li>
                    ))}
                  </ul>
                </ReactTooltip>
              </>
            }
          </>
        }
      </Td>
      <Td>{row.isinCode}</Td>
      <Td>{row.name}</Td>
      <Td>{row && row?.issuedNominalAmount ? `${formatToThousandsSeparator(row.issuedNominalAmount)}` : 'N/A'}</Td>
      <Td>{row && row?.currency ? row.currency : 'N/A'}</Td>
      <Td>{formatDate(row.listingDate)}</Td>
      <Td>{row && row?.interestRate ? `${row.interestRate} %` : 'N/A'}</Td>
      <Td>{row?.marketData?.lastPrice ? <PriceColumn {...row.marketData?.lastPrice} color={color} /> : 'N/A'}</Td>

      {selectedCols ? (
        selectedCols['securities']?.map((col: string, index: number) => {
          return (
            col.toLowerCase() !== 'id' && (
              <Td key={rowKey? `${rowKey}-col-selected-${index}`: `${rowKey}-col-selected-${index}`}>
                {dataMapper.securities[col] && dataMapper.securities[col].render((row as any)[col])}
              </Td>
            )
          );
        })
      ) : (
        <></>
      )}
    </Tr>
  );
};
