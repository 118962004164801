import * as React from "react";
import {css} from "@emotion/react";

export const style = {
    text: css`
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #354450;
    `,

    link: css` {
        color: #22aa5f;
        font-size: 1em;
        font-weight: 600;
        line-height: 20px;
        text-decoration: underline;
        cursor: pointer;

        :hover {
          color: #0d8e46;
        }
        :active {
          color: #075128;
        }
      }
    `,
}