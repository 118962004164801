
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";
import {breakpointMax} from "../../style";
import Loader from "../../core/components/Loader/loader.component";
import { useState } from "react";
import {getAuthors} from "../../utils/blog";
import {BlogTopContainer} from "../BlogAuthorComponent";

export interface BlogAuthorsListProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}


const parentContainer = css`
  width: 100%;
`;

const container = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const cardsContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: 40px; 
  max-width: 780px;
  margin: 0 auto;
  align-items: flex-start;

  @media (max-width: ${breakpointMax}) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    width: 100%;
  }
`;
const columnStyles = css`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
    margin-bottom: 16px;
    flex-basis: 100%;
    width: 100%;
  }
`;

const articleLeft = css`
  display: flex;
  width: 100%;
  justify-content: center;
  max-height: 200px;
  margin-bottom: 40px;
  
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    height: 100%;
  }
`;

const authorImage = css`
  height: 100%;
  max-height: 200px;
`;

const articleRight = css`
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;

const articleTitle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
  }
`;

const articleContent = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #253845;
  }
`;

const articleContainer = css`
  padding: 32px 16px;
  background: #FFFFFF;
  border: 1px solid #D9DEE2;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-decoration: none;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
  }
`;

const title = css`
  h1 {
    font-weight: 800;
    font-size: 44px;
    line-height: 56px;
    color: #253845;
    margin: 0;
    margin-bottom: 8px;
  }
  
  @media (max-width: ${breakpointMax}) {
    padding-left: 8px;
    h1 {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      margin: 0;
      margin-bottom: 8px;
      color: #253845;
    }
  }
`;

const description = css`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #425563;
  margin-bottom: 40px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #425563;
    padding-left: 8px;
  }
`;

const RenderChild = ({data, key}: any) => {
    return (
        <div css={columnStyles} key={key}>
            <a 
                href={data?.firstName 
                    ? `/blog/authors/${data?.firstName}-${data.lastName}` 
                    : `/blog/authors/${data.lastName}`} 
                css={articleContainer}
            >
                <div css={articleLeft}>
                    <img 
                        css={authorImage} 
                        src={renderLink(data?.pictureUrl)} 
                        alt={data?.firstName 
                            ? `/${data?.firstName} ${data.lastName}` 
                            : `/${data.lastName}`} 
                    />
                </div>
                <div css={articleRight}>
                    <div css={articleTitle}>
                        {data?.firstName ? `${data?.firstName} ${data.lastName}` : data.lastName}
                    </div>
                    <div css={articleContent}>
                        {data?.role}
                    </div>
                </div>
            </a>
        </div>
    )
}

export const BlogAuthorsList: React.FunctionComponent<BlogAuthorsListProps> = (props: BlogAuthorsListProps): React.ReactElement => {
    const {fields} = props;
    const [loader, setLoader] = useState<boolean>(true);
    const [authors, setAuthors] = useState<any>([]);

    React.useEffect(() => {
       getAuthors().then((res: any) => {
           setAuthors(res);
           setLoader(false);
       })
    }, []);

    return (
        <div css={parentContainer}>
            <div css={BlogTopContainer}>
                <div css={container}>
                    <div css={title}>
                        <Text
                            tag="h1"
                            field={fields.title as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={description}>
                        <Text
                            field={fields.description as SitecoreGenericTextField}
                        />
                    </div>
                        <Loader loading={loader}>
                            <div css={cardsContainer}>

                            {
                                (authors || []).map((data: any, index: number) => (
                                    <RenderChild data={data} key={`loop_${index}`}/>
                                ))
                            }
                            </div>
                        </Loader>
                </div>
            </div>
        </div>
    );
}
    