import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx, keyframes } from "@emotion/react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import hexaFull from "./hexa-full.svg";
import hexaLight from "./hexaEmptyLight.svg";
import { renderLink } from "../../utils/helper";
import { style } from "./index.style";

/** @jsx jsx */

export interface CarousselContentProps {
  fields: any;
  params?: ComponentParams;
  imageLeft?: string;
  buttonLink?: string;
  buttonLabel?: string;
  buttonIcon?: string;
  imageCenter?: string;
  isGreen?: boolean;
  isLightGray?: boolean;
  className?: string;
  itemCounter?: number;
  selected?: number;
  rendering: ComponentRendering;
  backgroundFilter?: boolean;
}

export const CarousselContent: React.FunctionComponent<CarousselContentProps> =
  (props: CarousselContentProps): React.ReactElement => {
    const {
      fields,
      className,
      imageLeft,
      imageCenter,
      isGreen,
      isLightGray,
      buttonLink,
      buttonLabel,
      buttonIcon,
      backgroundFilter,
    } = props;

    const left = css`
      position: relative;
      width: 735px;
      align-items: center;
      justify-content: center;
      display: flex;
      @media (max-width: ${breakpointMax}) {
        height: 400px;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
      }
    `;   
    const sliderContent = css`
      position: relative;
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
      background: ${isGreen
        ? "linear-gradient(270deg, #008C3D 0.46%, #008C3D 64.43%), #008C3D"
        : backgroundFilter 
        ? "linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)"
        : isLightGray
        ? "linear-gradient(82deg, #ECEFF1 0%, rgba(236, 239, 241, 0.30) 53.76%)"
        : "linear-gradient(270deg, #425563 0.46%, #425563 64.43%), #008C3D"};

      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
        background: ${isGreen
          ? "linear-gradient(0deg, #008C3D 0.46%, #008C3D 64.43%), #008C3D"
          : isLightGray
          ? "linear-gradient(82deg, #ECEFF1 0%, rgba(236, 239, 241, 0.30) 53.76%)"
          : "linear-gradient(0deg, #425563 0.46%, #425563 64.43%), #008C3D"};
      }
      transition: all 0.6s ease-in-out;
    `;
    const image = css`
      position: absolute;
      z-index: 2;
      max-width: 140px;
      @media (max-width: ${breakpointMax}) {
      }
    `;
    const dots = css`
      display: flex;
      margin-bottom: 16px;
      @media (max-width: ${breakpointMax}) {
        margin-top: 16px;
      }
    `;
    const polygon =
      "polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%)"
    ;
    const logoContainer = css`
      z-index: 6;
      position: absolute;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 170px;
      height: 170px;
      background-color: #ffffff;
      clip-path: ${polygon};
      left: calc(50% + 105px);
      top: calc(50% + 40px);
      @media (max-width: ${breakpointMax}) {
        width: 150px;
        height: 150px;
        left: calc(50% + 30px);
        top: calc(50% + 5px);
      }
    `;
    const imgContainer = css`
      z-index: 5;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 425px;
      height: 425px;
      clip-path: ${polygon};
      overflow: hidden;
      @media (max-width: ${breakpointMax}) {
        margin-left: 16px;
        width: 300px;
        height: 300px;
      }
    `;
    const hexaFullStyle = css`
      position: absolute;
      width: 200px;
      left: calc(50% + -300px);
      top: -2%;
      img {
        width: 100%;
      }
      @media (max-width: ${breakpointMax}) {
        display: none;
      }
    `;
    const hexaFullStyleSmall = css`
      position: absolute;
      width: 100px;
      left: calc(50% + 211px);
      top: 36%;
      img {
        width: 100%;
      }
      @media (max-width: ${breakpointMax}) {
        display: none;
      }
    `;

    const imgStyle = css`
      @media (max-width: ${breakpointMax}) {
        height: 300px;
      }
    `;
    const description = css`
    line-height: 23px;
    font-size: 18px;
    font-weight: 600;
    color: ${backgroundFilter ? "#425563" : isLightGray ? "#425563" : "#ffffff"};
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    @media (max-width: ${breakpointMax}) {
      margin-top: 14px !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 180%;
    }
    `; 
    const sign = css`
    line-height: 23px;
    font-size: 18px;
    font-weight: 800;
    color: ${backgroundFilter ? "#425563" : isLightGray ? "#425563" : "#ffffff"};
    margin-top: 16px;
    margin-bottom: 16px;
    @media (max-width: ${breakpointMax}) {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  `;
  
    return (
      <div css={sliderContent} className={className}>
        <div css={left}>
          <div css={imgContainer}>
            <img src={imageLeft} css={imgStyle} alt={"image"} />
          </div>
          <div css={logoContainer}>
            <img src={renderLink(imageCenter)} css={image} alt={"image"} />
          </div>
          <div css={hexaFullStyle}>
            <img src={renderLink(hexaFull)} alt={"image"} />
          </div>
          <div css={hexaFullStyleSmall}>
            <img src={renderLink(hexaFull)} alt={"image"} />
          </div>
        </div>
        <div css={style.right}>
          <div css={dots}></div>
          {fields && fields.heading?.value && (
            <div css={style.headTitle}>
              <Text
                field={fields && (fields.heading as SitecoreGenericTextField)}
              />
            </div>
          )}
          {fields && fields.title?.value && (
            <div css={style.title}>
              <Text
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
          )}

          <div
            css={description}
            style={{
              marginTop:
                fields && fields.heading?.value && fields.title?.value
                  ? "unset"
                  : undefined,
            }}
          >
            <Text
              field={fields && (fields.description as SitecoreGenericTextField)}
            />
          </div>
          <div css={sign}>
            <Text
              field={fields && (fields.author as SitecoreGenericTextField)}
            />
          </div>
          {buttonLabel && (
            <div css={style.action}>
              <Button
                label={buttonLabel}
                iconRight={buttonIcon as any}
                onClick={() =>
                  buttonLink ? (window.location.href = buttonLink) : console.debug
                }
              />
            </div>
          )}
        </div>
        <div css={style.hexa}>
          <img src={renderLink(hexaLight)} alt={"image"} />
        </div>
      </div>
    );
  };
