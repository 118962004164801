/** @jsx jsx */
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { css, jsx } from "@emotion/react";
import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";

export interface CaseStudiesBoxProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const CaseStudiesBox: React.FunctionComponent<CaseStudiesBoxProps> = (
  props: CaseStudiesBoxProps
): React.ReactElement => {
  const { fields } = props;
  const direction = getFieldValue<string>(fields, "layout-direction", "row");
  const backgroundColor = getFieldValue<string>(
    fields,
    "background-color",
    "white"
  );
  const isLogo = getFieldValue<boolean>(fields, "isLogo", false);
  const isTitleBlack = getFieldValue<boolean>(fields, "isTitleBlack", false);
  const isSubtitleBlack = getFieldValue<boolean>(
    fields,
    "isSubtitleBlack",
    false
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isSingleBox, setIsSingleBox] = React.useState(false);
  
  const subtitleText = getFieldValue<string>(fields, "subtitle", "");
  
  const getImageHeightRatio = () => {
    if (direction !== "column") return "50%";
    
    if (subtitleText.length > 35) {
      const reductionFactor = Math.min(Math.floor(subtitleText.length / 35), 3);
      return `${50 - (reductionFactor * 15)}%`;
    }
    return "50%";
  };

  const getTextHeightRatio = () => {
    if (direction !== "column") return "50%";
    return `${100 - parseInt(getImageHeightRatio())}%`;
  };

  React.useEffect(() => {
    if (containerRef.current) {
      setIsSingleBox(containerRef.current.childElementCount === 1);
    }
  }, []);

  const cardContainer = css`
    display: flex;
    flex-direction: ${direction === "row" ? "row" : "column"};
    width: 100%;
    height: 240px;
    background: ${backgroundColor === "green"
      ? "linear-gradient(131.24deg, #11994C -4.59%, #063319 137.46%)"
      : backgroundColor === "red"
      ? "linear-gradient(131.24deg, #CB1234 -4.59%, #6B091B 137.46%)"
      : backgroundColor === "grey"
      ? "linear-gradient(180deg, #9AA7B3 0%, #495966 100%)"
      : "#EBEDEF"};
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 0px;
  `;

  const cardContent = css`
    flex: 1;
    width: ${isSingleBox ? "50%" : "auto"};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px;
    box-sizing: border-box;
    position: relative;

    &:first-child {

      height: ${direction === "column" ? getTextHeightRatio() : "100%"};
    }

    &:last-child {

      height: ${direction === "column" ? getImageHeightRatio() : "100%"};
      padding: 0;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: ${direction === "row" ? "0 10px 10px 0" : "0 0 10px 10px"};
      overflow: visible; 
    }
  `;

  const title = css`
    color: ${isTitleBlack ? "#1E262E" : "white"};
    font-size: clamp(34px, 3vw, 42px);
    font-weight: 800;
    letter-spacing: 0.01em;
    margin: 20px 20px 0px 20px;
    padding: 0px 20px 0px 0px
  `;

  const subtitle = css`
    color: ${isSubtitleBlack ? "#495966" : "white"};
    font-size: clamp(10px, 1.4vw, 14px);
    font-weight: 100;
    line-height: 1.2;
    margin: 8px 10px 8px 20px;
  `;

  const image = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0;
    padding: 0;
  `;

  const logo = css`
    width: 80px;
    height: 80px;
    object-fit: contain;
    display: block;
    margin: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  `;

  return (
    <div ref={containerRef} css={cardContainer}>
      <div css={cardContent}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={subtitle}>
          <Text
            field={fields && (fields.subtitle as SitecoreGenericTextField)}
          />
        </div>
      </div>
      <div css={cardContent}>
        {isLogo ? (
          <img
            css={logo}
            src={renderLink(fields?.imageOrLogo?.value?.src)}
            alt={fields?.imageOrLogo?.value?.alt}
          />
        ) : (
          <img
            css={image}
            src={renderLink(fields?.imageOrLogo?.value?.src)}
            alt={fields?.imageOrLogo?.value?.alt}
          />
        )}
      </div>
    </div>
  );

};