import * as React from 'react';
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarousselContent } from '../CarousselContent';
import { breakpointMax } from '../../style';

export interface CarousselWithPictureProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const CarousselWithPicture: React.FunctionComponent<CarousselWithPictureProps> = (
  props: CarousselWithPictureProps
): React.ReactElement => {
  const isExperienceEditorActivated: boolean = isExperienceEditorActive();
  const { fields, params, rendering } = props;
  const isGreen: number = getFieldValue<number>(fields, 'isGreen', 0);
  const isLightGray: number = getFieldValue<number>(fields, 'isLightGray', 0);
  const backgroundFilter: number = getFieldValue<number>(fields, 'backgroundFilter', 0);

  const container = css`
    width: 100%;
    min-height: 500px;
    .slick-dots {
      position: absolute;
      bottom: 416px;
      padding-left: 95px;
      @media (max-width: ${breakpointMax}) {
        bottom: 565px;
        padding-left: 0px;
      }
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: black;
      background: ${backgroundFilter ? "#12CA64" : "#22aa5f"};
      height: 3px;
      width: 48px;
    }
    .slick-dots li button:before {
      position: absolute;
      top: 0;
      left: 0;
      background: ${backgroundFilter ? "#C7D64D" : isLightGray ? "rgba(35, 153, 88, 0.12)" : "#ffffff"};
      height: 3px;
      width: 48px;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 50px;
      height: 3px;
      padding: 0 !important;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button:before {
      opacity: 1;
    }
    .slick-dots li button:before {
      font-size: 0;
    }
  `;

  return (
    <div css={container}>
      {isExperienceEditorActivated ? (
        <>
          {new Array(6).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`description ${index + 1}`] &&
              (fields && (fields[`description ${index + 1}`] as any)).value
            ) {
              return (
                <CarousselContent
                  rendering={rendering}
                  fields={{
                    heading: {
                      value:
                        (fields &&
                          fields[`heading ${index + 1}`] &&
                          (fields && (fields[`heading ${index + 1}`] as any)).value) ||
                        null,
                    },
                    title: {
                      value:
                        (fields &&
                          fields[`title ${index + 1}`] &&
                          (fields && (fields[`title ${index + 1}`] as any)).value) ||
                        null,
                    },
                    description: {
                      value:
                        (fields &&
                          fields[`description ${index + 1}`] &&
                          (fields && (fields[`description ${index + 1}`] as any)).value) ||
                        null,
                    },
                    author: {
                      value:
                        (fields &&
                          fields[`author ${index + 1}`] &&
                          (fields && (fields[`author ${index + 1}`] as any)).value) ||
                        null,
                    },
                  }}
                  buttonLink={
                    fields &&
                    fields[`button link ${index + 1}`] &&
                    (fields && (fields[`button link ${index + 1}`] as any)).value
                  }
                  buttonLabel={
                    fields &&
                    fields[`button label ${index + 1}`] &&
                    (fields && (fields[`button label ${index + 1}`] as any)).value
                  }
                  buttonIcon={
                    (fields &&
                      fields[`button icon ${index + 1}`] &&
                      (fields && (fields[`button icon ${index + 1}`] as any)).value) ||
                    ''
                  }
                  imageLeft={
                    fields &&
                    fields[`left image ${index + 1}`] &&
                    (fields && (fields[`left image ${index + 1}`] as any)).value &&
                    (fields && (fields[`left image ${index + 1}`] as any)).value.src
                  }
                  imageCenter={
                    fields &&
                    fields[`center image ${index + 1}`] &&
                    (fields && (fields[`center image ${index + 1}`] as any)).value &&
                    (fields && (fields[`center image ${index + 1}`] as any)).value.src
                  }
                />
              );
            }
          })}
        </>
      ) : (
        <Slider {...settings}>
          {new Array(6).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`description ${index + 1}`] &&
              (fields && (fields[`description ${index + 1}`] as any)).value
            ) {
              return (
                <CarousselContent
                  rendering={rendering}
                  fields={{
                    heading: {
                      value:
                        (fields &&
                          fields[`heading ${index + 1}`] &&
                          (fields && (fields[`heading ${index + 1}`] as any)).value) ||
                        null,
                    },
                    title: {
                      value:
                        (fields &&
                          fields[`title ${index + 1}`] &&
                          (fields && (fields[`title ${index + 1}`] as any)).value) ||
                        null,
                    },
                    description: {
                      value:
                        (fields &&
                          fields[`description ${index + 1}`] &&
                          (fields && (fields[`description ${index + 1}`] as any)).value) ||
                        null,
                    },
                    author: {
                      value:
                        (fields &&
                          fields[`author ${index + 1}`] &&
                          (fields && (fields[`author ${index + 1}`] as any)).value) ||
                        null,
                    },
                  }}
                  isGreen={!!isGreen}
                  isLightGray={!!isLightGray}
                  backgroundFilter={!!backgroundFilter}
                  imageLeft={
                    fields &&
                    fields[`left image ${index + 1}`] &&
                    (fields && (fields[`left image ${index + 1}`] as any)).value &&
                    (fields && (fields[`left image ${index + 1}`] as any)).value.src
                  }
                  imageCenter={
                    fields &&
                    fields[`center image ${index + 1}`] &&
                    (fields && (fields[`center image ${index + 1}`] as any)).value &&
                    (fields && (fields[`center image ${index + 1}`] as any)).value.src
                  }
                />
              );
            }
          })}
        </Slider>
      )}
    </div>
  );
};
