/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import bg from './background.svg';
import {breakpointMax} from "../../style";

export interface CaseStudiesContainerProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  // min-height: 800px;
`;
const center = css`
  width: 780px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const top = css`
  width: 100%;
  height: 100%;
`;
const background = css`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    ${!isExperienceEditorActive() && 'display: none;'}
  }
`;

export const CaseStudiesContainer: React.FunctionComponent<CaseStudiesContainerProps> = (props: CaseStudiesContainerProps): React.ReactElement => {
  const { rendering } = props;

  return (
    <div css={container} id="case-studies-container">
      <div css={background} />
      <div css={top}>
        <Placeholder name='jss-top-container' rendering={rendering} />
      </div>
      <div css={center} id="caseStudiesContainer">
        <Placeholder name='center-column-section' rendering={rendering} />
      </div>
    </div>
  );
}
   