import axios from "axios";
import config from "../temp/config";
import { canUseDOM } from "../predicates";
import * as process from "process";
import ResultsCards from "../core/components/Search/results/searchMobile/resultsCards";

const instance = axios.create();
export const getValue = (object: any, field: string) => {
  if (object) {
    if (object[field] && (object[field] as any).value) {
      return (object[field] as any).value;
    }
  }
  return null;
};

export const withQueryParam = (data: any, param?: string) => {
  if (data && param) {
    return {
      value: {
        ...data.value,
        href: `${data.value?.href?.replace("/en/", "/")}${param}`,
      },
    };
  }
  return data;
};

export const getDataWithChild = async (target: any, subFields?: string[]) => {
  const data: any = [];
  if (target.length > 0) {
    for (const res of target) {
      const index: number = target.indexOf(res);
      data.push({
        label: res.DisplayName,
        itemName: res.ItemName || null,
        children: [],
      });
      if (res.HasChildren === "True") {
        //data[index] = getChildren(res, data, index);
        const subResult: any = await fetchData(res.ItemID);
        const target = subResult;
        data[index].children = target;

        for (const subRes of target) {
          const subIndex: number = target.indexOf(subRes);
          if (subFields) {
            for (const field of subFields) {
              if (target[subIndex][field]) {
                const fieldSource = target[subIndex][field].split("|");
                const fieldData = await getLinkedData(fieldSource);
                data[index].children[subIndex][field] = fieldData;
              }
            }
          }
        }
      }
    }
  }
  return data;
};

export const getDataWithDynamicChild = async (
  target: any,
  subFields?: string[]
) => {
  const data: any = [];
  if (target.length > 0) {
    for (const res of target) {
      const index: number = target.indexOf(res);
      data.push({
        label: res.DisplayName,
        itemName: res.ItemName || null,
        children: [],
      });
      if (res.HasChildren === "True") {
        //data[index] = getChildren(res, data, index);
        const subResult: any = await fetchData(res.ItemID);
        const target = subResult;
        data[index].children = target;

        for (const subRes of target) {
          const subIndex: number = target.indexOf(subRes);
          if (subFields) {
            for (const field of subFields) {
              if (target[subIndex][field]) {
                const fieldSource = target[subIndex][field].split("|");
                const fieldData = await getLinkedData(fieldSource);
                data[index].children[subIndex][field] = fieldData;
              }
            }
          }
          if (subRes && subRes["Item link"]) {
            if (
              subRes["Item link"].match(/{([^}]+)}/) &&
              subRes["Item link"].match(/{([^}]+)}/)[1]
            ) {
              const child = await fetchData(
                subRes["Item link"].match(/{([^}]+)}/)[1],
                false
              );
              const categories = data[index].children[subIndex]?.Categories;
              const thematics = data[index].children[subIndex]?.Thematics;
              const newData = (data[index].children[subIndex] = {
                ...child,
                Categories: categories || [],
                Thematics: thematics || [],
              });
              data[index].children[subIndex] = newData;
            }
          }
        }
      }
    }
  }
  return data;
};

export const getTransformLink = async (
  idPage: string,
  path = "/sitecore/content/bdl-port-luxse-ssr/"
) => {
  const pageData = await fetchData(idPage, false);
  return pageData?.ItemPath?.replace(path, "");
};
export const getTransformPdfLink = async (idPage: string) => {
  const pageData = await fetchData(idPage, false);
  const targetString =
    pageData?.ItemPath?.split("bdl-port-luxse-ssr") &&
    pageData?.ItemPath?.split("bdl-port-luxse-ssr")[1];
  return renderLink(
    `-/media/bdl-port-luxse-ssr${targetString}.${pageData?.Extension}`
  );
};

const getParentChild = async (
  computedLinks: any[],
  link: any,
  index: number
) => {
  const newLinks = [...computedLinks];
  for (let index1 = 0; index1 < 20; index1++) {
    if (
      link[`item ${index1 + 1} page link`] &&
      link[`item ${index1 + 1} page link`].match(/{([^}]+)}/) &&
      link[`item ${index1 + 1} page link`].match(/{([^}]+)}/)[1]
    ) {
      const target = link[`item ${index1 + 1} page link`].match(/{([^}]+)}/)[1];
      const route = await getTransformLink(
        target,
        "/sitecore/content/bdl-port-luxse-ssr/"
      );
      newLinks[index][`item ${index1 + 1} link`] = route;
    }
  }
  return newLinks;
};
const getChild = async (link: any) => {
  const newLink = { ...link };
  for (let index1 = 0; index1 < 20; index1++) {
    if (
      link[`item ${index1 + 1} page link`] &&
      link[`item ${index1 + 1} page link`].match(/{([^}]+)}/) &&
      link[`item ${index1 + 1} page link`].match(/{([^}]+)}/)[1]
    ) {
      const target = link[`item ${index1 + 1} page link`].match(/{([^}]+)}/)[1];
      const route = await getTransformLink(
        target,
        "/sitecore/content/bdl-port-luxse-ssr/"
      );
      newLink[`item ${index1 + 1} link`] = route;
    }
  }
  return newLink;
};

export const getLink = async (data: any, field: string) => {
  let computedLinks = [...data];
  for (const link of data) {
    const index: number = data.indexOf(link);
    if (
      link[field] &&
      link[field].match(/{([^}]+)}/) &&
      link[field].match(/{([^}]+)}/)[1]
    ) {
      const finalRoute = await getTransformLink(
        link[field].match(/{([^}]+)}/)[1],
        "/sitecore/content/bdl-port-luxse-ssr/"
      );
      computedLinks[index][field] = finalRoute;
    }
    const child = await getParentChild(computedLinks, link, index);
    computedLinks = child;
    if (link && link.child) {
      for (const subLink of link.child) {
        const subIndex: number = link.child.indexOf(subLink);
        const subChild = await getChild(subLink);
        computedLinks[index].child[subIndex] = subChild;
      }
    }
  }
  return computedLinks;
};
export const getLinkUrl = async (data: any) => {
  if (data && data?.match(/{([^}]+)}/) && data?.match(/{([^}]+)}/)[1]) {
    const finalRoute = await getTransformLink(
      data.match(/{([^}]+)}/)[1],
      "/sitecore/content/bdl-port-luxse-ssr/"
    );
    return finalRoute?.replace("home", "");
  }
  if (data && data?.includes("mailto")) {
    let subject = "";
    const target =
      data.split("=")[1]?.split("'")[1] || data.split("=")[1]?.split('"')[1];
    if (
      data.split("=") &&
      data.split("=")[5] &&
      data.split("=")[5].split("'") &&
      data.split("=")[5].split("'")[0].split('"') &&
      data.split("=")[5].split("'")[0].split('"')[0]
    ) {
      subject = data.split("=")[5].split("'")[0].split('"')[0];
    }
    if (
      data.split("=") &&
      data.split("=")[5] &&
      data.split("=")[5].split('"') &&
      data.split("=")[5].split('"')[0].split("'") &&
      data.split("=")[5].split('"')[0].split("'")[0]
    ) {
      subject = data.split("=")[5].split('"')[0].split("'")[0];
    }
    return `mailto:${target}?subject=${subject}`;
  }
  return false;
};
export const getDataWithoutChild = async (target: any) => {
  const data: any = [];
  for (const res of target) {
    const categories = (res.Categories && res.Categories.split("|")) || [];
    const thematics = (res.Thematics && res.Thematics.split("|")) || [];

    const thematicsData = await getLinkedData(thematics);
    const categoriesData = await getLinkedData(categories);
    const idImage =
      res["Document image"] &&
      res["Document image"].match(/{([^}]+)}/) &&
      res["Document image"].match(/{([^}]+)}/)[1];
    const idDocument =
      res["Document"] &&
      res["Document"].match(/{([^}]+)}/) &&
      res["Document"].match(/{([^}]+)}/)[1];
    let imageUrl: any = null;
    let documentUrl: any = null;
    if (idImage) {
      imageUrl = await fetchData(idImage, false); ///sitecore/media library/
    }
    if (idDocument) {
      const req = await fetchData(idDocument, false); ///sitecore/media library/
      if (req && req.ItemPath && req.Extension) {
        documentUrl = renderLink(
          `${req?.ItemPath}.${req?.Extension}`?.replace(
            "/sitecore/media library/",
            "/-/media/"
          )
        );
      }
    }

    data.push({
      label: res.DisplayName,
      categories: categoriesData.map((item: any) => item.label),
      thematics: thematicsData.map((item: any) => item.label),
      description: res.Description,
      document: documentUrl || res["Document external link"],
      isExternal: !documentUrl,
      documentImage: imageUrl?.ItemMedialUrl,
      documentTitle: res["Document title"],
    });
  }
  return data;
};

export const getMembers = async (data: any) => {
  const arr: any = [];
  if (data) {
    for (const res of data) {
      if (res["Logo"]) {
        const imageUrl = await getLinkedMedia(
          res["Logo"].match(/{([^}]+)}/)[1]
        );

        arr.push({ ...res, logo: imageUrl?.ItemMedialUrl });
      } else {
        arr.push({ ...res, logo: "" });
      }
    }
    return arr;
  } else {
    return [];
  }
};

export const getLinkedMedia = async (source: any) => {
  const result: any = await fetchData(source, false);
  return result;
};

export const getLinkedData = async (source: any) => {
  const data: any[] = [];

  for (const cat of source) {
    if (cat) {
      const result: any = await fetchData(cat, false);

      if (result.Icon && result.Icon.match(/{([^}]+)}/)[1]) {
        const icon: any = await getLinkedMedia(
          result.Icon.match(/{([^}]+)}/)[1]
        );
        data.push({ label: result.DisplayName, icon: icon.ItemMedialUrl });
      } else {
        data.push({ label: result.DisplayName, icon: null });
      }
    }
  }
  return data;
};

export const capitalizeFirstLetter = (str?: string) => {
  return str?.length > 0 ? str?.charAt(0)?.toUpperCase() + str?.slice(1) : str;
};

export const renderLink = (image: any, debug?: boolean) => {
  if (
    canUseDOM &&
    window.location.hostname === "localhost" &&
    typeof image === "string" &&
    image.split("/").length > 1 &&
    image.split("/")[1] &&
    image.split("/")[1] !== "static" &&
    !image.split("/")[1].includes("base64")
  ) {
    return `${process.env.SITECORE_API_HOST || config.sitecoreApiHost
      }/${image}`?.replace("/sitecore/shell", "");
  }
  return image ? image?.replace("/sitecore/shell", "") : "";
};
export const getUrlByEnv = () => {
  if (canUseDOM) {
    if (window.location.hostname === "localhost") {
      return "https://bdl-port-luxse-ssr-dev-weu-main-bdl-web-dev.apps.dev.luxse.cloud";
    }
    return `${window.location.protocol}//${window.location.hostname}`;
  }
  return `/`;
};
export const getUrlApiKey = () => {
  return process.env.SITECORE_API_KEY || config.sitecoreApiKey;
};
export const generateUrl = (id: string, withoutChildren?: boolean) => {
  const url = `${getUrlByEnv()}/sitecore/api/ssc/item/`;
  const params = `/${withoutChildren ? "" : "children"
    }?database=master&sc_apikey=${getUrlApiKey()}`;
  return `${url}${id?.replace("{", "")?.replace("}", "")}${params}`;
};
export const generatePostUrl = (path: string) => {
  const url = `${getUrlByEnv()}/sitecore/api/ssc/item${path}`;
  const params = `/?database=master&sc_apikey=${getUrlApiKey()}`;
  return `${url}${params}`;
};

export const fetchData = async (id: string, withChild = true) => {
  const url = `${getUrlByEnv()}/content-data/${id}/${withChild}`;
  try {
    const result: any = await instance.get(url, { timeout: 0 });
    const { data } = result;
    return data;
  } catch (err) {
    console.error("helper request api error =>", err);
    return false;
  }
};

export const getFullArticle = async (id: string, imageField: string) => {
  try {
    const data = await fetchData(id, false);
    const mediaId =
      data && data[imageField] && data[imageField].match(/{([^}]+)}/)[1];

    if (mediaId) {
      const media = await getLinkedMedia(mediaId);
      return { ...data, image: renderLink(media?.ItemMedialUrl) };
    } else {
      return data;
    }
  } catch (err) {
    console.error("error get full article", err);
  }
};

const getData = (
  id: string,
  sub: any,
  rootField: string,
  field: string,
  imageField: string
) => {
  return new Promise((resolve) => {
    fetchFullData(id)
      .then((response: any) => {
        const subId = response.filter((r: any) => r.parent === rootField)[0]
          ?.id;

        if (subId) {
          getFullArticle(subId, imageField).then((res: any) => {
            resolve({ ...res, item: sub });
          });
        } else {
          resolve({});
          console.log("no sub id");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  });
};

const goProcess = async (
  data: any,
  limit: number,
  rootField: string,
  field: string,
  imageField: string
) => {
  const req: any = await Promise.all(
    data.map((d: any) =>
      getData(
        d.children && d.children[0] && d.children[0].ItemID,
        d.parent,
        rootField,
        field,
        imageField
      )
    )
  );
  return req.slice(0, limit);
};
export const goProcessArticles = async (
  articlesIds: string[],
  limit?: number,
  rootField?: string,
  field?: string,
  imageField?: string
) => {
  const req: any = await Promise.all(
    articlesIds.map((articleId: string) =>
      getData(articleId, null, rootField, field, imageField)
    )
  );
  return req;
};

export const getDataFromParent = async (
  data: any[],
  limit: number,
  rootField: string,
  field: string,
  imageField: string
) => {
  return await goProcess(data, limit, rootField, field, imageField);
};

const fetchChild = async (object: any, ref?: any) => {
  const url = `${getUrlByEnv()}/content-data/${object.id}/${object.hasChildren ? "true" : "false"
    }`;
  const result: any = await instance.get(url, { timeout: 0 });
  const { data } = result;
  return { id: object.id, parent: object.parent, children: data };
};

const processDeepQuery = async (data: any, sub?: boolean) => {
  return new Promise((resolve) => {
    fetchChild(data).then((parents: any) => {
      Promise.all(
        parents.children.map((d: any) =>
          fetchChild({
            id: d.ItemID,
            hasChildren: d.HasChildren,
            parent: d.ItemName,
          })
        )
      ).then((req: any) => {
        const arrays = req
          .filter((n: any) => n)
          .map((r: any) => r.children.map((sub: any) => sub.ItemID));
        if (arrays.length > 0) {
          Promise.all(arrays.map((d: any) => fetchFullData(d[0]))).then(
            (total: any) => {
              const filtered = total
                .filter((n: any) => n)
                .map(
                  (r: any) =>
                    r.filter((sub: any) =>
                      sub.parent.includes("BlogArticleComponent")
                    )[0]
                );
              const final: any = [];
              filtered.forEach((h: any) => {
                final.push({
                  title: h?.children?.title,
                  content: h?.children?.content,
                  short: h?.children["short description"],
                  image: h?.children["main image"],
                  link: `${data?.parent}/${h?.children?.ItemPath.split("/")[7]
                    }`,
                  featured: h?.children?.featured,
                });
              });
              resolve(final);
            }
          );
        } else {
          resolve("empty");
        }
      });
    });
  });
};

const processQuery = async (data: any) => {
  return await fetchChild(data);
};

export const fetchFullData = async (id: string, arr: any = [], sub = false) => {
  const url = `${getUrlByEnv()}/content-data/${id}/true`;
  try {
    const result: any = await instance.get(url, { timeout: 0 });
    const { data } = result;

    const initial: any = [];
    (data || []).forEach((d: any) =>
      initial.push({
        id: d.ItemID,
        hasChildren: d.HasChildren === "True",
        parent: d.DisplayName,
        children: [],
        raw: d,
      })
    );
    const req = Promise.all(initial.map((i: any) => processQuery(i)));
    return await req;
  } catch (err) {
    console.error("helper request api error =>", err);
    return false;
  }
};
export const fetchDeepData = async (id: string) => {
  const url = `${getUrlByEnv()}/content-data/${id}/true`;
  try {
    const result: any = await instance.get(url, { timeout: 0 });
    const { data } = result;

    const initial: any = [];
    (data || []).forEach((d: any) =>
      initial.push({
        id: d.ItemID,
        hasChildren: d.HasChildren === "True",
        parent: d.DisplayName,
        children: [],
        raw: d,
      })
    );
    return new Promise((resolve) => {
      Promise.all(initial.map((i: any) => processDeepQuery(i))).then(
        (data: any) => {
          const arr: any = [];
          (data || []).forEach((d: any) => {
            (d || []).forEach((sub: any) => {
              if (sub.title) {
                arr.push(sub);
              }
            });
          });
          resolve(arr);
        }
      );
    });
  } catch (err) {
    console.error("helper request api error =>", err);
    return false;
  }
};

export const fetchNewData = async (id: string, withChild = true) => {
  //const url = `${getUrlByEnv()}/sitecore/api/getnavigation/${id}?sc_apikey=${getUrlApiKey()}`;
  const url = `${getUrlByEnv()}/sitecore/api/getnavigation/${id}?sc_apikey=${getUrlApiKey()}`;

  // /sitecore/api/itemurl/{item-id}?sc_apikey={api-key}&sc_lang=en&sc_site={site-name}
  //https://grp-weu-dev-scor-cm.apps.dev.luxse.cloud/sitecore/api/itemurl/951D2AF5-4E80-4C86-A12F-ECFDD70503F2?sc_apikey=D5933D33-7A8C-4621-8CA1-50B285E49ACF&sc_db=web&sc_site=bdl-port-luxse-ssr
  // /sitecore/api/sendform?sc_apikey={api-key}
  ///sitecore/api/getnavigation/{item-id}?sc_apikey={api-key}
  try {
    const result: any = await instance.get(url, { timeout: 0 });
    const { data } = result;
    return data;
  } catch (err) {
    console.error("helper request api error [GetNavigation] =>", err);
    return false;
  }
};

export const transformLink = (link: any) => {
  const newLink: any = {};
  newLink.value = {
    ...link,
    href: link?.href?.replace("/en/", "/"),
  };
  return newLink;
};

export const fetchChildPages = async (parentItemId: any) => {
  const url = `${getUrlByEnv()}/sitecore/api/ssc/item/${parentItemId}/children?sc_apikey=${config.sitecoreApiKey}&sc_site=${config.jssAppName}&sc_lang=en`;

  try {
    const result = await instance.get(url);
    const { data } = result;

    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map((child: any) => child.ItemID);
  } catch (err) {
    return [];
  }
};

export const fetchLayoutData = async (itemId: any): Promise<any> => {
  const url = `${getUrlByEnv()}/sitecore/api/layout/render/default?item=${itemId}&sc_apikey=${config.sitecoreApiKey}&sc_site=${config.jssAppName}&sc_lang=en&tracking=true`;

  try {
    const result = await instance.get(url);
    const { data } = result;

    if (!data || !data.sitecore) {
      return null;
    }

    const mainPlaceholder = data.sitecore.route.placeholders?.['jss-main'] || [];

    // Check for CaseStudiesArticle in jss-main
    let caseStudiesComponents = mainPlaceholder.filter(
      (component: any) => component.componentName === "CaseStudiesArticle"
    );

    // Check for CaseStudiesArticle in center-column-section within jss-main
    const centerColumnSection = mainPlaceholder.find(
      (placeholder: any) => placeholder.placeholders?.['center-column-section']
    );

    if (centerColumnSection) {
      const components = centerColumnSection.placeholders['center-column-section'] || [];
      caseStudiesComponents = caseStudiesComponents.concat(
        components.filter(
          (component: any) => component.componentName === "CaseStudiesArticle"
        )
      );
    }

    caseStudiesComponents = caseStudiesComponents.filter(
      (component: any) => component.fields?.displayInLatestStories?.value === true
    );

    return caseStudiesComponents.map((component: any) => ({
      caseStudiesShortDescription: component.fields?.caseStudiesShortDescription?.value || null,
      caseStudiesTitle: component.fields?.caseStudiesTitle?.value || null,
      caseStudiesLogo: component.fields?.caseStudiesLogo?.value || null,
      caseStudiesMainImage: component.fields?.caseStudiesMainImage?.value || null,
    }));
  } catch (err) {
    return [];
  }
};
