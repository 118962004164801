export enum MarketTradingGroupEnum {
	W0="(E) Warrants (with decimals)",
	W1="(L) Warrants (with decimals)",
	W2="(E) Warrants (without decimal)",
	W3="(L) Warrants (without decimal)",
	WK="(E) Bonds wholesale",
	WL="(L) Bonds wholesale",
	W5="(E) Warrants wholesale",
	W6="(L) Warrants wholesale",
	WI="(E) Funds wholesale",
	WJ="(L) Funds wholesale",
	WA="(L) ATT Bonds Pass-through",
	WB="(L) ATT Bonds Bilateral",
	WC="(E) Bilateral continuous shares",
	WD="(L) Bilateral continuous shares",
	WE="(E) Renminbi bonds",
	WF="(L) Renminbi bonds",
	WG="(E) Bilateral Bonds EU National CSD Settlement",
	WH="(L) Bilateral Bonds EU National CSD Settlement",
	X1="(L) Liquid bonds",
	X2="Euro denominated Straight Bonds - triple auction",
	X3="Euro denominated Floating Rate Notes - triple auction",
	X4="Euro denominated Zero Coupon Bonds - triple auction",
	X5="USD denominated Straight Bonds - triple auction",
	X6="USD denominated Floating Rate Notes - triple auction",
	X7="USD denominated Zero Coupon Bonds - triple auction",
	X8="Other European Bonds - triple auction",
	X9="Other Non European Bonds - triple auction",
	XA="Euro denominated Straight Bonds - triple auction",
	XB="Euro denominated Floating Rate Notes - triple auction",
	XC="Euro denominated Zero Coupon Bonds - triple auction",
	XD="USD denominated Straight Bonds - triple auction",
	XE="USD denominated Floating Rate Notes - triple auction",
	XF="USD denominated Zero Coupon Bonds - triple auction",
	XG="Other European Bonds - triple auction",
	XH="Other Non European Bonds - triple auction",
	XI="Liquid Non-corporate Bonds - continuous",
	XJ="Liquid Non-corporate Bonds - continuous",
	XK="Euro denominated Straight Bonds - triple auction",
	XL="Euro denominated Floating Rate Notes - triple auction",
	XM="Euro denominated Zero Coupon Bonds - triple auction",
	XN="USD denominated Straight Bonds - triple auction",
	XO="USD denominated Floating Rate Notes - triple auction",
	XP="USD denominated Zero Coupon Bonds - triple auction",
	XQ="Other European Bonds - triple auction",
	XR="Other Non European Bonds - triple auction",
	XS="Euro denominated Straight Bonds - triple auction",
	XT="Euro denominated Floating Rate Notes - triple auction",
	XU="Euro denominated Zero Coupon Bonds - triple auction",
	XV="USD denominated Straight Bonds - triple auction",
	XW="USD denominated Floating Rate Notes - triple auction",
	XX="USD denominated Zero Coupon Bonds - triple auction",
	XY="Other European Bonds - triple auction",
	XZ="Other Non European Bonds - triple auction",
	YA="(E) Continuous shares pass-through",
	YB="(E) Fixing shares pass-through",
	YC="(E) UCI (with decimals)",
	YD="(L) Continuous shares pass-through",
	YE="(L) Fixing shares pass-through",
	YF="(L) UCI (with decimals)",
	YG="(E) Liquid shares",
	YH="(E) Liquid Bonds",
	YJ="(E) Bilateral bonds",
	YK="Bilateral Non Corporate Bonds - triple auction",
	YL="(L) Bilateral bonds",
	YM="Bilateral Non Corporate Bonds - triple auction",
	YN="(E) Fixing shares bilateral",
	YP="(E) UCI (without decimal)",
	YQ="(L) UCI (without decimal)",
	YR="(L) Fixing shares bilateral",
	YS="(E) Bonds pass-through",
	YT="(L) Bonds pass-through",
	YU="Liquid Non-corporate Bonds pass-through - continuous",
	YV="Liquid Non-corporate Bonds pass-through - continuous",
	YX="UCI - continuous",
	YY="UCI - continuous",
	YZ="(L) Liquid shares",
	YO="(L) Dirty Bilateral Bonds",
	YW="(E) Dirty Bilateral Bonds",
	Z1="(L) Bilateral Shares EM3S",
	Z2="(L) Funds EM3S",
	Z3="(L) Warrants EM3S",
	Z4="(L) Bilateral bonds EM3S",
	ACTC="N/A",
	ACTE="N/A",
	ACTL="N/A",
	CNVW="N/A",
	ECU ="N/A",
	EUR1="N/A",
	EUR2="N/A",
	EURO="N/A",
	INT1="N/A",
	INT2="N/A",
	LUF ="N/A",
	MTNO="N/A",
	OPC ="N/A",
	OPCE="N/A",
	OPCL="N/A",
	OR  ="N/A",
	USD ="N/A",
	W4  ="N/A",
	XACC="N/A",
	XACE="N/A",
	XACL="N/A",
	XCNW="N/A",
	XEI1="N/A",
	XEI2="N/A",
	XEUR="N/A",
	XIN ="N/A",
	XOPC="N/A",
	XOUT="N/A",
	XUSD="N/A",
	SO="(S) All instruments",
}

export function marketTradingGroupLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(MarketTradingGroupEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketTradingGroupEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketTradingGroupEnum label for '" + value + "'", e);
	}
		
	return value;
}