/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import PopupUi from "reactjs-popup";
import { breakpointMax } from "../../style";

export interface PopupContainerProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const PopupContainer: React.FunctionComponent<PopupContainerProps> = (props: PopupContainerProps): React.ReactElement => {
    const { rendering, fields } = props;

    const isExperienceEditorActivated: boolean = isExperienceEditorActive();
    const [isOpen, setIsOpen] = React.useState(false);
    const title: string = getFieldValue<string>(fields, "title", "");
    const triggerStrategy: string = getFieldValue<string>(fields, "triggerStrategy", "time");
    const triggerTime: number = getFieldValue<number>(fields, "triggerTime", 30000);
    const triggerElement: string = getFieldValue<string>(fields, "triggerElement", undefined);
    const closeOnEscape: boolean = getFieldValue<boolean>(fields, "closeOnEscape", false);
    const lockScroll: boolean = getFieldValue<boolean>(fields, "lockScroll", false);
    const closeOnDocumentClick: boolean = getFieldValue<boolean>(fields, "closeOnDocumentClick", true);

    const parentContainer = css`
        width: 100%;
        margin-bottom: 10px;
    `;
    const container = css`
      width: 1152px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
    `;
    const popupContainer = css`
      width: 1152px;
      display: ${isExperienceEditorActivated === false ? "none" : "flex"};
      flex-direction: column;
      align-items: center;
      margin: auto;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
    `;

    React.useEffect(() => {
        if (triggerStrategy === "time") {
            const timer = setTimeout(() => {
                setIsOpen(true);
            }, triggerTime); // Convert seconds to milliseconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount
        } else if (triggerStrategy === "click") {
            const element = document.evaluate(
                triggerElement,
                document,
                null,
                XPathResult.FIRST_ORDERED_NODE_TYPE,
                null
            ).singleNodeValue;

            if (element) {
                element.addEventListener("click", () => setIsOpen(true));
            }
            return () => {
                if (element) {
                    element.removeEventListener("click", () => setIsOpen(true));
                }
            };
        }
    }, [triggerStrategy, triggerTime, triggerElement]);

    const onClose = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div css={parentContainer}>
            <div css={popupContainer}> 
                {title}
                <Placeholder name="jss-main" rendering={rendering} /> 
            </div>
            {isExperienceEditorActivated ? null : ( 
                <PopupUi
                    open={isOpen}
                    onClose={onClose}
                    position={"right center"}
                    closeOnEscape={closeOnEscape}
                    lockScroll={lockScroll}
                    closeOnDocumentClick={closeOnDocumentClick}
                    contentStyle={{ padding: 0, width: "unset", borderRadius: "4px" }}
                >
                    <div css={container}>
                        <Placeholder name="jss-main" rendering={rendering} />
                    </div>
                </PopupUi>
            )}
        </div>
    );
}
