/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { renderLink } from "../../utils/helper";
import { breakpointMax } from "../../style";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import hexa_stripes_dotted from "./hexa_stripes_dotted.svg";

export interface CaseStudiesFeatureStoryProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const CaseStudiesFeaturedStory: React.FunctionComponent<CaseStudiesFeatureStoryProps> = (props: CaseStudiesFeatureStoryProps): React.ReactElement => {
    const { fields } = props;

    const backgroundImage: any = getFieldValue<any>(fields, "backgroundImage", 0);
    const logo: any = getFieldValue<any>(fields, "logo", null);
    const title: string = getFieldValue<string>(fields, "title", "");
    const subtitle: string = getFieldValue<string>(fields, "subtitle", "");
    const description: string = getFieldValue<string>(fields, "description", "");
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
    const buttonLabel: string = getFieldValue<string>(fields, "buttonLabel", "");

    const container = css`
        width: 1152px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin: auto;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
        }
    `;
    const mainTitle = css`
        flex: 1 0 0;
        color: #253845;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        margin-top: 32px;
        align-self: flex-start;
        @media (max-width: ${breakpointMax}) {
            font-size: 36px;
        }
    `;
    const storyContainer = css`
        display: flex;
        height: 483px;
        padding: 40px 24px 16px 24px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #BCC4CC;
        background:
            url(${hexa_stripes_dotted}) no-repeat bottom right, 
            linear-gradient(90deg, rgba(255, 255, 255, 0.95) 40.8%, rgba(255, 255, 255, 0.00) 71.3%), url(${renderLink(backgroundImage && backgroundImage.src)}) lightgray 50% / cover no-repeat;
        margin-bottom: 80px;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            height: 610px;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.95) 43.8%, rgba(255, 255, 255, 0.00) 67.66%), url(${renderLink(backgroundImage && backgroundImage.src)}) lightgray 50% / cover no-repeat;
        }
    `;
    const leftSection = css`
        display: flex;
        width: 472px;
        padding: 16px 32px 0px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            padding: 0;
            margin-top: 50px;
        }
    `;
    const logoImage = css`
        display: flex;
        width: 185px;
        height: 105px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        background: #FFFFFF;
        // padding-top: 16px;
        padding-bottom: 16px;
        @media (max-width: ${breakpointMax}) {
        }
    `;
    const storyTitle = css`
        color: #2E3943;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        @media (max-width: ${breakpointMax}) {
            font-size: 24px;
            margin-top: 16px;
        }
    `;
    const storyDescription = css`
        align-self: stretch;
        color: #2E3943;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        @media (max-width: ${breakpointMax}) {
            font-size: 16px;
        }
    `;
    const storyButton = css`
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        margin-top: 10px;
        @media (max-width: ${breakpointMax}) {
            font-size: 16px;
        }
    `;

    return (
        <div css={container}>
            <div css={mainTitle}>
                {title}
            </div>
            <div css={storyContainer}>
                <div css={leftSection}>
                    <div css={logoImage}>
                        <img
                            src={renderLink(logo && logo.src)}
                            width="185px"
                            height="105px"
                            style={{ background: "#FFFFFF", borderRadius: "16px", paddingTop: "16px", paddingBottom: "16px" }}
                        />
                    </div>
                    <div css={storyTitle}>
                        {subtitle}
                    </div>
                    <div css={storyDescription}>
                        {description}
                    </div>
                    <div css={storyButton}>
                        <Button
                            variant="primary"
                            label={buttonLabel}
                            iconRight="chevron-right"
                            onClick={() => window.location.href = buttonLink}
                        >
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
