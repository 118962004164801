/** @jsx jsx */

import {
  Icon,
  Menu,
  MenuWithChild,
  ToggleButton,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { marketRuleLabel } from "../../../../../enums";
import { css, jsx } from "@emotion/react";
import {
  lgxEligibleCategoryLabel,
  lgxEligibleCategorySort,
} from "../../../../../enums/lgx-eligible-category";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountFilter from "../../../../../interfaces/search/search-filter-count";
import CountItem from "../../../Search/results/filters/count-item";
import RoundTag from "../../../RoundTag/roundTag.component";
import { SDGsList } from "../luxXPrime/sdgs-list";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import SearchSecurityFilters from "../../../../../interfaces/search/search-security-filters";
import { breakpointMax } from "../../../../../style";
import { computeCategoryLabel } from "./row";
import { lgxStandardLabel } from "../../../../../enums/lgx-standard";
import { lgxSustainableBondLabel } from "../../../../../enums/lgx-sustainable-bond";
import { lgxSustainableFundLabel } from "../../../../../enums/lgx-sustainable-fund";
import { securityCategoryLabel } from "../../../../../enums/security-category";
import { style } from "../../../Search/results/filters/index.style";
import SearchSecurityFiltersMarket from "../../../../../interfaces/search/search-security-market-filters";
import { marketSegmentLabel } from "../../../../../enums/market-segment";

export interface SecurityFiltersProps {
  filterCount?: SearchSecurityFilters;
  filters: SearchFilterParams;
  fullWidth?: boolean;
  onFiltersChange: (filters: SearchFilterParams) => void;
}

const menuCss = css`
  width: 100%;
`;

function computeMarketValues(
  values: SearchSecurityFiltersMarket[],
  selection?: any
): SearchSecurityFiltersMarket[] {
  if (!selection || (values && !!values.length)) {
    return values;
  }
  return [{ name: selection, count: -1, segments: [] }];
}

export function computeValues(
  values: CountFilter[],
  selection?: any
): CountFilter[] {
  if (!selection || (values && !!values.length)) {
    return values;
  }
  return [{ name: selection, count: -1 }];
}

export function SecurityFilters({
  filterCount,
  filters,
  onFiltersChange,
  fullWidth,
}: SecurityFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    const parent = event.currentTarget;
    const head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head == target) found = true;
      target = target.parentElement;
    } while (!found && parent != target && target != null);

    if (found) parent.classList.toggle("open");
  };

  const handleFilterChange = (
    key: keyof SearchFilterParams,
    value: any
  ): void => {
    const newFilters: SearchFilterParams = { ...filters };

    if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
      try {
        // @ts-ignore
        let array: any[] =
          filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error(
          "Failed to updated filter[" + key + "]",
          filters[key],
          " with value",
          value,
          e
        );
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };

  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;

    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }

    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "arrow-left" : "arrow-right"}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="folder-tree"
          label="Instrument Information"
          width="100%;"
          expanded={true}
          arrows={false}
        >
          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="folder-tree"
              label="Type"
              width="100%;"
              expanded={true}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.categories,
                filters.category
              ) &&
                computeValues(
                  filterCount && filterCount.categories,
                  filters.category
                )
                  .sort((first, second) => second.count - first.count)
                  .map((category) => {
                    if (category.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={category.name}
                          width="100%;"
                          selected={filters.category === category.name}
                          onSelect={() =>
                            handleFilterChange("category", category.name)
                          }
                          childLabel={
                            (
                              <div
                                css={css`
                                  display: flex;
                                  justify-content: space-between;
                                  width: 100%;
                                  align-items: center;
                                `}
                              >
                                <RoundTag
                                  green={filters.category === category.name}
                                  borderSize={2}
                                  textColor={"#ffffff"}
                                  size={14}
                                  minSize={30}
                                >
                                  {computeCategoryLabel(category.name)}
                                </RoundTag>
                                <div
                                  css={css`
                                    text-transform: capitalize;
                                    flex-shrink: 0;
                                    flex-grow: 3;
                                    width: 60%;
                                    margin-left: 5px;
                                  `}
                                >
                                  {securityCategoryLabel(category.name)}
                                </div>
                                <div
                                  css={css`
                                    text-align: right;
                                  `}
                                >
                                  {category.count}
                                </div>
                              </div>
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>

          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <FontAwesomeIcon icon={["fas", "circle-nodes"]} />
              </div>
              <div css={style.toggleLabel}>Security Tokens</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.tokenOnly || 0})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.tokenOnly === true}
                onClick={() =>
                  handleFilterChange("tokenOnly", !filters.tokenOnly)
                }
              />
            </div>
          </div>

          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="leaf" />
              </div>
              <div css={style.toggleLabel}>Include Delisted Securities</div>
              <div css={style.toggleCount}></div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={!filters.excludeRetr}
                onClick={() =>
                  handleFilterChange("excludeRetr", !filters.excludeRetr)
                }
              />
            </div>
          </div>

          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="yen-sign" />
              </div>
              <div css={style.toggleLabel}>Chinese bonds</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.chineseBondOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.chineseBondOnly === true}
                onClick={() =>
                  handleFilterChange(
                    "chineseBondOnly",
                    !filters.chineseBondOnly
                  )
                }
              />
            </div>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="chart-area"
              label="Underlying Market/Platform/Segment"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              { computeMarketValues(filterCount && filterCount.markets, filters.securityMarketRules) &&
                computeMarketValues(filterCount && filterCount.markets, filters.securityMarketRules)
                  .sort((first, second) => second.count - first.count)
                  .filter((item) => item.count > 0)
                  .map((item) => {
                    if (item.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          css={menuCss}
                          selected={
                            filters.securityMarketRules &&
                            filters.securityMarketRules.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("securityMarketRules", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={marketRuleLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                        { computeValues(item.segments, filters.securityMarketSegments) &&
                          computeValues(item.segments, filters.securityMarketSegments)
                            .sort((first, second) => second.count - first.count)
                            .filter((subItem) => subItem.count > 0)
                            .map((subItem) => {
                              if (subItem.count == -1)
                                return (
                                  <div css={style.menuNoItem}>No filter available</div>
                                );

                              return (
                                <div css={style.menuSubItem}>
                                  <Menu
                                    key={item.name}
                                    css={menuCss}
                                    selected={
                                      filters.securityMarketSegments &&
                                      filters.securityMarketSegments.includes(subItem.name)
                                    }
                                    onSelect={() =>
                                      handleFilterChange("securityMarketSegments", subItem.name)
                                    }
                                    childLabel={
                                      (
                                        <CountItem
                                          name={marketSegmentLabel(subItem.name)}
                                          count={subItem.count}
                                          countCss={css`
                                            position: absolute;
                                            right: 1.5rem;  
                                          `}
                                        />
                                      ) as unknown as string
                                    }
                                  />
                                </div>
                              );
                            })
                          }

                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="leaf"
          label="LGX Information"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="tag" />
              </div>
              <div css={style.toggleLabel}>LGX</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.lgxOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.lgxOnly === true}
                onClick={() => handleFilterChange("lgxOnly", !filters.lgxOnly)}
              />
            </div>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            className="open"
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="folder"
              label="Sustainable Bonds"
              width="100%;"
              expanded
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.lgxSustainableBonds,
                filters.lgxSustainableBonds
              ) &&
                computeValues(
                  filterCount && filterCount.lgxSustainableBonds,
                  filters.lgxSustainableBonds
                )
                  .sort((first, second) => second.count - first.count)
                  .map((item) => {
                    if (item.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.lgxSustainableBonds &&
                            filters.lgxSustainableBonds.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("lgxSustainableBonds", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={lgxSustainableBondLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            className="open"
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="folder"
              label="LGX Funds"
              width="100%;"
              expanded
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.lgxSustainableFunds,
                filters.lgxSustainableFunds
              ) &&
                computeValues(
                  filterCount && filterCount.lgxSustainableFunds,
                  filters.lgxSustainableFunds
                )
                  .sort((first, second) => second.count - first.count)
                  .map((item) => {
                    if (item.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.lgxSustainableFunds &&
                            filters.lgxSustainableFunds.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("lgxSustainableFunds", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={lgxSustainableFundLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <FontAwesomeIcon icon={["fas", "person-half-dress"]} />
              </div>
              <div css={style.toggleLabel}>Gender-focused bonds</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.genderBondOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.genderBondOnly === true}
                onClick={() =>
                  handleFilterChange("genderBondOnly", !filters.genderBondOnly)
                }
              />
            </div>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="file-certificate"
              label="Standards"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.lgxStandards,
                filters.lgxStandards
              ) &&
                computeValues(
                  filterCount && filterCount.lgxStandards,
                  filters.lgxStandards
                )
                  .filter((item) => item.name !== "ownframework" && item.name !== "eu_taxonomy" && item.name !== "lma - green loan principles")
                  .sort((first, second) => second.count - first.count)
                  .map((item) => {
                    if (item.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.lgxStandards &&
                            filters.lgxStandards.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("lgxStandards", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={lgxStandardLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>

          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="file-alt" />
              </div>
              <div css={style.toggleLabel}>External review</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.lgxExternalReviewOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.lgxExternalReviewOnly === true}
                onClick={() =>
                  handleFilterChange(
                    "lgxExternalReviewOnly",
                    !filters.lgxExternalReviewOnly
                  )
                }
              />
            </div>
          </div>

          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="file-chart-line" />
              </div>
              <div css={style.toggleLabel}>Post issuance Reporting</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.lgxPostIssuanceOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.lgxPostIssuanceOnly === true}
                onClick={() =>
                  handleFilterChange(
                    "lgxPostIssuanceOnly",
                    !filters.lgxPostIssuanceOnly
                  )
                }
              />
            </div>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="calendar-alt"
              label="UN Sustainable Development Goals"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.lgxSdgs,
                filters.lgxSdgs
              ) && (
                <SDGsList
                  sdgs={computeValues(
                    filterCount && filterCount.lgxSdgs,
                    filters.lgxSdgs
                  )
                    .sort(
                      (first, second) =>
                        Number(
                          first.name.substring(first.name.indexOf("_") + 1)
                        ) -
                        Number(
                          second.name.substring(second.name.indexOf("_") + 1)
                        )
                    )
                    .map((el) => el.name)}
                  onSelect={(sdgs) => handleFilterChange("lgxSdgs", sdgs)}
                  selected={filters.lgxSdgs}
                />
              )}
            </MenuWithChild>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="calendar-alt"
              label="Eligible categories"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.lgxEligibleCategories,
                filters.lgxEligibleCategories
              ) &&
                computeValues(
                  filterCount && filterCount.lgxEligibleCategories,
                  filters.lgxEligibleCategories
                )
                  .sort((first, second) =>
                    lgxEligibleCategorySort(first.name, second.name)
                  )
                  .map((item) => {
                    if (item.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.lgxEligibleCategories &&
                            filters.lgxEligibleCategories.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange(
                              "lgxEligibleCategories",
                              item.name
                            )
                          }
                          childLabel={
                            (
                              <CountItem
                                name={lgxEligibleCategoryLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="exchange-alt"
          label="Trading Information"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="dollar-sign"
              label="Currency"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.currencies,
                filters.currency
              ) &&
                computeValues(
                  filterCount && filterCount.currencies,
                  filters.currency
                )
                  .sort((first, second) => second.count - first.count)
                  .map((currency) => {
                    if (currency.count == -1)
                      return (
                        <div css={style.menuNoItem}>No filters available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={currency.name}
                          css={menuCss}
                          selected={filters.currency === currency.name}
                          onSelect={() =>
                            handleFilterChange("currency", currency.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={currency.name}
                                count={currency.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
        </MenuWithChild>
      </div>
    </div>
  );
}
