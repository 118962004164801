/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { renderLink } from "../../utils/helper";
import { breakpointMax, breakpointMaxMobile, breakpointMobileSmall } from "../../style";

export interface CaseStudiesHeaderProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const CaseStudiesHeader: React.FunctionComponent<CaseStudiesHeaderProps> = (props: CaseStudiesHeaderProps): React.ReactElement => {
    const { fields, rendering } = props;
    const [isCentered, setIsCentered] = React.useState(false);

    React.useEffect(() => {
        const productElements = document.querySelectorAll('.product-box');
        setIsCentered(productElements.length <= 3);
    }, [rendering]);

    const backgroundImage: any = getFieldValue<any>(fields, "backgroundImage", null);
    const logo: any = getFieldValue<any>(fields, "logo", null);
    const title: string = getFieldValue<string>(fields, "title", "");
    const subtitle: string = getFieldValue<string>(fields, "subtitle", "");
    const productsUsed: string = getFieldValue<string>(fields, "productTitle", "");

    const container = css`
        width: 100%;
        height: 801px;
        flex-shrink: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 44.72%, #FFF 98.79%), url(${renderLink(backgroundImage && backgroundImage.src)}) no-repeat lightgray 0px -20.071px / 100% 152.164%;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            height: 1010px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 44.72%, #FFF 98.79%), url(${renderLink(backgroundImage && backgroundImage.src)}) no-repeat lightgray 0px -20.071px / 100% 152.164%;
            background-position: 50% 50%;
            background-size: cover;
            padding-right: 8px;
            padding-left: 8px;
        }
    `;
    const textContainer = css`
        display: flex;
        width: 100%;
        max-width: 1280px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin: auto;
        padding-top: 127px;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
        }
    `;
    const logoImage = css`
        width: 337px;
        height: 96px;
        background: url(${renderLink(logo && logo.src)}) 50% / cover no-repeat;
        @media (max-width: ${breakpointMax}) {
            width: 337px;
        }
    `;
    const titleField = css`
        color: #2E3943;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: 100%; 
        @media (max-width: ${breakpointMax}) {
            font-size: 34px;
        }
    `;
    const subTitle = css`
        color: #2E3943;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 100%;
        @media (max-width: ${breakpointMax}) {
            font-size: 16px;
        }
    `;
    const boxContainer = css`
        display: flex;
        width: 867px;
        padding: 16px;
        align-items: flex-start;
        gap: 29px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 6px 11px 11px rgba(0, 0, 0, 0.10);
        @media (max-width: ${breakpointMax}) {
            width: 326px;
            flex-direction: column;
            margin-top: 24px;
            gap: 16px;
        }
        @media (min-width: ${breakpointMobileSmall}) and (max-width: ${breakpointMaxMobile}) { 
            width: 300px;
        }   
    `;
    const boxItem = css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        @media (max-width: ${breakpointMax}) {
            width: 326px;
            gap: 8px;
        }
    `;
    const boxTitle = css`
        color: #2E3943;
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
    `;
    const boxSubtitle = css`
        color: #2E3943;
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
    `;
    const dividerLine = css`
        content: "";
        border: 1px solid #CED4D9;
        height: 31px;
        margin-top: 1px;
        margin-bottom: -1px;
        @media (max-width: ${breakpointMax}) {
            height: unset;
            width: 100%;
            border-top: 1px solid #CED4D9;
            margin-top: 0;
            margin-bottom: 0;
        }
    `;
    const productContainer = css`
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 24px;
        width: 100%;
        max-width: 1280px;
        align-items: center;
        height: 175px;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            max-width: 768px;
            flex-direction: column;
            display: flow;
        }
    `;
    const productTitle = css`
        color: #2E3943;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        @media (max-width: ${breakpointMax}) {
            font-size: 24px;
            text-align: center;
        }
    `;
    const productBoxes = css`
        margin-top: 24px;
        display: flex;
        gap: 24px;
        &::-webkit-scrollbar {
            display: none;
        }
        @media (max-width: ${breakpointMax}) {
            flex-direction: row;
            gap: 16px;
            overflow-x: auto;
            white-space: nowrap;
            flex-wrap: nowrap;
            -webkit-overflow-scrolling: touch;
        }
    `;

    const centeredProductBoxes = css`
    ${productBoxes};
    @media (max-width: ${breakpointMax}) {
        justify-content: center;
    }
    `;

    const boxes = [1, 2, 3];

    return (
        <div css={container}>
            <div css={textContainer}>
                <div css={logoImage} />
                <div css={titleField}>
                    {title}
                </div>
                <div css={subTitle}>
                    {subtitle}
                </div>
                <div css={boxContainer}>
                    {boxes.map((box, index) => {
                        const title = (fields[`boxTitle${index + 1}`] as any)?.value;
                        const subtitle = (fields[`boxSubtitle${index + 1}`] as any)?.value;
                        return (
                            <React.Fragment key={index}>
                                {title && subtitle ? (
                                    <div css={boxItem}>
                                        <div css={boxTitle}>
                                            {title}
                                        </div>
                                        <div css={boxSubtitle}>
                                            {subtitle}
                                        </div>
                                    </div>
                                ) : null}
                                {index < boxes.length - 1 && <hr css={dividerLine} />}
                            </React.Fragment>
                        );
                    })}
                </div>
                <div css={productContainer}>
                    <div css={productTitle}>
                        {productsUsed}
                        <div css={isCentered ? centeredProductBoxes : productBoxes}>
                            <Placeholder name="jss-case-studies-products" rendering={rendering} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    