
import * as React from 'react';

import { AdvantagesOfLgx } from '../sitecore-components/AdvantagesOfLgx';
import { AnchorComponent } from '../sitecore-components/AnchorComponent';
import { ArticleComponent } from '../sitecore-components/ArticleComponent';
import { ArticleHeaderWithBackground } from '../sitecore-components/ArticleHeaderWithBackground';
import { AssitanceComponent } from '../sitecore-components/AssitanceComponent';
import { BLogArticle } from '../sitecore-components/BLogArticle';
import { BackgroundContent } from '../sitecore-components/BackgroundContent';
import { BackgroundTwoColumns } from '../sitecore-components/BackgroundTwoColumns';
import { BackgroundWithActions } from '../sitecore-components/BackgroundWithActions';
import { BackgroundWithItemsLinksComponent } from '../sitecore-components/BackgroundWithItemsLinksComponent';
import { BannerBrandmarksCard } from '../sitecore-components/BannerBrandmarksCard';
import { BannerBrandmarksContainer } from '../sitecore-components/BannerBrandmarksContainer';
import { BannerBrandmarksImages } from '../sitecore-components/BannerBrandmarksImages';
import { BannerBrandmarksText } from '../sitecore-components/BannerBrandmarksText';
import { BannerBrandmarksVideo } from '../sitecore-components/BannerBrandmarksVideo';
import { BannerComponent } from '../sitecore-components/BannerComponent';
import { BenefitsComponent } from '../sitecore-components/BenefitsComponent';
import { BigMenuComponent } from '../sitecore-components/BigMenuComponent';
import { BlogAd } from '../sitecore-components/BlogAd';
import { BlogArticleComponent } from '../sitecore-components/BlogArticleComponent';
import { BlogArticleContainer } from '../sitecore-components/BlogArticleContainer';
import { BlogArticlesCorner } from '../sitecore-components/BlogArticlesCorner';
import { BlogAuthorComponent } from '../sitecore-components/BlogAuthorComponent';
import { BlogAuthorsList } from '../sitecore-components/BlogAuthorsList';
import { BlogBlockQuote } from '../sitecore-components/BlogBlockQuote';
import { BlogCategories } from '../sitecore-components/BlogCategories';
import { BlogCategoryHeader } from '../sitecore-components/BlogCategoryHeader';
import { BlogCenterColumnSection } from '../sitecore-components/BlogCenterColumnSection';
import { BlogContactComponent } from '../sitecore-components/BlogContactComponent';
import { BlogHighlight } from '../sitecore-components/BlogHighlight';
import { BlogHomeArticlesFromCategory } from '../sitecore-components/BlogHomeArticlesFromCategory';
import { BlogHomeComponent } from '../sitecore-components/BlogHomeComponent';
import { BlogImageWithLink } from '../sitecore-components/BlogImageWithLink';
import { BlogImages } from '../sitecore-components/BlogImages';
import { BlogLatestArticles } from '../sitecore-components/BlogLatestArticles';
import { BlogLeftColumnSection } from '../sitecore-components/BlogLeftColumnSection';
import { BlogRichText } from '../sitecore-components/BlogRichText';
import { BlogRightColumnSection } from '../sitecore-components/BlogRightColumnSection';
import { BlogSubscribeToNewsletter } from '../sitecore-components/BlogSubscribeToNewsletter';
import { BlogTableOfContent } from '../sitecore-components/BlogTableOfContent';
import { BlogTagsContainer } from '../sitecore-components/BlogTagsContainer';
import { BlogVideosCarousel } from '../sitecore-components/BlogVideosCarousel';
import { BoardOfMembers } from '../sitecore-components/BoardOfMembers';
import { Breadcrumb } from '../sitecore-components/Breadcrumb';
import { CardWithBackgroundAndTextComponent } from '../sitecore-components/CardWithBackgroundAndTextComponent';
import { CardsWithBackground } from '../sitecore-components/CardsWithBackground';
import { Caroussel } from '../sitecore-components/Caroussel';
import { CarousselContent } from '../sitecore-components/CarousselContent';
import { CarousselWithPicture } from '../sitecore-components/CarousselWithPicture';
import { CaseStudiesArticle } from '../sitecore-components/CaseStudiesArticle';
import { CaseStudiesBox } from '../sitecore-components/CaseStudiesBox';
import { CaseStudiesBoxContainer } from '../sitecore-components/CaseStudiesBoxContainer';
import { CaseStudiesContainer } from '../sitecore-components/CaseStudiesContainer';
import { CaseStudiesFeaturedStory } from '../sitecore-components/CaseStudiesFeaturedStory';
import { CaseStudiesHeader } from '../sitecore-components/CaseStudiesHeader';
import { CaseStudiesLatestStories } from '../sitecore-components/CaseStudiesLatestStories';
import { CaseStudiesProduct } from '../sitecore-components/CaseStudiesProduct';
import { CaseStudiesText } from '../sitecore-components/CaseStudiesText';
import { ChangingTheWay } from '../sitecore-components/ChangingTheWay';
import { ChinaCIBM } from '../sitecore-components/ChinaCIBM';
import { ChinaIndexSeries } from '../sitecore-components/ChinaIndexSeries';
import { ChinaSSE } from '../sitecore-components/ChinaSSE';
import { ClosingDay } from '../sitecore-components/ClosingDay';
import { Contact } from '../sitecore-components/Contact';
import { ContactUs } from '../sitecore-components/ContactUs';
import { ContentBlock } from '../sitecore-components/ContentBlock';
import { CorporateGovernanceReport } from '../sitecore-components/CorporateGovernanceReport';
import { CssfApprovalsComponent } from '../sitecore-components/CssfApprovalsComponent';
import { CurrentOffers } from '../sitecore-components/CurrentOffers';
import { CurrentOffersCard } from '../sitecore-components/CurrentOffersCard';
import { CurrentOffersContainer } from '../sitecore-components/CurrentOffersContainer';
import { DataMarket } from '../sitecore-components/DataMarket';
import { DataMarketSearch } from '../sitecore-components/DataMarketSearch';
import { DisclaimerComponent } from '../sitecore-components/DisclaimerComponent';
import { DiscoverAd } from '../sitecore-components/DiscoverAd';
import { DoubleCardComponent } from '../sitecore-components/DoubleCardComponent';
import { DoubleColsWithTicks } from '../sitecore-components/DoubleColsWithTicks';
import { DoubleColumnsWithButtons } from '../sitecore-components/DoubleColumnsWithButtons';
import { DoubleColumnsWithDoubleColumnsComponent } from '../sitecore-components/DoubleColumnsWithDoubleColumnsComponent';
import { DoubleColumnsWithHexaList } from '../sitecore-components/DoubleColumnsWithHexaList';
import { DoublePartsComponent } from '../sitecore-components/DoublePartsComponent';
import { DropdownBlocsComponent } from '../sitecore-components/DropdownBlocsComponent';
import { EventRessources } from '../sitecore-components/EventRessources';
import { EventsCards } from '../sitecore-components/EventsCards';
import { FooterComponent } from '../sitecore-components/FooterComponent';
import { FormComponent } from '../sitecore-components/FormComponent';
import { FormsRessources } from '../sitecore-components/FormsRessources';
import { FreeTextComponent } from '../sitecore-components/FreeTextComponent';
import { GeoMapData } from '../sitecore-components/GeoMapData';
import { Glossary } from '../sitecore-components/Glossary';
import { GreenBannerWithCardsComponent } from '../sitecore-components/GreenBannerWithCardsComponent';
import { GreenDoubleColumnsComponent } from '../sitecore-components/GreenDoubleColumnsComponent';
import { GreenIndicesComponent } from '../sitecore-components/GreenIndicesComponent';
import { GreyBackgroundDataComponent } from '../sitecore-components/GreyBackgroundDataComponent';
import { GreyBannerText } from '../sitecore-components/GreyBannerText';
import { HeaderDataActions } from '../sitecore-components/HeaderDataActions';
import { HexaBackground } from '../sitecore-components/HexaBackground';
import { HexaLabelsWithTextComponent } from '../sitecore-components/HexaLabelsWithTextComponent';
import { HistogramWithPicture } from '../sitecore-components/HistogramWithPicture';
import { Home } from '../sitecore-components/Home';
import { HomeComingJoinComponent } from '../sitecore-components/HomeComingJoinComponent';
import { HomeComingNextComponent } from '../sitecore-components/HomeComingNextComponent';
import { HomeDataComponent } from '../sitecore-components/HomeDataComponent';
import { HomeInformations } from '../sitecore-components/HomeInformations';
import { HomeInformationsComponent } from '../sitecore-components/HomeInformationsComponent';
import { HomeTopComponent } from '../sitecore-components/HomeTopComponent';
import { HomeUniqueComponent } from '../sitecore-components/HomeUniqueComponent';
import { HorizontalTimeline } from '../sitecore-components/HorizontalTimeline';
import { HowToListWizard } from '../sitecore-components/HowToListWizard';
import { HowWeCanHelp } from '../sitecore-components/HowWeCanHelp';
import { ImageComponent } from '../sitecore-components/ImageComponent';
import { IndexDescription } from '../sitecore-components/IndexDescription';
import { IndexDetail } from '../sitecore-components/IndexDetail';
import { IndexDetailsComponent } from '../sitecore-components/IndexDetailsComponent';
import { IndexListView } from '../sitecore-components/IndexListView';
import { Indices } from '../sitecore-components/Indices';
import { InternshipOffers } from '../sitecore-components/InternshipOffers';
import { IntershipOffers } from '../sitecore-components/IntershipOffers';
import { IssuerCardContent } from '../sitecore-components/IssuerCardContent';
import { JobRequirements } from '../sitecore-components/JobRequirements';
import { LGXAcademyCourse } from '../sitecore-components/LGXAcademyCourse';
import { LGXCoursesComponent } from '../sitecore-components/LGXCoursesComponent';
import { LanguageSwitcher } from '../sitecore-components/LanguageSwitcher';
import { LatestPressReleases } from '../sitecore-components/LatestPressReleases';
import { LatestRegulationPublications } from '../sitecore-components/LatestRegulationPublications';
import { LinkedArticle } from '../sitecore-components/LinkedArticle';
import { ListingProcess } from '../sitecore-components/ListingProcess';
import { LuxXPrimeDiscover } from '../sitecore-components/LuxXPrimeDiscover';
import { LuxXPrimeDoubleContent } from '../sitecore-components/LuxXPrimeDoubleContent';
import { LuxXPrimeDoubleTrading } from '../sitecore-components/LuxXPrimeDoubleTrading';
import { LuxXPrimeFallersList } from '../sitecore-components/LuxXPrimeFallersList';
import { LuxXPrimeLastTradedList } from '../sitecore-components/LuxXPrimeLastTradedList';
import { LuxXPrimeLatestNoticeCards } from '../sitecore-components/LuxXPrimeLatestNoticeCards';
import { LuxXPrimeLatestNoticeTabs } from '../sitecore-components/LuxXPrimeLatestNoticeTabs';
import { LuxXPrimeMostViewList } from '../sitecore-components/LuxXPrimeMostViewList';
import { LuxXPrimeRisersList } from '../sitecore-components/LuxXPrimeRisersList';
import { LuxXPrimeTopTradedList } from '../sitecore-components/LuxXPrimeTopTradedList';
import { MainContainer } from '../sitecore-components/MainContainer';
import { MainHeader } from '../sitecore-components/MainHeader';
import { ManagementMember } from '../sitecore-components/ManagementMember';
import { MarketCapitalisation } from '../sitecore-components/MarketCapitalisation';
import { MarketCapitalisationComponent } from '../sitecore-components/MarketCapitalisationComponent';
import { MarketCompleteOutagesHistory } from '../sitecore-components/MarketCompleteOutagesHistory';
import { MarketData } from '../sitecore-components/MarketData';
import { MarketDataProductsIndices } from '../sitecore-components/MarketDataProductsIndices';
import { MarketNews } from '../sitecore-components/MarketNews';
import { MarketOutagePlan } from '../sitecore-components/MarketOutagePlan';
import { MarketOutagesHistory } from '../sitecore-components/MarketOutagesHistory';
import { MarketStatisticsComponent } from '../sitecore-components/MarketStatisticsComponent';
import { MarketStatisticsData } from '../sitecore-components/MarketStatisticsData';
import { MarketStatisticsRisersAndFallers } from '../sitecore-components/MarketStatisticsRisersAndFallers';
import { MarketStatisticsTopTradedSecurities } from '../sitecore-components/MarketStatisticsTopTradedSecurities';
import { MarketStatus } from '../sitecore-components/MarketStatus';
import { MeetOurExperts } from '../sitecore-components/MeetOurExperts';
import { MeetOurExpertsCard } from '../sitecore-components/MeetOurExpertsCard';
import { MeetOurExpertsContainer } from '../sitecore-components/MeetOurExpertsContainer';
import { MeetOurExpertsSection } from '../sitecore-components/MeetOurExpertsSection';
import { MeetOurExpertsTag } from '../sitecore-components/MeetOurExpertsTag';
import { MemberCardData } from '../sitecore-components/MemberCardData';
import { MemberCardHeader } from '../sitecore-components/MemberCardHeader';
import { MembershipApplicationTabs } from '../sitecore-components/MembershipApplicationTabs';
import { MixedChart } from '../sitecore-components/MixedChart';
import { Navigation } from '../sitecore-components/Navigation';
import { NotFoundPage } from '../sitecore-components/NotFoundPage';
import { OAMSearchComponent } from '../sitecore-components/OAMSearchComponent';
import { OAMSearchContent } from '../sitecore-components/OAMSearchContent';
import { OfficialList } from '../sitecore-components/OfficialList';
import { OpeningAndClosingDays } from '../sitecore-components/OpeningAndClosingDays';
import { OpeningRowComponent } from '../sitecore-components/OpeningRowComponent';
import { OpeningYearComponent } from '../sitecore-components/OpeningYearComponent';
import { Partners } from '../sitecore-components/Partners';
import { PdfViewerComponent } from '../sitecore-components/PdfViewerComponent';
import { PeopleCard } from '../sitecore-components/PeopleCard';
import { Popup } from '../sitecore-components/Popup';
import { PopupContainer } from '../sitecore-components/PopupContainer';
import { PreciousMetalsList } from '../sitecore-components/PreciousMetalsList';
import { PressArticle } from '../sitecore-components/PressArticle';
import { PressRelease } from '../sitecore-components/PressRelease';
import { PressReleaseContainer } from '../sitecore-components/PressReleaseContainer';
import { PressRessources } from '../sitecore-components/PressRessources';
import { PricesComponent } from '../sitecore-components/PricesComponent';
import { ProgrammeCardContent } from '../sitecore-components/ProgrammeCardContent';
import { PublicationContent } from '../sitecore-components/PublicationContent';
import { RTS27RegulationFilesList } from '../sitecore-components/RTS27RegulationFilesList';
import { ReasonsToTrade } from '../sitecore-components/ReasonsToTrade';
import { RisersAndFallersStats } from '../sitecore-components/RisersAndFallersStats';
import { RisersFallersStatistics } from '../sitecore-components/RisersFallersStatistics';
import { RtsComponent } from '../sitecore-components/RtsComponent';
import { RtsSummaryComponent } from '../sitecore-components/RtsSummaryComponent';
import { RulesAndRegulations } from '../sitecore-components/RulesAndRegulations';
import { SSEIndexSeries } from '../sitecore-components/SSEIndexSeries';
import { SearchComponent } from '../sitecore-components/SearchComponent';
import { SearchContent } from '../sitecore-components/SearchContent';
import { SearchInputHeader } from '../sitecore-components/SearchInputHeader';
import { SecurityCardContent } from '../sitecore-components/SecurityCardContent';
import { Shareholders } from '../sitecore-components/Shareholders';
import { SimpleRedTickComponent } from '../sitecore-components/SimpleRedTickComponent';
import { SitecoreHeader } from '../sitecore-components/SitecoreHeader';
import { SquareLink } from '../sitecore-components/SquareLink';
import { SquareWitHbackground } from '../sitecore-components/SquareWitHbackground';
import { SubscribeToNewsletter } from '../sitecore-components/SubscribeToNewsletter';
import { SustainableFinanceStandardsAndLabels } from '../sitecore-components/SustainableFinanceStandardsAndLabels';
import { SustainableTabsComponent } from '../sitecore-components/SustainableTabsComponent';
import { TableWithImages } from '../sitecore-components/TableWithImages';
import { TabsContainerComponent } from '../sitecore-components/TabsContainerComponent';
import { TabsWithTimelines } from '../sitecore-components/TabsWithTimelines';
import { TeamMembers } from '../sitecore-components/TeamMembers';
import { Test } from '../sitecore-components/Test';
import { TestComponentName } from '../sitecore-components/TestComponentName';
import { TextWithActionAndBackground } from '../sitecore-components/TextWithActionAndBackground';
import { TextWithButtonActionComponent } from '../sitecore-components/TextWithButtonActionComponent';
import { TimelineComponent } from '../sitecore-components/TimelineComponent';
import { TimelineRow } from '../sitecore-components/TimelineRow';
import { TitleDescriptionAndGreyCards } from '../sitecore-components/TitleDescriptionAndGreyCards';
import { TitleTicksAndActionComponent } from '../sitecore-components/TitleTicksAndActionComponent';
import { Tradingmembers } from '../sitecore-components/Trading-members';
import { TradingData } from '../sitecore-components/TradingData';
import { TradingDataComponent } from '../sitecore-components/TradingDataComponent';
import { TradingHomeTemplate } from '../sitecore-components/TradingHomeTemplate';
import { TradingItemCard } from '../sitecore-components/TradingItemCard';
import { TradingNews } from '../sitecore-components/TradingNews';
import { TrustingUsPartners } from '../sitecore-components/TrustingUsPartners';
import { TwoBlocsWithBackground } from '../sitecore-components/TwoBlocsWithBackground';
import { TwoCols } from '../sitecore-components/TwoCols';
import { TwoColsBlocWithTitleAndSubTitle } from '../sitecore-components/TwoColsBlocWithTitleAndSubTitle';
import { TwoColsWithBackgroundAndHexagon } from '../sitecore-components/TwoColsWithBackgroundAndHexagon';
import { TwoColsWithPoints } from '../sitecore-components/TwoColsWithPoints';
import { TwoColumnsContainer } from '../sitecore-components/TwoColumnsContainer';
import { TwoColumnsDisplayer } from '../sitecore-components/TwoColumnsDisplayer';
import { TwoColumnsWithCourse } from '../sitecore-components/TwoColumnsWithCourse';
import { TwoColumnsWithHexagon } from '../sitecore-components/TwoColumnsWithHexagon';
import { UsefullLinks } from '../sitecore-components/UsefullLinks';
import { UsefullPublications } from '../sitecore-components/UsefullPublications';
import { WWDCoreValues } from '../sitecore-components/WWDCoreValues';
import { WebsiteHeader } from '../sitecore-components/WebsiteHeader';
import { WebsiteMenu } from '../sitecore-components/WebsiteMenu';
import { WorldMap } from '../sitecore-components/WorldMap';
import { ZtestComponent } from '../sitecore-components/ZtestComponent';

const components: Map<string, any> = new Map<string, React.Component>();

components.set('AdvantagesOfLgx', AdvantagesOfLgx);
components.set('AnchorComponent', AnchorComponent);
components.set('ArticleComponent', ArticleComponent);
components.set('ArticleHeaderWithBackground', ArticleHeaderWithBackground);
components.set('AssitanceComponent', AssitanceComponent);
components.set('BLogArticle', BLogArticle);
components.set('BackgroundContent', BackgroundContent);
components.set('BackgroundTwoColumns', BackgroundTwoColumns);
components.set('BackgroundWithActions', BackgroundWithActions);
components.set('BackgroundWithItemsLinksComponent', BackgroundWithItemsLinksComponent);
components.set('BannerBrandmarksCard', BannerBrandmarksCard);
components.set('BannerBrandmarksContainer', BannerBrandmarksContainer);
components.set('BannerBrandmarksImages', BannerBrandmarksImages);
components.set('BannerBrandmarksText', BannerBrandmarksText);
components.set('BannerBrandmarksVideo', BannerBrandmarksVideo);
components.set('BannerComponent', BannerComponent);
components.set('BenefitsComponent', BenefitsComponent);
components.set('BigMenuComponent', BigMenuComponent);
components.set('BlogAd', BlogAd);
components.set('BlogArticleComponent', BlogArticleComponent);
components.set('BlogArticleContainer', BlogArticleContainer);
components.set('BlogArticlesCorner', BlogArticlesCorner);
components.set('BlogAuthorComponent', BlogAuthorComponent);
components.set('BlogAuthorsList', BlogAuthorsList);
components.set('BlogBlockQuote', BlogBlockQuote);
components.set('BlogCategories', BlogCategories);
components.set('BlogCategoryHeader', BlogCategoryHeader);
components.set('BlogCenterColumnSection', BlogCenterColumnSection);
components.set('BlogContactComponent', BlogContactComponent);
components.set('BlogHighlight', BlogHighlight);
components.set('BlogHomeArticlesFromCategory', BlogHomeArticlesFromCategory);
components.set('BlogHomeComponent', BlogHomeComponent);
components.set('BlogImageWithLink', BlogImageWithLink);
components.set('BlogImages', BlogImages);
components.set('BlogLatestArticles', BlogLatestArticles);
components.set('BlogLeftColumnSection', BlogLeftColumnSection);
components.set('BlogRichText', BlogRichText);
components.set('BlogRightColumnSection', BlogRightColumnSection);
components.set('BlogSubscribeToNewsletter', BlogSubscribeToNewsletter);
components.set('BlogTableOfContent', BlogTableOfContent);
components.set('BlogTagsContainer', BlogTagsContainer);
components.set('BlogVideosCarousel', BlogVideosCarousel);
components.set('BoardOfMembers', BoardOfMembers);
components.set('Breadcrumb', Breadcrumb);
components.set('CardWithBackgroundAndTextComponent', CardWithBackgroundAndTextComponent);
components.set('CardsWithBackground', CardsWithBackground);
components.set('Caroussel', Caroussel);
components.set('CarousselContent', CarousselContent);
components.set('CarousselWithPicture', CarousselWithPicture);
components.set('CaseStudiesArticle', CaseStudiesArticle);
components.set('CaseStudiesBox', CaseStudiesBox);
components.set('CaseStudiesBoxContainer', CaseStudiesBoxContainer);
components.set('CaseStudiesContainer', CaseStudiesContainer);
components.set('CaseStudiesFeaturedStory', CaseStudiesFeaturedStory);
components.set('CaseStudiesHeader', CaseStudiesHeader);
components.set('CaseStudiesLatestStories', CaseStudiesLatestStories);
components.set('CaseStudiesProduct', CaseStudiesProduct);
components.set('CaseStudiesText', CaseStudiesText);
components.set('ChangingTheWay', ChangingTheWay);
components.set('ChinaCIBM', ChinaCIBM);
components.set('ChinaIndexSeries', ChinaIndexSeries);
components.set('ChinaSSE', ChinaSSE);
components.set('ClosingDay', ClosingDay);
components.set('Contact', Contact);
components.set('ContactUs', ContactUs);
components.set('ContentBlock', ContentBlock);
components.set('CorporateGovernanceReport', CorporateGovernanceReport);
components.set('CssfApprovalsComponent', CssfApprovalsComponent);
components.set('CurrentOffers', CurrentOffers);
components.set('CurrentOffersCard', CurrentOffersCard);
components.set('CurrentOffersContainer', CurrentOffersContainer);
components.set('DataMarket', DataMarket);
components.set('DataMarketSearch', DataMarketSearch);
components.set('DisclaimerComponent', DisclaimerComponent);
components.set('DiscoverAd', DiscoverAd);
components.set('DoubleCardComponent', DoubleCardComponent);
components.set('DoubleColsWithTicks', DoubleColsWithTicks);
components.set('DoubleColumnsWithButtons', DoubleColumnsWithButtons);
components.set('DoubleColumnsWithDoubleColumnsComponent', DoubleColumnsWithDoubleColumnsComponent);
components.set('DoubleColumnsWithHexaList', DoubleColumnsWithHexaList);
components.set('DoublePartsComponent', DoublePartsComponent);
components.set('DropdownBlocsComponent', DropdownBlocsComponent);
components.set('EventRessources', EventRessources);
components.set('EventsCards', EventsCards);
components.set('FooterComponent', FooterComponent);
components.set('FormComponent', FormComponent);
components.set('FormsRessources', FormsRessources);
components.set('FreeTextComponent', FreeTextComponent);
components.set('GeoMapData', GeoMapData);
components.set('Glossary', Glossary);
components.set('GreenBannerWithCardsComponent', GreenBannerWithCardsComponent);
components.set('GreenDoubleColumnsComponent', GreenDoubleColumnsComponent);
components.set('GreenIndicesComponent', GreenIndicesComponent);
components.set('GreyBackgroundDataComponent', GreyBackgroundDataComponent);
components.set('GreyBannerText', GreyBannerText);
components.set('HeaderDataActions', HeaderDataActions);
components.set('HexaBackground', HexaBackground);
components.set('HexaLabelsWithTextComponent', HexaLabelsWithTextComponent);
components.set('HistogramWithPicture', HistogramWithPicture);
components.set('Home', Home);
components.set('HomeComingJoinComponent', HomeComingJoinComponent);
components.set('HomeComingNextComponent', HomeComingNextComponent);
components.set('HomeDataComponent', HomeDataComponent);
components.set('HomeInformations', HomeInformations);
components.set('HomeInformationsComponent', HomeInformationsComponent);
components.set('HomeTopComponent', HomeTopComponent);
components.set('HomeUniqueComponent', HomeUniqueComponent);
components.set('HorizontalTimeline', HorizontalTimeline);
components.set('HowToListWizard', HowToListWizard);
components.set('HowWeCanHelp', HowWeCanHelp);
components.set('ImageComponent', ImageComponent);
components.set('IndexDescription', IndexDescription);
components.set('IndexDetail', IndexDetail);
components.set('IndexDetailsComponent', IndexDetailsComponent);
components.set('IndexListView', IndexListView);
components.set('Indices', Indices);
components.set('InternshipOffers', InternshipOffers);
components.set('IntershipOffers', IntershipOffers);
components.set('IssuerCardContent', IssuerCardContent);
components.set('JobRequirements', JobRequirements);
components.set('LGXAcademyCourse', LGXAcademyCourse);
components.set('LGXCoursesComponent', LGXCoursesComponent);
components.set('LanguageSwitcher', LanguageSwitcher);
components.set('LatestPressReleases', LatestPressReleases);
components.set('LatestRegulationPublications', LatestRegulationPublications);
components.set('LinkedArticle', LinkedArticle);
components.set('ListingProcess', ListingProcess);
components.set('LuxXPrimeDiscover', LuxXPrimeDiscover);
components.set('LuxXPrimeDoubleContent', LuxXPrimeDoubleContent);
components.set('LuxXPrimeDoubleTrading', LuxXPrimeDoubleTrading);
components.set('LuxXPrimeFallersList', LuxXPrimeFallersList);
components.set('LuxXPrimeLastTradedList', LuxXPrimeLastTradedList);
components.set('LuxXPrimeLatestNoticeCards', LuxXPrimeLatestNoticeCards);
components.set('LuxXPrimeLatestNoticeTabs', LuxXPrimeLatestNoticeTabs);
components.set('LuxXPrimeMostViewList', LuxXPrimeMostViewList);
components.set('LuxXPrimeRisersList', LuxXPrimeRisersList);
components.set('LuxXPrimeTopTradedList', LuxXPrimeTopTradedList);
components.set('MainContainer', MainContainer);
components.set('MainHeader', MainHeader);
components.set('ManagementMember', ManagementMember);
components.set('MarketCapitalisation', MarketCapitalisation);
components.set('MarketCapitalisationComponent', MarketCapitalisationComponent);
components.set('MarketCompleteOutagesHistory', MarketCompleteOutagesHistory);
components.set('MarketData', MarketData);
components.set('MarketDataProductsIndices', MarketDataProductsIndices);
components.set('MarketNews', MarketNews);
components.set('MarketOutagePlan', MarketOutagePlan);
components.set('MarketOutagesHistory', MarketOutagesHistory);
components.set('MarketStatisticsComponent', MarketStatisticsComponent);
components.set('MarketStatisticsData', MarketStatisticsData);
components.set('MarketStatisticsRisersAndFallers', MarketStatisticsRisersAndFallers);
components.set('MarketStatisticsTopTradedSecurities', MarketStatisticsTopTradedSecurities);
components.set('MarketStatus', MarketStatus);
components.set('MeetOurExperts', MeetOurExperts);
components.set('MeetOurExpertsCard', MeetOurExpertsCard);
components.set('MeetOurExpertsContainer', MeetOurExpertsContainer);
components.set('MeetOurExpertsSection', MeetOurExpertsSection);
components.set('MeetOurExpertsTag', MeetOurExpertsTag);
components.set('MemberCardData', MemberCardData);
components.set('MemberCardHeader', MemberCardHeader);
components.set('MembershipApplicationTabs', MembershipApplicationTabs);
components.set('MixedChart', MixedChart);
components.set('Navigation', Navigation);
components.set('NotFoundPage', NotFoundPage);
components.set('OAMSearchComponent', OAMSearchComponent);
components.set('OAMSearchContent', OAMSearchContent);
components.set('OfficialList', OfficialList);
components.set('OpeningAndClosingDays', OpeningAndClosingDays);
components.set('OpeningRowComponent', OpeningRowComponent);
components.set('OpeningYearComponent', OpeningYearComponent);
components.set('Partners', Partners);
components.set('PdfViewerComponent', PdfViewerComponent);
components.set('PeopleCard', PeopleCard);
components.set('Popup', Popup);
components.set('PopupContainer', PopupContainer);
components.set('PreciousMetalsList', PreciousMetalsList);
components.set('PressArticle', PressArticle);
components.set('PressRelease', PressRelease);
components.set('PressReleaseContainer', PressReleaseContainer);
components.set('PressRessources', PressRessources);
components.set('PricesComponent', PricesComponent);
components.set('ProgrammeCardContent', ProgrammeCardContent);
components.set('PublicationContent', PublicationContent);
components.set('RTS27RegulationFilesList', RTS27RegulationFilesList);
components.set('ReasonsToTrade', ReasonsToTrade);
components.set('RisersAndFallersStats', RisersAndFallersStats);
components.set('RisersFallersStatistics', RisersFallersStatistics);
components.set('RtsComponent', RtsComponent);
components.set('RtsSummaryComponent', RtsSummaryComponent);
components.set('RulesAndRegulations', RulesAndRegulations);
components.set('SSEIndexSeries', SSEIndexSeries);
components.set('SearchComponent', SearchComponent);
components.set('SearchContent', SearchContent);
components.set('SearchInputHeader', SearchInputHeader);
components.set('SecurityCardContent', SecurityCardContent);
components.set('Shareholders', Shareholders);
components.set('SimpleRedTickComponent', SimpleRedTickComponent);
components.set('SitecoreHeader', SitecoreHeader);
components.set('SquareLink', SquareLink);
components.set('SquareWitHbackground', SquareWitHbackground);
components.set('SubscribeToNewsletter', SubscribeToNewsletter);
components.set('SustainableFinanceStandardsAndLabels', SustainableFinanceStandardsAndLabels);
components.set('SustainableTabsComponent', SustainableTabsComponent);
components.set('TableWithImages', TableWithImages);
components.set('TabsContainerComponent', TabsContainerComponent);
components.set('TabsWithTimelines', TabsWithTimelines);
components.set('TeamMembers', TeamMembers);
components.set('Test', Test);
components.set('TestComponentName', TestComponentName);
components.set('TextWithActionAndBackground', TextWithActionAndBackground);
components.set('TextWithButtonActionComponent', TextWithButtonActionComponent);
components.set('TimelineComponent', TimelineComponent);
components.set('TimelineRow', TimelineRow);
components.set('TitleDescriptionAndGreyCards', TitleDescriptionAndGreyCards);
components.set('TitleTicksAndActionComponent', TitleTicksAndActionComponent);
components.set('Trading-members', Tradingmembers);
components.set('TradingData', TradingData);
components.set('TradingDataComponent', TradingDataComponent);
components.set('TradingHomeTemplate', TradingHomeTemplate);
components.set('TradingItemCard', TradingItemCard);
components.set('TradingNews', TradingNews);
components.set('TrustingUsPartners', TrustingUsPartners);
components.set('TwoBlocsWithBackground', TwoBlocsWithBackground);
components.set('TwoCols', TwoCols);
components.set('TwoColsBlocWithTitleAndSubTitle', TwoColsBlocWithTitleAndSubTitle);
components.set('TwoColsWithBackgroundAndHexagon', TwoColsWithBackgroundAndHexagon);
components.set('TwoColsWithPoints', TwoColsWithPoints);
components.set('TwoColumnsContainer', TwoColumnsContainer);
components.set('TwoColumnsDisplayer', TwoColumnsDisplayer);
components.set('TwoColumnsWithCourse', TwoColumnsWithCourse);
components.set('TwoColumnsWithHexagon', TwoColumnsWithHexagon);
components.set('UsefullLinks', UsefullLinks);
components.set('UsefullPublications', UsefullPublications);
components.set('WWDCoreValues', WWDCoreValues);
components.set('WebsiteHeader', WebsiteHeader);
components.set('WebsiteMenu', WebsiteMenu);
components.set('WorldMap', WorldMap);
components.set('ZtestComponent', ZtestComponent);

export default function componentFactory(componentName: string): React.Component {
    return components.get(componentName);
};  
    