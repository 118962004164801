import * as React from 'react';

import { Button, Icon, InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';
import { SitecoreGenericTextField } from '../../interfaces';
import { Suggestion } from '../../core/components/Search/input/suggestion';
import { canUseDOM } from '../../predicates';
import hexa from './fullhexa.svg';
import hexas from './hexas.svg';
import { renderLink } from '../../utils/helper';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SearchType } from '../../enums/search';
import { DataType } from '../../enums';
import { style } from './index.style';

/** @jsx jsx */

export interface HomeTopComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
  history: any;
  onSearchInputUpdate: (textInput: string) => void;
  searchType: SearchType;
  onSearchTypeChange: (type: SearchType) => void;
  dataType: string;
  routeTextInput: string;
}

const topContainer = css`
  width: 100%;
  margin: auto;
  margin-top: 96px;
  background: url(${renderLink(hexas)});
  background-repeat: no-repeat;
  background-position: left;
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
      margin-top: 60px;
      margin-bottom: 48px;
    }
  `;
const rightHexa = css`
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: 800px;
  position: absolute;
  height: 100%;
  width: -webkit-fill-available;
  z-index: -1;
  margin-top: 64px;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

export const HomeTopComponent: React.FunctionComponent<any> = (props: HomeTopComponentProps): React.ReactElement => {
  const { fields, history, searchType, onSearchTypeChange, dataType, routeTextInput } = props;
  const [isOamPage, setIsOamPage] = useState<boolean>(false);
  const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
  const [visibilityTimer, setVisibilityTimer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);

  const [checksearchType, setSearchType] = useState(SearchType.data);
  const handleSearchTypeChange = (newSearchType: any) => {
    setSearchType(newSearchType); 
  };

  React.useEffect(() => {
    if (canUseDOM) {
      const pathname = window.location.pathname;
      const isOam = pathname.split('/').pop() === 'oam-search';
      setIsOamPage(isOam);
    }
  }, []);

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (!isOamPage && event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else {
      setSuggestionOpen(false);
    }
  };

  const redirectToSearchEngine = () => {
    if (canUseDOM) {
      if (isOamPage === true) {
        window.location.href = `/oam-search-details?q=${search}`;
      } else if (checksearchType === "content") {
        window.location.href = `/search?dataType=all&q=${search}`;
      } else {
        window.location.href = `/search?q=${search}`;
      }
    }
  };

  const [textInput, setTextInput] = React.useState<string>(routeTextInput);

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var url = `/search?q=${textInput}`;
    if( canUseDOM ){
      if(dataType in DataType) {
        url=window.location.pathname;
        var link=window.location.pathname.split('/');
        url=link[link.length-4]+`/search?dataType=${dataType}&q=${textInput}`;
        window.location.href=url;
      }
      else{
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("q", textInput);
        url = window.location.pathname + '?' + searchParams.toString();
      }
    }
    history.push(url);
    setSuggestionOpen(false);
  };

  React.useState(() => {
    setTimeout(() => {
      setVisibilityTimer(true);
    });
  });

  return (
    <div css={topContainer}>
      <div css={style.container}>
        <div css={style.left}>
          <div css={style.leftContent}>
            <div css={style.redTitle}>
              <Text tag={isH1 ? 'h1' : 'div'} field={fields && (fields.redTitle as SitecoreGenericTextField)} />
            </div>
            <div css={style.title}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
            <div css={[style.hideDesktop, { position: 'relative' }]}>
              <form
                onSubmit={formSubmitHandler}
                role="search"
              >
                <div css={style.input(visibilityTimer)}>
                  {!isOamPage ? (
                    <div css={style.checkbox}>
                      <span css={style.spanText}>Search in: </span>
                      <label css={style.label}>
                        <input
                          type="radio"
                          id="searchData"
                          name="searchType"
                          value={SearchType.data}
                          checked={checksearchType === SearchType.data}
                          onChange={() => handleSearchTypeChange(SearchType.data)}
                        />
                        <span css={style.spanTextRadio}>Data</span>
                      </label>
                      <label css={style.label}>
                        <input
                          type="radio"
                          id="searchContent"
                          name="searchType"
                          value={SearchType.content}
                          checked={checksearchType === SearchType.content}
                          onChange={() => handleSearchTypeChange(SearchType.content)}
                        />
                        <span css={style.spanTextRadio}>Content</span>
                      </label>
                    </div>
                  ) : null}
                  <InputField
                    helperText=''
                    placeholder={isOamPage ? 'Search oam by issuer, ...' : ''}
                    textError=''
                    type='text'
                    width='100%'
                    onChange={inputChangeHandler}
                    onKeyPress={(ev: any) => {
                      if (ev.key === 'Enter' && search.length > 0) {
                        redirectToSearchEngine();
                      }
                    }}
                    onClickTrailingIcon={redirectToSearchEngine}
                    trailingIcon={'search'}
                  />
                  <div css={style.advanced} onClick={redirectToSearchEngine}>
                    <Icon icon={'search'} /> <span>Advanced search</span>
                  </div>
                </div>
                <div css={style.suggestionMobile}>
                  <Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={search} />
                </div>
              </form>
            </div>
            <div css={[style.hideMobile, style.inputSearch]}>
              <form
                onSubmit={formSubmitHandler}
                role="search"
                css={style.searchInputForm}
              >
                <div css={style.input(visibilityTimer)}>
                  {!isOamPage ? (
                    <div css={style.checkbox}>
                      <span css={style.spanText}>Search in: </span>
                      <label css={style.label}>
                        <input
                          type="radio"
                          id="searchData"
                          name="searchType"
                          value={SearchType.data}
                          checked={checksearchType === SearchType.data}
                          onChange={() => handleSearchTypeChange(SearchType.data)}
                        />
                        <span css={style.spanTextRadio}>Data</span>
                      </label>
                      <label css={style.label}>
                        <input
                          type="radio"
                          id="searchContent"
                          name="searchType"
                          value={SearchType.content}
                          checked={checksearchType === SearchType.content}
                          onChange={() => handleSearchTypeChange(SearchType.content)}
                        />
                        <span css={style.spanTextRadio}>Content</span>
                      </label>
                    </div>
                  ) : null}
                  <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <InputField
                      helperText=''
                      placeholder={isOamPage ? 'Search oam by issuer, ...' : ''}
                      textError=''
                      type='text'
                      width='100%'
                      onChange={inputChangeHandler}
                      onKeyPress={(ev: any) => {
                        if (ev.key === 'Enter' && search.length > 0) {
                          redirectToSearchEngine();
                        }
                      }}
                    />
                    <Button
                      css={{ 'align-items': 'center' }}
                      iconLeft='search'
                      label='Search'
                      onClick={redirectToSearchEngine}
                      variant='primary'
                    />
                  </div>

                  <div css={style.advanced} onClick={redirectToSearchEngine}>
                    <Icon icon={'search'} /> <span>Advanced search</span>
                  </div>
                </div>
                <div css={style.suggestion}>
                  {checksearchType === SearchType.data ?
                    (<Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={search} />
                    ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div css={style.right}>
          <div css={rightHexa} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(HomeTopComponent);
