/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Placeholder,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";
import { canUseDOM } from "../../predicates";
import { breakpointMax } from "../../style";

export interface CaseStudiesArticleProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 780px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;

  h1 {
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    color: #425563;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      color: #425563;
    }
  }

  h2 {
    color: #253845;
  }

  h3 {
    color: #425563;
  }

  p {
    margin-top: 24px;
    margin-bottom: 24px;
  }
   @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const imageContainer = (total: number) => css`
  width: ${100 / total}%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    width: ${100 / total}%;
  }
`;
const imageItem = css`
  max-width: 100%;
  width: 100%;
  border-radius: 16px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const articleText = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }
    p, h3, h4, span {
      color: #425563;
    }

    h1, h2 {
      color: #253845;
    }
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253845;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    font-size: 34px;
    line-height: 34px;
  }
`;

export const CaseStudiesArticle: React.FunctionComponent<CaseStudiesArticleProps> =
  (props: CaseStudiesArticleProps): React.ReactElement => {
    const { fields, rendering } = props;
    const [fullText, setFullText] = React.useState<string>("");
    const image: any = getFieldValue<any>(fields, "caseStudiesMainImage", null);
    React.useEffect(() => {
      const words = canUseDOM && document.getElementById("caseStudiesContainer");
      setFullText(words?.outerHTML?.replace(/(<([^>]+)>)/gi, ""));
    }, [canUseDOM]);

    return (
      <article css={container}>
        <div css={title}>
          <Text
            field={fields && (fields["caseStudiesTitle"] as SitecoreGenericTextField)}
          />
        </div>
        <div
          id="imageStart"
          css={imageContainer(1)}
          onClick={() => {
            if (canUseDOM) {
              window.open(renderLink(image.url), "_blank");
            }
          }}
        >
          <img css={imageItem} src={renderLink(image?.src)} alt={image?.alt} />
        </div>
        <div css={articleText}>
          <RichText
            field={fields && (fields["caseStudiesContent"] as SitecoreGenericTextField)}
          />
          <Placeholder name="jss-main" rendering={rendering} />
        </div>
      </article>
    );
  };