/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { getFieldValue, ComponentRendering } from "@sitecore-jss/sitecore-jss-react";
import PopupUi from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import error from "./error.svg";
import warning from "./warning.svg";
import info from "./info.svg";
import star from "./star.svg";

export interface PopupProps {
  fields: any;
  rendering: ComponentRendering;
}

export const Popup: React.FunctionComponent<PopupProps> = (
  props: PopupProps
): React.ReactElement => {
  const { fields, rendering } = props;

  const title: string = getFieldValue<string>(fields, "title", "");
  const subtitle: string = getFieldValue<string>(fields, "subtitle", "");
  const description: string = getFieldValue<string>(fields, "description", "");
  const triggerStrategy: string = getFieldValue<string>(fields, "triggerStrategy", "time");
  const triggerTime: number = getFieldValue<number>(fields, "triggerTime", 30000);
  const triggerElement: string = getFieldValue<string>(fields, "triggerElement", undefined);
  const closeOnEscape: boolean = getFieldValue<boolean>(fields, "closeOnEscape", false);
  const lockScroll: boolean = getFieldValue<boolean>(fields, "lockScroll", false);
  const closeOnDocumentClick: boolean = getFieldValue<boolean>(fields, "closeOnDocumentClick", true);
  const buttonLeftLabel: string = getFieldValue<string>(fields, "buttonLeftLabel", "");
  const buttonRightLabel: string = getFieldValue<string>(fields, "buttonRightLabel", "");
  const buttonRightLink: string = getFieldValue<string>(fields, "buttonRightLink", "");
  const isError: boolean = getFieldValue<boolean>(fields, "isError", false);
  const isWarning: boolean = getFieldValue<boolean>(fields, "isWarning", false);
  const isInfo: boolean = getFieldValue<boolean>(fields, "isInfo", false);

  const [isOpen, setIsOpen] = React.useState(false);

  const container = css`
    display: flex;
    width: 444px !important;
    padding: 24px 10px !important;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background-color: #fff;
    box-shadow: 0px 4px 46px 8px rgba(0, 0, 0, 0.04), 0px 8px 38px 3px rgba(0, 0, 0, 0.04), 0px 10px 15px -7px rgba(0, 0, 0, 0.04);
    margin: auto;
    @media (min-width: 376px ) and (max-width: 425px) {
      width: 350px !important;
    }
      @media (min-width: 321px ) and (max-width: 375px) {
      width: 330px !important;
    }
      @media (max-width: 320px) {
      width: 275px !important;
    }
  `;
  const topIcon = css`
    display: flex;
    align-self: center;
    width: 16px;
    height: 16px;
    margin-bottom: 10px;
  `;
  const mainTitle = css`
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    color: #2E3943;
    margin-bottom: 10px;
    margin: auto;
  `;
  const alertContainer = css`
    display: flex;
    width: 364px;
    height: auto;
    border-radius: 4px;
    background-color: ${isError ? "#FEEBEE" : isWarning ? "#FFECE0" : isInfo ? "#E0F9FF" : "#ffff"}; 
    padding: 12px 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    align-self: center;
    @media (min-width: 376px ) and (max-width: 425px) {
      width: 320px;
    }
       @media (min-width: 321px ) and (max-width: 375px) {
      width: 300px !important;
    }
      @media (max-width: 320px) {
      width: 240px !important;
    }
  `;
  const icon = css` 
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 5px;
  `;
  const textBox = css`
    width: 332px;
    height: auto;
    margin-left: 10px;
  `;
  const subTitle = css`
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    color: ${isError ? "#A3122D" : isWarning ? "#BA4F07" : isInfo ? "#007A99" : "#2E3943"}; 
    letter-spacing: 0.15px; 
    padding-bottom: 4px;
  `;
  const descriptionText = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    color: ${isError ? "#A3122D" : isWarning ? "#BA4F07" : isInfo ? "#007A99" : "#2E3943"}; 
    letter-spacing: 0.15px; 
  `;
  const buttons = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: auto;
    gap: 8px;
  `;

  React.useEffect(() => {
    if (triggerStrategy === "time") {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, triggerTime); // Convert seconds to milliseconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    } else if (triggerStrategy === "click") {
      const element = document.evaluate(
        triggerElement,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;

      if (element) {
        element.addEventListener("click", () => setIsOpen(true));
      }
      return () => {
        if (element) {
          element.removeEventListener("click", () => setIsOpen(true));
        }
      };
    }
  }, [triggerStrategy, triggerTime, triggerElement]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <PopupUi
      open={isOpen}
      onClose={onClose}
      position={"right center"}
      closeOnEscape={closeOnEscape}
      lockScroll={lockScroll}
      closeOnDocumentClick={closeOnDocumentClick}
      contentStyle={{ padding: 0, width: "unset", borderRadius: "4px" }}
    >
          <div css={container}>
              <div css={topIcon}>
                <img src={star} alt="icon" />
              </div>
              <div css={mainTitle}>{title}</div>
              <div css={alertContainer}>
                <div css={icon}>
                  <img src={isError ? error : isWarning ? warning : isInfo ? info : ""} alt="icon" />
                </div>
                <div css={textBox}>
                  <div css={subTitle}>{subtitle}</div>
                  <div css={descriptionText}>{description}</div>
                </div>
              </div>
              <div css={buttons}>
                {buttonLeftLabel ? (
                  <Button
                    onClick={onClose}
                    label={buttonLeftLabel}
                    variant={"thirdary"}
                    style={{ color: "black", border: "1px solid black" }}>
                  </Button>
                ) : null}
                {buttonRightLabel || buttonRightLink ? (
                  <Button
                    onClick={() => window.location.href = buttonRightLink}
                    label={buttonRightLabel}
                    variant={"primary"}
                    style={{ maxHeight: "52.39px", alignItems: "anchor-center" }}>
                  </Button>
                ) : null}
              </div>
          </div>
    </PopupUi>
  );
};
