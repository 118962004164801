import { parseGraphQLRequest } from "msw/lib/types/utils/internal/parseGraphQLRequest";
import React from "react";
import { emailParser } from "./parser/emailParser";
import { urlParser } from "./parser/urlParser";
import { textParser } from "./parser/textParser";

export interface TextFormatParser {
    type?: string;
    regex?: RegExp;
    render?: (text: string) => JSX.Element;
}

export interface TextFormatItem {
    text?: string;
    parser?: TextFormatParser
}

const parsers: TextFormatParser[] = [emailParser, urlParser];
  
const useTextParser = () => {
    
    const parseText : (textToParse: string) => TextFormatItem[] = React.useCallback( (textToParse: string) => {
        const results : TextFormatItem[] = [];

        console.log(" ---- Start parseText with '" + textToParse + "'");

        let indexStart = -1;
        let indexEnd = -1;  
        let parserItem = null;

        if(parseText?.length == 0)
            return;

        parsers.forEach(function(parser) {
            if(parser.regex){
                let parserResults = parser.regex.exec(textToParse);
                console.log("Result for '" + parser.type + "'", parser.regex, "in '" + textToParse + "'", parserResults, textToParse.match(parser.regex));
                if( parserResults && parserResults?.index > -1 && ( indexStart == -1 || parserResults.index < indexStart )){
                    var firstOccurance = parserResults[0];
                    parserItem = parser;
                    indexStart = parserResults?.index;
                    indexEnd = parserResults?.index + firstOccurance.length;
                }
            }
        });

        console.log("IndexStart: ", indexStart, "IndexEnd: ", indexEnd, "ParserType: ", parserItem);

        if(!parserItem){
            results.push({
                text: textToParse,
                parser: textParser
            })
        }else{
            if(indexStart > 0 ){
                results.push({
                    text: textToParse.substring(0, indexStart),
                    parser: textParser
                });
            }

            results.push({
                text: textToParse.substring(indexStart, indexEnd),
                parser: parserItem
            });

            if( textToParse.substring(indexEnd)?.length )
                return results.concat( parseText(textToParse.substring(indexEnd)) );
        }

        console.log("Results for (" + textToParse + "): ", results);

        return results;
    }, []);

    return { parseText };
};

export default useTextParser;