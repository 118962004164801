import gql from "graphql-tag";
import { indexSearchNoFilterFragment } from "./search/search-index.queries";

export const priceInformationFragment = gql`
  fragment priceInformationFragment on PriceInformation {
    amount
    currency
    date
    marker
    volume
    dayVolume
    changeAbsolute
    changePercent
    yieldToMaturity
  } 
`;

export const indiceSummaryFragment = gql`
  ${priceInformationFragment}
  fragment indiceSummaryFragment on IndiceInfo {
    id
    indexName
    isin
    codeType
    currency

    indexPrice {
      lastPrice {
        ...priceInformationFragment
      }
      lastClosingPrice {
        ...priceInformationFragment
      }
      monthPriceMax {
        ...priceInformationFragment
      }
      monthPriceMin {
        ...priceInformationFragment
      }
      yearPriceMax {
        ...priceInformationFragment
      }
      yearPriceMin {
        ...priceInformationFragment
      }
      yearToDateAbsolute
      yearToDatePercent
    }
  } 
`;

export const indiceNoBasketFragment = gql`
  ${priceInformationFragment}
  fragment indiceNoBasketFragment on IndiceInfo {
    id
    indexName
    isin
    codeType
    currency

    indexPrice {
      lastPrice {
        ...priceInformationFragment
      }
      lastClosingPrice {
        ...priceInformationFragment
      }
      monthPriceMax {
        ...priceInformationFragment
      }
      monthPriceMin {
        ...priceInformationFragment
      }
      yearPriceMax {
        ...priceInformationFragment
      }
      yearPriceMin {
        ...priceInformationFragment
      }
      yearToDateAbsolute
      yearToDatePercent

      pricesHistory{
        ...priceInformationFragment
      }
    }
  } 
`;

export const indiceFullFragment = gql`
  ${priceInformationFragment}
  fragment indiceFullFragment on IndiceInfo {
    id
    indexName
    isin
    codeType
    currency

    basket {
      id
      isinCode
      securityName
      weight
      numberOfShares
      security{
        id
        instrumentName
        isin
        status
        delistingDate
        currency
        instrumentType
        instrumentSubtype
        listingDate
        maturityDate
        tags
        issuedNominalAmount
        interestRate
        luxxprimeDate
        marketRule
        marketSegment
        marketData {
          previousDayChangeAbsolute
          previousDayChangePercent
          previousClosingPrice {
            ...priceInformationFragment
          }
          lastPrice {
            ...priceInformationFragment
          }
        }
      }
    }

    indexPrice {
      lastPrice {
        ...priceInformationFragment
      }
      lastClosingPrice {
        ...priceInformationFragment
      }
      monthPriceMax {
        ...priceInformationFragment
      }
      monthPriceMin {
        ...priceInformationFragment
      }
      yearPriceMax {
        ...priceInformationFragment
      }
      yearPriceMin {
        ...priceInformationFragment
      }
      yearToDateAbsolute
      yearToDatePercent

      pricesHistory{
        ...priceInformationFragment
      }
    }
  } 
`;

export const GET_INDICEMARKET_QUERY = gql`
  ${priceInformationFragment}
  query indiceMarketData($id: String!) {
    indexInformation(id: $id) {
      id
      indexName
      codeType
      currency
      basket {
        id
        isinCode
        securityName
        weight
        numberOfShares
      }
      securities {
        instrumentName
        isin
        id
        issuers {
          name
          id
        }
        marketData {
          lastPrice {
            amount
            changePercent
          }
        }
      }

      indexPrice {
        pricesHistory {
          date
          currency
          amount
          changePercent
          changeAbsolute
          marker
          yieldToMaturity
        }
        lastPrice {
          ...priceInformationFragment
        }
        lastClosingPrice {
          ...priceInformationFragment
        }
        monthPriceMax {
          ...priceInformationFragment
        }
        monthPriceMin {
          ...priceInformationFragment
        }
        yearPriceMax {
          ...priceInformationFragment
        }
        yearPriceMin {
          ...priceInformationFragment
        }
        yearToDateAbsolute
        yearToDatePercent
      }
    }
  }
`;

export const GET_INDICE_QUERY =  gql`
  ${indiceFullFragment}
  query IndexInformation($id: String!) {
    indexInformation(id: $id) {
      ... indiceFullFragment
    }
  } 
`;