
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from "../../style";
import { renderLink } from "../../utils/helper";

export interface CaseStudiesProductProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const CaseStudiesProduct: React.FunctionComponent<CaseStudiesProductProps> = (props: CaseStudiesProductProps): React.ReactElement => {
    const { fields } = props;

    const productLink: string = getFieldValue<string>(fields, 'link', '');
    const logo: any = getFieldValue<any>(fields, "logo", null);

    const productBox = css`
        width: 136px;
        height: 127px;
        display: flex;
        padding: 24px 48px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        border: 1px solid #BCC4CC;
        background: #FFFFFF;
        cursor: pointer;
        box-shadow: 0px 6px 11px 11px rgba(0, 0, 0, 0.10);
            @media (max-width: ${breakpointMax}) {
                width: 124px;
                height: 120px;
                padding: 16px 34px;
                box-shadow: none;
            }
    `;
    const productLogo = css`
        width: 40px;
        height: 78px;
        @media (max-width: ${breakpointMax}) {
                width: 100%;
        }
    `;
    
    return (
        productLink !== null || logo ? (
            <div css={productBox} className="product-box" onClick={() => window.location.href = productLink}>
                <div css={productLogo}>
                    <img src={renderLink(logo.src)} />
                </div>
            </div>
        ) : null
    );
}
    