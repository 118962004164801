/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import bg from "./bg.svg";
import { useLocation } from "react-router-dom";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../style";
import { fetchMediaUrl, getAuthorsBySitecoreAPI } from "../../utils/blog";
import Loader from "../../core/components/Loader/loader.component";

export interface BlogAuthorComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
export const BlogTopContainer = css`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: url(${bg});
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 40px;
  padding-top: 76px;
  @media (max-width: ${breakpointMax}) {
    background: none;
    padding-top: 32px;
  }
`;
const container = (border = false) => css`
  display: flex;
  flex-direction: ${border ? "row" : "column"};
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: ${border ? "1px solid #D9DEE2" : "none"};
  padding-bottom: 40px;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const left = css`
  width: 180px;

  img {
    max-width: 100%;
  }

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const right = css`
  width: calc(100% - 180px);
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 0;
  }
`;
const nameStyle = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-size: 36px;
  }
`;
const role = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-size: 18px;
    font-weight: 500;
  }
`;
const action = css`
  margin-top: 32px;
`;
const content = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #425563;

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #253845;
    @media (max-width: ${breakpointMax}) {
      font-size: 28px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    color: #425563;
    @media (max-width: ${breakpointMax}) {
      font-size: 24px;
    }
  }

  @media (max-width: ${breakpointMax}) {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;

    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 150%;
      color: #253845;
      @media (max-width: ${breakpointMax}) {
        font-size: 28px;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 150%;
      color: #425563;
      @media (max-width: ${breakpointMax}) {
        font-size: 24px;
      }
    }
  }
`;

export const BlogAuthorComponent: React.FunctionComponent<BlogAuthorComponentProps> =
  (): React.ReactElement => {
    const location = useLocation();
    const name = location.pathname
      .split("/")
      .pop()
      .split("-")
      .filter((elem: string) => elem !== "");
    const [author, setAuthor] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);

    // this function is made to get the id in the Picture field of every author via the sitecore api
    const extractMediaId = (xmlString: string) => {
      if (!xmlString) return "";

      // Extract GUID
      const regex = /mediaid="{([^}]+)}"/;
      const match = regex.exec(xmlString);

      if (match && match[1]) {
        return match[1];
      }
      return "";
    };

    React.useEffect(() => {
      if (author?.Picture) {
        const mediaId = extractMediaId(author.Picture); 
        if (mediaId) {
          fetchMediaUrl(mediaId).then((url) => {
            setImageUrl(url);
          });
        }
      }
    }, [author?.Picture]);
    
    React.useEffect(() => {
      getAuthorsBySitecoreAPI("{787B59E5-1A99-4D1A-A8C2-CFA7A95031DA}")
        .then((res: any) => {
          let target;

          if (name.length === 1) {
            //just a lastName 
            target = res.filter(
              (author: any) =>
                author?.["Last name"]?.replace(/ /g, "") === name[0]
            )?.[0];
          } else {
            //lastName and firstName
            target = res.filter(
              (author: any) =>
                author?.["First name"]?.replace(/ /g, "") === name[0] &&
                author?.["Last name"]?.replace(/ /g, "") === name[1]
            )?.[0];
          }
          if (target) {
            setAuthor(target);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching authors:", error);
          setLoading(false);
        });
    }, []);

    const getLinkAttributes = (link: string) => {
      const regex = /<link\s+(.*?)\s*\/?>/i;
      const match = regex.exec(link);
      const attributes: any = {};
      if (match && match[1]) {
        const attributePairs = match[1].split(/\s+/);
        attributePairs.forEach((pair) => {
          const [key, value] = pair.split("=");
          if (key && value) {
            attributes[key] = value.replace(/^['"]|['"]$/g, ""); // remove quotes if any
          }
        });
      }
      return {
        value: { href: attributes?.href || attributes.url, ...attributes },
      };
    };

    return (
      <div css={parentContainer}>
        <Loader loading={loading}>
          <div css={BlogTopContainer}>
            <div css={container(true)}>
              <div css={left}>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={`${name?.[0] || ""} ${name?.[1] || ""}`}
                  />
                ) : (
                  ""
                )}
              </div>
              <div css={right}>
                <div css={nameStyle}>
                  {name?.[0]
                    ? `${name[0]}${name?.[1] ? ` ${name[1]}` : ""}`
                    : ""}
                </div>
                <div css={role}>{author?.Role}</div>
              </div>
            </div>
          </div>
          <div css={container(false)}>
            <div
              css={content}
              dangerouslySetInnerHTML={{ __html: author?.Description }}
            />
            <div css={action}>
              {author?.buttonLabel && (
                <Link
                  target={
                    getLinkAttributes(author?.buttonLink).value?.linktype ===
                    "external"
                      ? "_blank"
                      : "_self"
                  }
                  title={author?.["button label"]}
                  field={getLinkAttributes(author?.buttonLink)}
                  css={{ textDecoration: "none" }}
                >
                  <Button
                    label={author?.["button label"]}
                    iconRight={author?.["button icon"] as any}
                  />
                </Link>
              )}
            </div>
          </div>
        </Loader>
      </div>
    );
  };
