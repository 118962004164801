let indexCount = 0;

const setBDLMTF= (
    prospectus: string,
    lgx: boolean,
    hideButton?: boolean,
    applicationLink?: string,
    letterLink?: string,
    empty?: boolean,
    extraSentence?: string,
    mandatoryText?: string) => {
    indexCount++;
    // https://bdl-port-luxse-ssr-dev-weu-main-bdl-web-dev.apps.dev.luxse.cloud/-/media/bdl-port-luxse-ssr/Data/Media/Files/lgx/LGX_ACADEMY_FORM_Registration.pdf
    // /sitecore/media library/bdl-port-luxse-ssr/Data/Media/Files/forms/form-listing-application-bonds-warrants
    // https://bdl-port-luxse-ssr-dev-weu-main-bdl-web-dev.apps.dev.luxse.cloud/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/form-listing-application-bonds-warrants
    return (
        {
            prospectus: prospectus !== '' ? prospectus : 'To learn more about whether this listing option is possible at LuxSE, please reach out to our expert listing team via <a href="mailto:bolide@luxse.com">bolide@luxse.com</a>',
            prospectusLink: 'https://www.cssf.lu/en/Document/e-prospectus/',
            mandatoryText: mandatoryText || 'To begin the listing process, please fill in the below documents carefully before sending the completed pack to',
            mandatoryLink: 'mailto:bolide@luxse.com?subject=Listing%20request%20on%20LuxSE',
            mandatoryDocuments: [
                {
                    title: 'Application form',
                    link: applicationLink || '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Bonds_Warrants.pdf',
                    buttonText: '',
                    imageField: 'image application form',
                    type: 'applicationForm'
                },
                {
                    title: 'Letter of Undertaking',
                    imageField: 'image letter of undertaking',
                    link: letterLink || '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Regulated_Market_Bonds_Warrants.pdf',
                    buttonText: '',
                    type: 'undertaking',
                }
            ],
            rulesDocument: '',
            rulesTextTitle: 'Rules & Regulations of the Luxembourg Stock Exchange',
            rulesImage: 'image rules and regulations',
            rulesText: '',
            rulesButtonText: 'Download Rules & Regulations',
            documentLink: '/-/media/bdl-port-luxse-ssr/Data/Media/Files/regulation/luxse-rules-and-regulations',
            lgx,
            index: indexCount,
            hideButton,
            empty,
            extraSentence
        }
    )
}
const setSOL = (
    prospectus: string,
    alternateText?: string,
    secondLetterText?: string,
    notice?: string,
    hideButton?: boolean,
    applicationLink?: string,
    letterLink?: string,
    empty?: boolean,
    extraSentence?: string,
    newCardTitle?: string,
    newCardDescription?: string,
    newCardLink?: string,
    mandatoryText?: string,
) => {
    indexCount++;
    const mandatoryDocuments = [];
    mandatoryDocuments.push(
        {
            title: 'Application form',
            link: '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_SOL_APPLICATION_FORM_Admission_to_SOL.pdf',
            buttonText: '',
            imageField: 'image application form',
            type: 'notice'
        },
        {
            title: 'Letter of Undertaking',
            link: '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_SOL_LETTER_OF_UNDERTAKING_Issuer_Securities_Admission_to_SOL.pdf',
            buttonText: '',
            imageField: 'image letter of undertaking',
            type: 'undertaking'
        }
    );
    if (notice) {
        mandatoryDocuments.push(
            {
                title: 'Information notice',
                link: '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_SOL_APPLICATION_FORM_Admission_to_SOL.pdf',
                buttonText: 'Download information notice',
                type: 'undertaking',
                imageField: 'image sol rules book',
                coverText : notice || 'Please consult section 5.2.11 and in Schedule 1 on LuxSE’s SOL Rulebook for more information. '
            }
        )
    }

    return (
        {
            prospectus: prospectus === "empty" ? null : (prospectus !== '' ? prospectus : 'To learn more about whether this security can be registered on our Securities Official List, please reach out to our expert listing team via <a href="mailto:bolide@luxse.com">bolide@luxse.com</a>'),
            prospectusLink: 'https://www.cssf.lu/en/Document/e-prospectus/',
            mandatoryText: mandatoryText || 'To begin the listing process, please fill in the below documents carefully before sending the completed pack to',
            mandatoryLink: 'mailto:bolide@luxse.com?subject=Listing%20request%20on%20LuxSE',
            mandatoryDocuments,
            rulesDocument: '',
            secondLetterText,
            notice,
            rulesTextTitle: alternateText || "LuxSE's SOL Rulebook",
            rulesImage: 'image sol rules book',
            rulesTextContent: 'rulesText',
            rulesButtonText: "Download LuxSE's SOL Rulebook",
            documentLink: '/-/media/bdl-port-luxse-ssr/Data/Media/Files/regulation/luxse-sol-rulebook.pdf',
            lgx: false,
            index: indexCount,
            hideButton,
            empty,
            extraSentence,
            newCardTitle,
            newCardDescription,
            newCardLink,
            imageField: 'image guideline security tokens',
            isSol: true
        }
    )
}
//Debt and derivative securities that are exchangeable for or convertible into shares
const lastColumn = ['a debt security','an ABS', 'a certificate', 'a convertible security','a derivative or SIP','a warrant','a money market instrument','a share or GDR', 'security tokens'];
const scenariosBdl : any = [];
const scenariosSol : any = [];
lastColumn.forEach((col: string, index: number) => {
    if (col !== 'security tokens') {
        if (index === 6) {
            scenariosBdl.push(
                setBDLMTF(`Before listing a money market instrument on our regulated market, Bourse de Luxembourg, your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus.<br /><br />Since money market instruments are excluded under Article 2. (a) of Regulation (EU) 2017/1129, issuers fall under the provisions of Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities. `, true, true)
            )
            scenariosSol.push(
                setSOL(`Before registering ${col} on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  `)
            )
        } else if (index === 9) {
            scenariosBdl.push(
                setBDLMTF(`Before listing ${col} shares/units on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA). The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and any prospectus to be approved by the CSSF must be filed via its e-Prospectus platform.`, true, true)
            )
            scenariosSol.push(
                setSOL(`Before registering ${col} on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  `)
            )
        } else {
            if (index === 7) {
                scenariosBdl.push(
                    setBDLMTF(
                        `Before listing ${col} on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA). The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and any prospectus to be approved by the CSSF must be filed via its e-Prospectus platform.`,
                        true,
                        false,
                        '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Share_or_Depositary_Receipts.pdf', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Regulated_Market_Shares_Depositary_Receipts.pdf')
                )
                scenariosSol.push(
                    setSOL(`Before registering ${col} on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  `)
                )
            } else {
                    scenariosBdl.push(
                        setBDLMTF(`Before listing ${col} on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA). <br />
            The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and any prospectus to be approved by the CSSF must be filed via its e-Prospectus platform. `, true)
                    )
                    scenariosSol.push(
                        setSOL(`Before registering ${col} on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  `)
                    )
            }
        }

    } else {
        scenariosBdl.push(
            setBDLMTF(``, true, true, '', '', true)
        )
        scenariosSol.push(
            setSOL(``)
        )
    }
});

export const rules: any[] = [
    ...scenariosBdl, // 0- 9
    {
        /* 10 */ ...setBDLMTF('Before listing a debt security on our regulated market, Bourse de Luxembourg, your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus. <br />' +
            '\r' +
            '\r' +
            '<br /><br />Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the alleviated prospectus approval process. For a full list of exemptions, please refer to Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities.', false, true,'', '', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 11 */ ...setBDLMTF('Before listing an ABS on our regulated market, Bourse de Luxembourg, your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus. <br />' +
            '<br /><br />Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the alleviated prospectus approval process. For a full list of exemptions, please refer to Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities.', false, true,'', '', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 12 */ ...setBDLMTF('Before listing a certificate on our regulated market, Bourse de Luxembourg, your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus.<br />' +
            '<br /><br />Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the alleviated prospectus approval process. For a full list of exemptions please refer to Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities. ', false, true, '', '', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 13  */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 14 */ ...setBDLMTF('Before listing a derivative/SIP on our regulated market, Bourse de Luxembourg, your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus. <br />' +
            '<br /><br />Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the alleviated prospectus approval process. For a full list of exemptions, please refer to Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities.', false, true, '', '', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions')
    },
    {
        /* 15 */ ...setBDLMTF('', false, true,'', '', true, '')
    },
    {
        /* 16 */ ...setBDLMTF('Since money market instruments are excluded under Article 2. (a) of Regulation (EU) 2017/1129, issuers fall under the provisions of Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities.  <br />' +
            '<br />' +
            'Your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus. <br />' +
            '<br /><br />Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the alleviated prospectus approval process (for a full list of exemptions please refer to Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities. ', false, true, '', '', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions')
    },
    {
        /* 17 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 18 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 19 */ ...setBDLMTF('Before listing a debt security on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br />' +
            '<br />' +
            'The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform.', false)
    },
    {
        /* 20 */ ...setBDLMTF('Before listing an ABS on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br />' +
            '<br />' +
            'The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform.', false)
    },
    {
        /* 21 */ ...setBDLMTF('Before listing a certificate on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br />' +
            '<br />' +
            'The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform.', false)
    },
    {
        /* 22 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 23 */ ...setBDLMTF('Before listing derivates or SIP on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br />' +
            '<br />' +
            'The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform. ', false)
    },
    {
        /* 24 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 25 */ ...setBDLMTF('Since money market instruments are excluded under Article 2. (a) of Regulation (EU) 2017/1129, issuers fall under the provisions of Part III, Chapter 2, Article 41. of the Law of 16 July 2019 on prospectuses for securities.<br />' +
            '<br />' +
            'Your alleviated prospectus will first need to be approved by LuxSE in accordance with R&R, Part 2, Appendix XIV: Short-form prospectus.', false, true, '', '', false, 'Please consult Part 2, Appendix XIV our of Rules and Regulations to find out more about short-form prospectuses.')
    },
    {
        /* 26 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')

    },
    {
        /* 27 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')

    },
    {
        /* 28 */ ...setBDLMTF('Before listing a debt security on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br /><br  />The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform.', false)

    },
    {
        /* 29 */ ...setBDLMTF('Before listing an ABS on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved by a national competent authority (NCA).<br />' +
            '<br />' +
            'The NCA for Luxembourg is the Commission de Surveillance du Secteur Financier (CSSF), and all Prospectus Regulation compliant prospectuses to be approved by the CSSF must be filed via its e-Prospectus platform.', false)
    },
    {
        /* 30 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 31 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 32 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 33 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 34 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 35 */ ...setBDLMTF('Before listing a Share/Global depositary receipt (GDR) on our regulated market, Bourse de Luxembourg, your prospectus will first need to be approved/authorised by a national competent authority (NCA). The prospectus which open-ended UCIs draw up in accordance with the Law of 17 December 2010 relating to UCIs are exempted from approval under the Law of 16 July 2019 on prospectuses for securities and are valid for the purposes of the listing. \n' +
            '<br /><br />' +
            'To learn more about listing options for unregulated AIFs at LuxSE, please reach out to our expert listing team via <a href="mailto:bolide@luxse.com">bolide@luxse.com</a> ', false, true, '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Investment_Funds.pdf', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Regulated_Market_Shares_Depositary_Receipts.pdf')
    },
    {
        /* 36 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 37 */ ...setBDLMTF('Before listing a debt security on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure schemes provided by Appendices I and II of the Rules and Regulations of LuxSE (R&R) in combination with the additional building block specific to guarantees (Appendix III) if applicable. \n' +
            '<br /><br />' +
            'Issuers that have shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R. For further information, please refer to the guidelines under Part 2 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 38 */ ...setBDLMTF('Before listing an ABS on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of asset-backed securities might use the disclosure schemes provided by Appendices V and VI in combination with the ‘Debt securities - Securities building block’ in Appendix II of the R&R. For further information, please refer to the guidelines under Part 2 of the R&R. \n' +
            '<br /><br />' +
            'Issuers having shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 39 */ ...setBDLMTF('Before listing a certificate on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of certificates might use the disclosure schemes provided by Appendix VII for derivative securities which do not give the right to acquire shares in respect of the derivative securities and their underlying. Appendix VII shall be combined with the ‘Debt securities – Issuer building block’ in appendix I. of the R&R. For further information, please refer to the guidelines under Part 2 of the R&R. \n' +
            '<br /><br />' +
            'Issuers having shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane)  for prospectus approvals under Part 2, Chapter 4 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 40 */ ...setBDLMTF('Issuers having shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the Rules and Regulations of LuxSE (R&R).  \n' +
            '<br /><br />' +
            'Under the standard regime, debt or derivative securities that are exchangeable for or convertible into shares on the exchange-regulated Euro MTF, require a prospectus approved by LuxSE. Appendix VIII of the R&R displays a table of combinations for several types of issuances under the heading ‘Debt and derivative securities that are exchangeable for or convertible into shares.', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 41 */ ...setBDLMTF('Before listing a derivative/SIP on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of derivatives or SIPs might use the disclosure schemes provided by Appendices I and VII of the Rules and Regulations of LuxSE (R&R). For further information, please refer to the guidelines under Part 2 of the R&R. \n' +
            '<br /><br />' +
            'Issuers that have shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R.', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 42 */ ...setBDLMTF('On the Euro MTF, warrants that are exercisable into shares require a prospectus approved by LuxSE. Appendix VIII of the Rules & Regulations of the Luxembourg Stock Exchange (R&R) displays a table of combinations for several types of issuances under the heading ‘Debt and derivative securities that are exchangeable for or convertible into shares. For further information, please refer to the guidelines under Part 2 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 43 */ ...setBDLMTF('Before listing a money market instrument on the exchange-regulated Euro MTF, your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules and Regulations of LuxSE (R&R), Part 2, Appendix XIV: Short-form prospectus. Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions. \n' +
            '<br /><br />' +
            'Issuers having shares listed on an EU regulated market or equivalent may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 44 */ ...setBDLMTF('Before listing a share or GDR on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of shares or GDRs might use the disclosure schemes provided by Appendices IX and X (shares) or XI (GDRs) of the Rules and Regulations of LuxSE (R&R). For further information, please refer to the guidelines under Part 2 of the R&R.', false, true, '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Share_or_Depositary_Receipts.pdf', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Shares_Depositary_Receipts.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations. ')
    },
    {
        /* 45 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 46 */ ...setBDLMTF('Before listing a debt security on the exchange-regulated market (Euro MTF), your short-form prospectus will first need to be approved by LuxSE  in accordance with the Rules & Regulations of the Luxembourg Stock Exchange (R&R), Part 2, Appendix XIV: Short-form prospectus. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the Short-form prospectus approval process. <br />' +
            'For a full list of exemptions please refer to Part II, Chapter 2, Article 203. as well as to Chapter 4 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 47 */ ...setBDLMTF('Before listing an ABS on the exchange-regulated market (Euro MTF), your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules & Regulations of the Luxembourg Stock Exchange (R&R), Part 2, Appendix XIV: Short-form prospectus. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the Short-form prospectus approval process. <br />' +
            'For a full list of exemptions please refer to Part II, Chapter 2, Article 203. as well as to Chapter 4 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 48 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 49 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 50 */ ...setBDLMTF('Before listing a derivative/SIP on the exchange-regulated market (Euro MTF), your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules and Regulations of LuxSE (R&R), Part 2, Appendix XIV: Short-form prospectus. \n' +
            '<br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the Short-form prospectus approval process. \n' +
            '<br /><br />' +
            'For a full list of exemptions please refer to Part II, Chapter 2, Article 203. as well as to Chapter 4 of the R&R. ', false, true,'', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 51 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 52 */ ...setBDLMTF('Before listing a money market instrument on the exchange-regulated Euro MTF, your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules & Regulations of the Luxembourg Stock Exchange (R&R), Part 2, Appendix XIV: Short-form prospectus. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the Short-form prospectus approval process. <br />' +
            'For a full list of exemptions please refer to Part II, Chapter 2, Article 203. as well as to Chapter 4 of the R&R.  ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 53 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 54 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 55 */ ...setBDLMTF('OECD member states: Before listing a debt security on the exchange-regulated Euro MTF, your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules and Regulations of LuxSE (R&R), Part 2, Appendix XIV: Short-form prospectus. Please consult Part 2, Rule 203 of our Rules and Regulations to find out more about exemptions. \n' +
            '<br /><br />' +
            'Third country sovereign issuers: Before listing a debt security on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE  In general, issuers of debt securities might use the disclosure schemes provided by Appendices II and IV of the Rules and Regulations of LuxSE (R&R). For further information, please refer to the guidelines under Part 2 of the R&R. \n' +
            '<br /><br />' +
            'Sovereign issuers (Non-EU Member State) may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R.', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 56 */ ...setBDLMTF('', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', true, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 57 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 58 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 59 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 60 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 61 */ ...setBDLMTF('Before listing a money market instrument on the exchange-regulated Euro MTF, your short-form prospectus will first need to be approved by LuxSE in accordance with the Rules and Regulations (R&R), Part 2, Appendix XIV: Short-form prospectus.  \n' +
            '<br /><br />' +
            'Sovereign issuers (Non-EU Member State) may choose to use the exemption regime (FastLane) for prospectus approvals under Part 2, Chapter 4 of the R&R. Please consult Part 2, Guidelines of our Rules and Regulations. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false,
            'Please consult Part 2, Rule 203 of our Rules and Regulations to find out more about exemptions.')
    },
    {
        /* 62 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 63 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 64 */ ...setBDLMTF('Before listing a debt security on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure schemes provided by Appendices I and II of the Rules and Regulations (R&R) in combination with the additional building block specific to guarantees (Appendix III) if applicable. Please consult Part 2, Guidelines of our Rules and Regulations for more information.', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 65 */ ...setBDLMTF('Before listing an ABS on the exchange-regulated Euro MTF, your prospectus will first need to be approved by LuxSE. In general, issuers of asset-backed securities might use the disclosure schemes provided by Appendices V and VI in combination with the ‘Debt securities - Securities building block’ in Appendix II of the Rules & Regulations of the Luxembourg Stock Exchange (R&R). For further information, please refer to the guidelines under Part 2 of the R&R. ', false, true, '', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Bonds_Warrants.pdf', false, 'Please consult Part 2, Guidelines of our Rules and Regulations.')
    },
    {
        /* 66 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 67 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 68 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 69 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 70 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    {
        /* 71 */ ...setBDLMTF('Before listing a share or GDR on our exchange-regulated market, Euro MTF, your prospectus will first need to be approved/authorised by a national competent authority (NCA). The prospectus which open-ended UCIs draw up in accordance with the Law of 17 December 2010 relating to UCIs are exempted from approval under the Law of 16 July 2019 on prospectuses for securities and are valid for the purposes of the listing. \n' +
            '<br /><br />' +
            'To learn more about listing options for unregulated AIFs at LuxSE, please reach out to our expert listing team via <a href="mailto:bolide@luxse.com">bolide@luxse.com</a>. ', false, true,'/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Investment_Funds.pdf', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_LETTER_OF_UNDERTAKING_Euro_MTF_Shares_Depositary_Receipts.pdf', false, '')
    },
    {
        /* 72 */ ...setBDLMTF('', false, true,'', '', true, 'Please consult Part 2, Rule 203 of our Rules & Regulations to find out more about exemptions.')
    },
    //...scenariosSol, // 73 - 81
    {
        /* 73 */ ...setSOL('Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.', '','', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to')
    },
    {
        /* 74 */ ...setSOL('Before registering an ABS on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.', '','', 'Before registering an ABS on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 75 */ ...setSOL('Before registering a certificate on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.', '','', 'Before registering a certificate on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 76 */ ...setSOL('Before registering a convertible security on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  ', '','', 'Before registering a convertible security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of convertibles shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 77 */ ...setSOL('Before registering a derivative or SIP on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  ', '','', 'Before registering a derivative/structured investment product on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of derivatives/structured investment products might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 78 */ ...setSOL('Before registering a warrant on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  ', '','', 'Before registering a warrant on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of warrants might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 79 */ ...setSOL('Before registering money market instrument on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.  ', '','', 'Before registering a money market instrument on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of money market instruments shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 80 */ ...setSOL('Before registering a share or GDR on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of shares/GDRs shall refer to LuxSE’s SOL Rulebook.', '','', 'Before registering a share or GDR on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of shares/GDRs shall refer to LuxSE’s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 81 */ ...setSOL('Before registering a security token on our Securities Official List (LuxSE SOL) without admission to trading, your prospectus will first need to be approved by LuxSE.', '', '', 'Before registering a security token on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Please verify that the token represents an eligible debt instrument under the guidelines for the registration of security tokens onto the SOL. Issuers of eligible security tokens might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook in combination with the additional disclosure requirements in the aforementioned guidelines.', true, '', '', false, '', 'Guidelines for the registration of DLT Financial Instruments onto the Securities Official List (SOL) ', 'Please consult our guidelines on how to register a DLT financial instrument to find out more about registering a security token on LuxSE SOL.', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/listing/LuxSE_Guidelines_for_registration_of_DLT_FI_on_SOL.pdf', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to')
    },
    {
        /* 82 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers might use the disclosure scheme provided by LuxSE’s SOL Rulebook. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 83 */ ...setSOL('', '', '', 'Before registering an asset-backed security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers might use the disclosure scheme provided by LuxSE’s SOL Rulebook. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 84 */ ...setSOL('', '', '', 'Before registering a certificate on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of certificates shall refer to LuxSE’s SOL Rulebook. <br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 85 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 86 */ ...setSOL('', '', '', 'Before registering derivatives/structured investment products on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers might use the disclosure scheme provided by LuxSE’s SOL Rulebook.<br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 87 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 88 */ ...setSOL('', '', '', 'Before registering money market instruments on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers shall refer to LuxSE’s SOL Rulebook. <br /> <br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 89 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 90 */ ...setSOL('', '', '', 'Before registering a security token on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Please verify that the token represents an eligible debt instrument under the guidelines for the registration of security tokens onto LuxSE SOL. Issuers of eligible security tokens might use the disclosure scheme provided by LuxSE SOL Rulebook in combination with the additional disclosure requirements in the aforementioned guidelines.<br /><br />' +
            'Please note that if you are an EU Member State, a regional or local authority or an EU-recognised public international body (PIB), you are fully exempt from the provision of an information notice requiring approval. The document that has been prepared to describe the securities should ideally be complemented by disclosure requirements of the guidelines for the registration of security tokens onto LuxSE SOL. ', true, '', '', false, '', 'Guidelines for the registration of DLT Financial Instruments onto the Securities Official List (SOL) ', 'Please consult our guidelines on how to register a DLT financial instrument to find out more about registering a security token on LuxSE SOL.', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/listing/LuxSE_Guidelines_for_registration_of_DLT_FI_on_SOL.pdf', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to')
    },
    {
        /* 91 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of debt securities shall refer to the LuxSE\'s SOL Rulebook. ', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 92 */ ...setSOL('', '', '', 'Before registering an ABS on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of money market instruments shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 93 */ ...setSOL('', '', '', 'Before registering a certificate on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of certificates shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 94 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 95 */ ...setSOL('', '', '', 'Before registering a derivative/SIP on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of derivative/SIP instruments shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 96 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 97 */ ...setSOL('', '', '', 'Before registering a money market instrument on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Issuers of money market instruments shall refer to the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 98 */ ...setSOL('', '', '', '', true,'', '', true)
    },
    {
        /* 99 */ ...setSOL('', '', '', 'Before registering a security token on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Please verify that the token represents an eligible debt instrument under the guidelines for the registration of security tokens onto LuxSE SOL. Issuers of eligible security tokens might use the disclosure scheme provided by LuxSE’s SOL Rulebook in combination with the additional disclosure requirements in the aforementioned guidelines.', true, '', '', false, '', 'Guidelines for the registration of DLT Financial Instruments onto the Securities Official List (SOL) ', 'Please consult our guidelines on how to register a DLT financial instrument to find out more about registering a security token on LuxSE SOL.', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/listing/LuxSE_Guidelines_for_registration_of_DLT_FI_on_SOL.pdf', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to')
    },
    {
        /* 100 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 101 */ ...setSOL('', '', '', 'Before registering an ABS on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true, '', '', false, '', '','','', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to' )
    },
    {
        /* 102 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true,'', '', true)
    },
    {
        /* 103 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true,'', '', true)
    },
    {
        /* 104 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true,'', '', true)
    },
    {
        /* 105 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true,'', '', true)
    },
    {
        /* 106 */ ...setSOL('', '', '', 'Before registering a debt security on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. In general, issuers of debt securities might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook.', true,'', '', true)
    },
    {
        /* 107 */ ...setSOL('', '', '', 'The prospectus which open-ended UCIs draw up in accordance with the Law of 17 December 2010 relating to UCIs is valid for the purposes of the registration on our Securities Official List (LuxSE SOL) without admission to trading. To learn more about registration options for unregulated AIFs at LuxSE, please reach out to our expert listing team via <a href="mailto:bolide@luxse.com">bolide@luxse.com</a>.', true,'/-/media/bdl-port-luxse-ssr/Data/Media/Files/forms/LuxSE_APPLICATION_FORM_Investment_Funds.pdf', '', false)
    },
    {
        /* 108 */ ...setSOL('', '', '', 'Before registering a security token on our Securities Official List (LuxSE SOL) without admission to trading, your information notice will first need to be approved by LuxSE. Please verify that the token represents an eligible debt instrument under the guidelines for the registration of security tokens onto the SOL. Issuers of eligible security tokens might use the disclosure scheme provided by the LuxSE\'s SOL Rulebook in combination with the additional disclosure requirements in the aforementioned guidelines.', true, '', '', false, '', 'Guidelines for the registration of DLT Financial Instruments onto the Securities Official List (SOL) ', 'Please consult our guidelines on how to register a DLT financial instrument to find out more about registering a security token on LuxSE SOL.', '/-/media/bdl-port-luxse-ssr/Data/Media/Files/listing/LuxSE_Guidelines_for_registration_of_DLT_FI_on_SOL.pdf', 'To begin the registration process, please fill in the below documents carefully before sending the completed pack to')
    },
]
