export enum MarketSegmentLabelEnum {
	SSS = "Specialist Securities Segment (EM3S)",
}

export function marketSegmentLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(MarketSegmentLabelEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketSegmentLabelEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketSegmentLabelEnum label for '" + value + "'", e);
	}
	
	return value;
}

export enum MarketSegmentNameEnum {
	SSS = "EM3S",
}

export function isMarketSegment(value: string): boolean {
	if( value == null || value === "" || ! value.length )
        return false;

	try {
		let index = Object.keys(MarketSegmentNameEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? true : false;
	}catch (e){
		console.error("Failed to get MarketSegmentNameEnum label for '" + value + "'", e);
	}

	return false;
}

export function marketSegmentName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(MarketSegmentNameEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketSegmentNameEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketSegmentNameEnum label for '" + value + "'", e);
	}

	return value;
}