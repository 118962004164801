import * as React from "react";

import { Document, Security } from "../../../../interfaces";
import {
  ProgrammeDetail,
  setPeriodicityCodeLabelDataValue,
} from "../../../../graphql";
import { css, jsx } from "@emotion/react";
import {
  instrumentSubTypeLabel,
  marketRuleCheck,
  MarketRuleEnum,
} from "../../../../enums";
import tmpValuesArray from "../../../../mocks/tmpValuesArray";
import moment from "moment-business-days";

import {
  IssuerSummaryCard,
  IssuerSummaryCards,
} from "../../IssuerCard/issuer-summary";
import Loader from "../../../../core/components/Loader/loader.component";
import { NoticeSummaryCard } from "../notice-summary";
import { ProgrammeSummaryCard } from "../../ProgrammeCard/programme-summary";
import { SecurityCardTab } from "..";
import { formatDate } from "../../../../utils/date";
import { formatToThousandsSeparator } from "../../../../utils";
import { style } from "./securityOverview.style";
import { securityDelistingReasonLabel } from "../../../../enums/security-delistingReason";
import SearchIssuer from "../../../../interfaces/search/search-issuer";
import Tag from "../../Tag/tag.component";
import axios from "axios";
import { BACKBOURSE_ENDPOINT, ECB_COLLATERAL } from "../../../../backbourse";
import { canUseDOM } from "../../../../predicates";
import RoundTag from "../../RoundTag/roundTag.component";
import { computeCategoryLabel } from "../../UI/list/security/row";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { useCallback } from "react";
import TextFormat from "../../TextFormat/textFormat.component";

moment.updateLocale("lu", {
  workingWeekdays: [1, 2, 3, 4, 5],
});

/** @jsx jsx */
export interface Row {
  label: string;
  action: () => void;
}

const titleStyle = css`
  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    color: #354450;
    margin: 0px;
    margin-bottom: 5px;
  }
`;

export interface SecurityOverviewProps {
  label?: string;
  children?: any;
  securityData?: Security;
  issuersData?: SearchIssuer[];
  issuersLoading?: boolean;
  programmeData?: ProgrammeDetail;
  programmeLoading?: boolean;
  noticesData?: Document[];
  noticesLoading?: boolean;
  rows?: Row[];
  onTabChange: (tab: SecurityCardTab) => void;
}

const SecurityOverview: React.FunctionComponent<SecurityOverviewProps> = ({
  securityData,
  issuersData,
  issuersLoading = false,
  programmeData,
  programmeLoading = false,
  noticesData,
  noticesLoading = false,
  onTabChange,
}: SecurityOverviewProps) => {
  const checkEcbValidity = (date?: string) => {
    return !!(
      date &&
      moment(date).isSameOrAfter(
        moment(moment().startOf("day")).prevBusinessDay()
      )
    );
  };

  const patchCondition = useCallback((date: string) => {
    if (tmpValuesArray.includes(Number(securityData?.id))) {
      return "18/12/2023 ";
    }
    return date;
  }, []);

  return (
    <div
      css={[
        style.securityOverview,
        css`
          padding-top: 0px !important;
        `,
      ]}
    >
      <div css={style.securityOverviewTopNew}>
        <div css={style.securityOverviewCard}>
          <div css={titleStyle}>
            <h3>Issuer</h3>
          </div>
          <IssuerSummaryCards
            issuers={issuersData}
            isGreen={securityData.lgxDisplay}
            loading={issuersLoading}
          />
        </div>
        <div css={style.securityOverviewCard}>
          <div css={titleStyle}>
            <h3>Programme</h3>
          </div>
          <ProgrammeSummaryCard
            programme={programmeData}
            loading={programmeLoading}
          />
        </div>
      </div>
      <div css={style.securityOverviewBottom}>
        <div css={style.securityOverviewTopLeftStart}>
          <div css={style.flexRow}>
            <div css={style.blocTitle}>Reference data</div>

            {securityData?.instrumentSubtype ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Issue type</div>
                <div css={style.blocRowValue}>
                  {instrumentSubTypeLabel(securityData.instrumentSubtype)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.issuedNominalAmount ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Issued amount</div>
                <div css={style.blocRowValue}>
                  {`${formatToThousandsSeparator(
                    securityData.issuedNominalAmount
                  )} ${securityData.currency}`}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.listedSecurities ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Securities</div>
                <div css={style.blocRowValue}>
                  {formatToThousandsSeparator(
                    securityData.listedSecurities,
                    " "
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.issuedSecurities ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Issued Securities</div>
                <div css={style.blocRowValue}>
                  {formatToThousandsSeparator(
                    securityData.issuedSecurities,
                    " "
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.listingDate ? (
              <>
                {!securityData.tags?.includes("ATT") ? (
                  <div css={style.blocRowSpace}>
                    <div css={style.blocRowKey}>Listing date</div>
                    <div css={style.blocRowValue}>
                      {formatDate(securityData.listingDate)}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {!marketRuleCheck(
                  securityData?.marketRule?.toString(),
                  MarketRuleEnum.SOL
                ) ? (
                  <div css={style.blocRowSpace}>
                    <div css={style.blocRowKey}>First trading date</div>
                    <div css={style.blocRowValue}>
                      {patchCondition(formatDate(securityData.listingDate))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {securityData?.maturityDate ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Final maturity</div>
                <div css={style.blocRowValue}>
                  {formatDate(securityData.maturityDate)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.delistingDate && securityData?.status == "RETR" ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Delisting date</div>
                <div css={style.blocRowValue}>
                  <p>{formatDate(securityData.delistingDate)}</p>
                  <p>
                    {securityDelistingReasonLabel(securityData.delistingReason)}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.interestRate ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Coupon</div>
                <div
                  css={style.blocRowValue}
                >{`${securityData.interestRate}%`}</div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.periodicityCode ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Periodicity</div>
                <div css={style.blocRowValue}>
                  {setPeriodicityCodeLabelDataValue(
                    securityData.periodicityCode
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.redemptionPrice ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Redemption price</div>
                <div css={style.blocRowValue}>
                  {securityData.redemptionPrice}
                </div>
              </div>
            ) : (
              <></>
            )}
            {securityData?.blockchainType ? (
              <>
                <div css={style.blocRowSpace}>
                  <div css={style.blocRowKey}>Blockchain type</div>
                  <div css={style.blocRowValue}>
                    {securityData.blockchainType}
                  </div>
                </div>
                <div css={style.blocRowSpace}>
                  <div css={style.blocRowKey}>HASH code</div>
                  <div css={style.blocRowValue}>
                    {securityData?.blockchainHash || "-"}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {checkEcbValidity(securityData?.ecbCollateral?.injectionDate) && (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>ECB Collateral</div>
                <div css={style.blocRowValue}>
                  <div css={style.ecbParent}>
                    <div css={style.ecbTag}>
                      <Icon icon={"check"} /> Eligible
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div css={style.securityOverviewTopRightStart}>
          <div css={style.flexRow}>
            <div css={style.blocTitle}>Notices</div>
            <div>
              <Loader loading={noticesLoading}>
                {noticesData?.length ? (
                  noticesData.map((notice: Document) => (
                    <NoticeSummaryCard notice={notice} />
                  ))
                ) : (
                  <div style={{ color: "#354450" }}>No notice found</div>
                )}
              </Loader>
            </div>

            <div style={{ marginTop: 24 }}>
              <a
                css={style.link}
                onClick={() => onTabChange(SecurityCardTab.DOCUMENTS)}
              >
                Access to all documents
              </a>
            </div>
          </div>
          { securityData?.comment ?
            <div css={style.flexRow}>
              <div css={style.blocTitle}>Further information for investors</div>
              <TextFormat text={securityData?.comment}/>
            </div>
            : <></>
          }
        </div>
      </div>
    </div>
  );
};

export default SecurityOverview;
