import axios from "axios";
import {getUrlByEnv} from "./helper";
import config from "../temp/config";
const instance = axios.create();
///blog-data/search/:params

const fetchBlogData = async(params: any) => {
    const url = `${getUrlByEnv()}/blog-data/search/${params}`;
    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('blog request api error =>', err);
        return false;
    }
}

export const getArticle = async (id: string) => {
    //const {authors, categories, tags} = props;
    const url = `${getUrlByEnv()}/blog-data/articles/${id}`;

    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }
}

export const getAuthors = async () => {
    const url = `${getUrlByEnv()}/blog-data/search/authors`;

    try {
        const result: any = await instance.get(url, { timeout: 0 });
        const { data } = result;

        // Sort authors alphabetically by last name
        data.sort((a: any, b: any) => {
            const lastNameA = a.lastName.toLowerCase();
            const lastNameB = b.lastName.toLowerCase();
            if (lastNameA < lastNameB) return -1;
            if (lastNameA > lastNameB) return 1;
            return 0;
        });

        return data;
    } catch (err) {
        console.error('helper request api error =>', err);
        return false;
    }
}

export const getAuthorsBySitecoreAPI = async (itemId: any) => {
    const url = `${getUrlByEnv()}/sitecore/api/ssc/item/${itemId}/children?sc_apikey=${config.sitecoreApiKey}&sc_site=${config.jssAppName}&sc_lang=en`;

    try{
        const result: any = await instance.get(url, {timeout: 0});
        const {data} = result;
        return data;
    } catch(error){
        console.error('sitecore api error =>', error);
        return false;
    }
}

export const fetchMediaUrl = async (mediaId: any): Promise<string | null> => {
    const url = `${getUrlByEnv()}/sitecore/api/ssc/item/{${mediaId}}?sc_apikey=${config.sitecoreApiKey}&sc_site=${config.jssAppName}&sc_lang=en`;

    try {
        const result = await instance.get(url);
        const { data } = result;

        if (data && data.ItemMedialUrl) {
            return data.ItemMedialUrl;
        }
        return null;
    } catch (error) {
        console.error("Error fetching media URL:", error);
        return null;
    }
};

export const getArticles = async (params? : any) => {
    //const {authors, categories, tags} = props;
    const url = `${getUrlByEnv()}/blog-data/search/articles${params || ''}`;

    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }
    /*const url = `https://grp-weu-dev-scor-cm.apps.dev.luxse.cloud/sitecore/api/search/`;
    try {
        const result: any = await instance.get(url, {
            params: {
                //authors,
                //categories,
                //tags,
                sortascending: false,
            }
        });
        console.clear();

        console.log('response', result)
        //const { data } = result;
        return result;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }*/
}
//http://localhost:50800/sitecore/api/search/articles?
// authors=Julie Vichy&
// categories=finance,bond&
// tags=Tag 1&
// sortby=views&
// sortascending=true&
// skip=0&
// take=3