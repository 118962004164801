import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { TextFormatParser } from "../useTextParser";

export const textParser : TextFormatParser = {
    type: "TEXT",
    regex: null,
    render: (text: string) => {
        return <>{text}</>;
    }
}