import gql from "graphql-tag";
import {
    securityMarketFragment,
    securityMarketPriceFragment,
    securityMarketRealtimeFragment,
    securityMarketSummaryFragment
} from "./securities-market.queries";
import {issuerSummaryFragment} from "./issuers.queries";
import {indiceSummaryFragment} from "./indices.queries";
import {documentSummaryFragment} from "./documents.queries";
import {programmeSummaryFragment} from "./programmes.queries";
import {greenCardFragment} from "./green.queries";
import {securitySearchFullFragment, securitySearchItemFragment} from "./search/search-security.queries";

export const GET_SECURITIES_MARKET_TRANSFER = gql`
    query GetSecuritiesMarketTransfer($pageNumber: Float!, $pageSize: Float!) {
        securitiesLatestMarketTransfer(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            issuedNominalAmountEUR
            issuedNominalAmount
            currency
            status
            marketTransfers {
                changeUtcDt
                marketBefore
                marketAfter
            }
            postponed
        }
    }
`;

export const GET_LATEST_SUSPENSIONS = gql`
    query GetLatestSuspensions($pageNumber: Float!, $pageSize: Float!) {
        securitiesLatestSuspension(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            listingDate
            suspensionDate
            suspensionReason
            currency
            issuedNominalAmount
            status
            postponed
        }
    }
`;

export const GET_LATEST_LISTINGS = gql`
    query GetLatestListings($pageNumber: Float!, $pageSize: Float!) {
        securitiesLatestListing(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            listingDate
            delistingDate
            status
            currency
            issuedNominalAmount
            tradingCode
            postponed
        }
    }
`;

export const GET_LATEST_CSSF_APPROVAL = gql`
    query GetLatestCssfApproval($pageNumber: Float!, $pageSize: Float!) {
        latestCssfApproval(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            description
            publishDate
            referenceDate
            documentTypeCode
            documentPublicTypeCode
            downloadUrl
            categories
            complement
            language
            incorporedByReferenceDocuments  {
                id
                downloadUrl
                description
                documentTypeCode
                documentPublicTypeCode
                complement
                categories
                format
                publishDate
                referenceDate
            }
            hostMemberStates
        }
    }
`;

export const GET_LATEST_FNS = gql`
    query GetLatestFNS($pageNumber: Float!, $pageSize: Float!) {
        latestFNS(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            name
            description
            publishDate
            referenceDate
            documentTypeCode
            documentTypeTranslatableField {
                field
                key
                value
            }
            documentPublicTypeCode
            documentPublicTypeTranslatableField {
                field
                key
                value
            }
            downloadUrl
            categories
            complement
            language
            depositor {
                id
                name
            }
            issuers {
                id
                name
            }
        }
    }
`;

export const GET_UPCOMING_DELISTINGS = gql`
    query GetSecuritiesUpcomingDelistings($pageNumber: Float!, $pageSize: Float!) {
        securitiesUpcomingDelistings(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            issuedNominalAmount
            listingDate
            delistingDate
            delistingReason
            currency
            status
            postponed
        }
    }
`;

export const GET_LATEST_ADMISSION_TO_TRADING = gql`
    query GetSecuritiesLatestAdmissionToTrading($pageNumber: Float!, $pageSize: Float!) {
        securitiesLatestAdmissionToTrading(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            issuedNominalAmount
            listingDate
            delistingDate
            delistingReason
            currency
            status
            postponed
        }
    }
`;

export const GET_UPCOMING_REMOVAL_FROM_TRADING = gql`
    query GetSecuritiesUpcomingRemovalFromTrading($pageNumber: Float!, $pageSize: Float!) {
        securitiesUpcomingRemovalFromTrading(pageNumber: $pageNumber, pageSize: $pageSize) {
            id
            instrumentName
            isin
            issuedNominalAmount
            listingDate
            delistingDate
            delistingReason
            currency
            status
            postponed
        }
    }
`;

export const GET_RISERS_SECURITIES_LIST_YEARLY_FULL = gql`
query GetRisersSecuritiesListYearlyFull($limit: Int) {
    yearlyRisersLuxXPrimeSecurities(limit: $limit) {
        id
        instrumentName
        isin
        category: instrumentType
        currency
        interestRate
        listingDate
        maturityDate
        marketData {
            dayVolume
            yieldToMaturity
            ytdVariation
            tradeIndicator
            lastPrice {
                amount
                marker
            }
            previousYearLastPrice {
                amount
                marker
            }
            dailyChange
            yieldToMaturity
        }
        # marketRealtime {
        #   bestOrders {
        #     bid {
        #       quantity
        #       price
        #     }
        #     ask {
        #       quantity
        #       price
        #     }
        #   }
        # }
    }
}
`;

export const GET_RISERS_SECURITIES_LIST_YEARLY = gql`
    query GetRisersSecuritiesListYearly($limit: Int) {
        yearlyRisersLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousYearLastPrice {
                    amount
                    marker
                }
                dailyChange
                mtdVariation
                ytdVariation
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_FALLERS_SECURITIES_LIST_YEARLY = gql`
    query GetFallersSecuritiesListYearly($limit: Int) {
        yearlyFallersLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousYearLastPrice {
                    amount
                    marker
                }
                dailyChange
                mtdVariation
                ytdVariation
                yieldToMaturity
            }
            #marketRealtime {
            #  bestOrders {
            #    bid {
            #      quantity
            #      price
            #    }
            #    ask {
            #      quantity
            #      price
            #    }
            #  }
            #}
        }
    }
`;

export const GET_MOST_VIEWED_SECURITIES_LIST = gql`
    query GetMostViewedLuxXPrimeSecurities($limit: Int) {
        mostViewedLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousMonthLastPrice {
                    amount
                    marker
                }
                dailyChange
                mtdVariation
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_LAST_TRADED_SECURITIES_THUNBNAIL = gql`
    query GetLastTradedSecuritiesThumbnail($limit: Int) {
        lastTradedLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            marketData {
                dailyChange
                pricesHistory {
                    amount
                    date
                }
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       price
            #     }
            #     ask {
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_MOST_TRADED_SECURITIES_LIST_YEARLY = gql`
    query GetMostTradedSecuritiesListYearly($limit: Int) {
        lastYearMostTradedLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            currency
            category: instrumentType
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousMonthLastPrice {
                    amount
                    marker
                }
                dailyChange
                mtdVariation
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_MOST_TRADED_SECURITIES_LIST_YEARLY_TWO = gql`
    query GetMostTradedSecuritiesListYearly($limit: Int) {
        lastYearMostTradedLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousMonthLastPrice {
                    amount
                    marker
                }
                dailyChange
                mtdVariation
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;


export const GET_MOST_TRADED_SECURITIES_LIST = gql`
    query GetMostTradedSecuritiesList($limit: Int) {
        mostTradedLuxXPrimeSecurites(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousMonthLastPrice {
                    amount
                    marker
                }
                dailyChange
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_LAST_TRADED_SECURITIES_LIST = gql`
    query GetLastTradedSecuritiesList($limit: Int) {
        lastTradedLuxXPrimeSecurities(limit: $limit) {
            id
            instrumentName
            isin
            category: instrumentType
            currency
            interestRate
            listingDate
            maturityDate
            marketData {
                dayVolume
                yieldToMaturity
                tradeIndicator
                lastPrice {
                    amount
                    marker
                }
                previousMonthLastPrice {
                    amount
                    marker
                }
                dailyChange
                yieldToMaturity
            }
            # marketRealtime {
            #   bestOrders {
            #     bid {
            #       quantity
            #       price
            #     }
            #     ask {
            #       quantity
            #       price
            #     }
            #   }
            # }
        }
    }
`;

export const GET_SECURITY_MARKET_QUERY = gql`
    ${securityMarketPriceFragment}
    ${securityMarketRealtimeFragment}
    query GetSecurityMarket($idSecurity: String!) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            marketData {
                ...securityMarketPriceFragment
            }
            marketRealtime {
                ...securityMarketRealtimeFragment
            }
        }
    }
`;

export const GET_SECURITY_PROGRAMME_QUERY = gql`
    ${programmeSummaryFragment}
    query GetSecurityProgramme($idSecurity: String!) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            programme {
                ...programmeSummaryFragment
            }
        }
    }
`;

export const GET_SECURITY_ISSUERS_QUERY = gql`
    ${issuerSummaryFragment}
    query GetSecurityIssuers($idSecurity: String!) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            issuers {
                ...issuerSummaryFragment
            }
        }
    }
`;

export const GET_SECURITY_INDICES_QUERY = gql`
    ${indiceSummaryFragment}
    query GetSecurityIndices($idSecurity: String!) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            indices{
                ...indiceSummaryFragment
            }
        }
    }
`;

export const GET_RELATED_SECURITIES_QUERY = gql`
    ${securityMarketFragment}
    ${securityMarketRealtimeFragment}
    query GetRelatedSecurities($idSecurity: String!, $relatedSecuritiesLimit: Int) {
        relatedSecurities(id: $idSecurity, limit: $relatedSecuritiesLimit){
            id
            isin
            instrumentName
            instrumentType
            marketData {
                ...securityMarketFragment
            }
            marketRealtime {
                ...securityMarketRealtimeFragment
            }
        }
    }
`;

export const GET_SECURITY_NOTICES_QUERY = gql`
    ${documentSummaryFragment}
    query GetSecurityNotices($idSecurity: String!, $noticesLimit: Int) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            notices(limit: $noticesLimit) {
                totalSize: totalResultSize
                data: documents {
                    ...documentSummaryFragment
                }
            }
        }
    }
`;

export const GET_SECURITY_FROM_ISIN_QUERY = gql`
    query GetSecurityFromIsin($isin: String!) {
        securityNotFungibleByIsin(isin: $isin) {
            id
            isin
            instrumentName
        }
    }
`;


export const securitySummaryWithMarketFragment = gql`
    ${securityMarketSummaryFragment}
    fragment securitySummaryWithMarketFragment on Security {
        id
        instrumentName
        isin
        status
        delistingDate
        currency
        instrumentType
        instrumentSubtype
        listingDate
        maturityDate
        tags
        issuedNominalAmount
        interestRate
        luxxprimeDate
        marketRule
        marketSegment
        marketData {
            ...securityMarketSummaryFragment
        }
    }
`;

export const GET_SECURITY_QUERY = gql`
    ${securityMarketFragment}
    ${securityMarketRealtimeFragment}
    ${greenCardFragment}
    query GetSecurity($idSecurity: String!) {
        security(id: $idSecurity) {
            id
            isin
            instrumentName
            instrumentType
            instrumentTypeTranslatableField {
                field
                key
                value
            }
            instrumentSubtype
            ecbCollateral {
                injectionDate
                haircut
                haircutCategoryCode
                typeCode
            }
            instrumentSubtypeTranslatableField {
                field
                key
                value
            }
            issuedNominalAmount
            issuedSecurities
            listedSecurities
            currency
            suspensionDate
            suspensionReason
            listingDate
            maturityDate
            delistingDate
            delistingReason
            fastLaneUrls
            blockchainType
            blockchainHash
            status
            tags
            lgxDisplay
            lgxEligibleCategories
            lgxExternalReportings
            lgxExternalReviews
            lgxPubDate
            lgxSdgs
            lgxSlbKpiThemes
            lgxStandards
            lgxSustainableBonds
            lgxSustainableFunds
            comment
            homeMarket {
                mic
                name
                country
            }
            tradingVenue {
                mic
                name
                country
            }
            dayConventionCode
            quotingConvention
            interestRate
            periodicityCode
            marketRule
            marketSegment
            brokers {
                id
                name
                urls
                logoUrl
            }
            redemptionPrice
            marketData {
                ...securityMarketFragment
            }
            marketRealtime {
                ...securityMarketRealtimeFragment
            }
        }

        luxseLgx(id: $idSecurity) {
            ...greenCardFragment
        }
    }
`;


export const PROCESS_YIELD_CALCULATION = gql`
    mutation ProcessYieldsCalculation($yieldsData: YieldDataInput!) {
        yieldsCalculation(yieldsData: $yieldsData) {
            averageYield
            averageYieldCalc2
            netResult
            totalCash
            totalRePayment
            yieldOverYears {
                year
                interests
                investment
            }
        }
    }
`;

export const SEARCH_LGX_SECURITIES_WITHOUT_FILTERS = gql`
    ${securitySearchItemFragment}
    query searchLGXSecuritiesWithoutFilters(
        $page: Int,
        $size: Int,
        $sort: String!
        $listingDateFrom: Date
        $listingDateTo: Date
        $statuses: [String!]
        $statusesOperator: String
    ){
        luxseSecuritiesSearch(
            searchTerm: "",
            page: $page,
            size: $size,
            sort: $sort,
            lgxOnly : true,
            statuses: $statuses,
            statusesOperator: $statusesOperator,
            listingDateFrom: $listingDateFrom,
            listingDateTo: $listingDateTo,
        ){
            totalHits,
            securities {
                ...securitySearchItemFragment
            }
        }
    }
`
export const SEARCH_LGX_DATA_COUNTER = gql`
    query GetLuxseSearchResults(
        $page : Int,
        $size : Int,
        $sort: String,
        $statuses: [String!],
        $statusesOperator: String,
        $lgxOnly: Boolean
    ){
        luxseSecuritiesSearch(
            searchTerm: "",
            page: $page,
            size: $size,
            sort: $sort,
            statuses: $statuses,
            statusesOperator: $statusesOperator,
            lgxOnly: $lgxOnly
        ){
            totalHits,
            filters {
                lgxOnly
                climateAlignedOnly
                genderBondOnly
                lgxSustainableBonds {
                    name
                    count
                }
                lgxSustainableFunds {
                    name
                    count
                }
                climateAlignedOnly
                genderBondOnly
            },
        }
    }

`

export const SEARCH_LGX_SECURITIES_WITH_FILTERS = gql`
    ${securitySearchFullFragment}
    query searchLGXSecuritiesWithFilters(
        $page : Int,
        $size : Int,
        $sort: String!
        $listingDateFrom: Date
        $listingDateTo: Date
        $statuses: [String!]
        $statusesOperator: String
    ){
        luxseSecuritiesSearch(
            searchTerm: "",
            page: $page,
            size: $size,
            sort: $sort,
            lgxOnly : true,
            statuses: $statuses,
            statusesOperator: $statusesOperator,
            listingDateFrom: $listingDateFrom,
            listingDateTo: $listingDateTo,
        ){
            ...securitySearchFullFragment
        }
    }
`

export const SEARCH_SECURITIES_WITHOUT_FILTERS = gql`
    ${securityMarketFragment}
    query GetLuxseSearchResults(
        $page : Int,
        $size : Int,
        $sort: String!
        $statuses: [String!]
        $statusesOperator: String!
        $listingDateFrom: Date!
        $listingDateTo: Date!
    ){
        luxseSecuritiesSearch(
            searchTerm: "",
            page: $page,
            size: $size,
            sort: $sort,
            statuses: $statuses,
            statusesOperator: $statusesOperator
            listingDateFrom: $listingDateFrom
            listingDateTo: $listingDateTo
        ){
            totalHits,
            securities {
                id
                instrumentName
                isin
                status
                listingDate
                marketData{
                    ...securityMarketFragment
                }
            }
        }
    }
`;

export const GET_SEARCH_SECURITIES_ONLY_RESULTS = gql`
    ${securitySearchFullFragment}
    query SearchSecurities(
        $searchTerm: String!
        $size: Int
        $page: Int
        $sort: String!
        $lgxOnly: Boolean
        $climateAlignedOnly: Boolean
        $luxXPrimeOnly: Boolean
        $excludeRetr: Boolean
        $category: LuxseSearchSecurityCategoryFilter
        $marketRegulation: MarketRule
        $currency: Currency
        $chineseBondOnly: Boolean
        $genderBondOnly: Boolean
        $securityStatuses: [String!]
        $securityStatusesOperator: String
        $securityExcludeStatuses: [String!]
        $securityMarketRules: [String!]
        $securityExcludeMarketRules: [String!]
        $securityMarketSegments: [String!]
        $tokenOnly: Boolean
        $tags: [String!]
        $tagsOperator: String
        $lgxSustainableBonds: [String!]
        $lgxSustainableFunds: [String!]
        $lgxStandards: [String!]
        $lgxExternalReviewOnly: Boolean
        $lgxExternalReportingOnly: Boolean
        $lgxPostIssuanceOnly: Boolean
        $lgxSdgs: [String!]
        $lgxEligibleCategories: [String!]
        $lgxSlbKpiThemes: [String!]
        $issuerId: String
        $programmeId: String
    ) {
        luxseSecuritiesSearch(
            searchTerm: $searchTerm
            size: $size
            page: $page
            sort: $sort
            lgxOnly: $lgxOnly
            climateAlignedOnly: $climateAlignedOnly
            luxXPrimeOnly: $luxXPrimeOnly
            excludeRetr: $excludeRetr
            category: $category
            marketRegulation: $marketRegulation
            currency: $currency
            chineseBondOnly: $chineseBondOnly
            genderBondOnly: $genderBondOnly
            statuses: $securityStatuses
            statusesOperator: $securityStatusesOperator
            excludeStatuses: $securityExcludeStatuses
            marketRules: $securityMarketRules
            excludeMarketRules: $securityExcludeMarketRules
            marketSegments: $securityMarketSegments
            tokenOnly: $tokenOnly
            tags: $tags
            tagsOperator: $tagsOperator
            lgxSustainableBonds: $lgxSustainableBonds
            lgxSustainableFunds: $lgxSustainableFunds
            lgxStandards: $lgxStandards
            lgxExternalReviewOnly: $lgxExternalReviewOnly
            lgxExternalReportingOnly: $lgxExternalReportingOnly
            lgxPostIssuanceOnly: $lgxPostIssuanceOnly
            lgxSdgs: $lgxSdgs
            lgxEligibleCategories: $lgxEligibleCategories
            lgxSlbKpiThemes: $lgxSlbKpiThemes
            issuerId: $issuerId
            programmeId: $programmeId
        ) {
            ...securitySearchFullFragment
        }
    }
`;