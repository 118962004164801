import * as React from "react";

import { breakpoint, breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  tagStyle: css`
    align-items: center;
    justify-content: flex-start;
    border: solid 1px #e3e6e8;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    margin-right: 5px;
    font-size: 13px;
  `,
  highlightTag: css`
  background-color: #f3f3cd;
  `,	
  securityOverview: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding-top: 16px;
  `,

  securityOverviewTop: css`
    display: flex;
    margin: 80px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      margin: 10px;
    }
  `,

  sustainableDataTop: css`
    display: flex;
    padding: 80px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      padding: 10px;
    }
  `,

  sustainableDataBottom: css`
    display: flex;
    margin: 80px;
    margin-left: 140px;
    margin-right: 140px;
    border: solid 1px #e3e6e8;
    display: flex;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin-left: 0px;
      margin-right: 0px;
    }
  `,

  securityOverviewTopNew: css`
    @media (min-width: ${breakpoint}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;

      padding-left: 80px;
      padding-right: 80px;
      padding-top: 45px;
      padding-bottom: 73px;
      gap: 32px;

      width: 100%;
      min-height: 392px;

      background: linear-gradient(180deg, #e8ecee 1.66%, #ffffff 121.3%);

      border: 1px solid #e8ecee;
      border-radius: 4px;
    }
    @media (max-width: ${breakpointMax}) {
      margin-top: -1em;
    }
  `,
  securityOverviewTopNewContainer: css`
    @media (min-width: ${breakpoint}) {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: ${breakpointMax}) {
      margin-top: -1em;
    }
  `,
  securityOverviewTopLeft: css`
    width: 50%;
    display: flex;

    @media (max-width: 720px) {
      width: 100%;
      border-bottom: solid 1px #e3e6e8;
      padding: 1em;
      align-items: flex-start;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      padding: 1em;
    }
  `,
  sustainableDataCardContainer: css`
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  descriptionCard: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px;
    height: 100%;

    @media (max-width: ${breakpointMax}) {
      padding: 20px;
    }
  `,
  descriptionCardGrey: css`
    background-color: #f0f5f5;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px;
    min-height: 600px;
    @media (max-width: ${breakpointMax}) {
      padding: 20px;
    }
  `,
  descriptionCardTitle: css`
    width: 100%;
    padding-bottom: 1em;
    color: #354450;
    font-size: 1.5em;
    line-height: 31px;
    font-weight: 800;
  `,
  descriptionCardText: css`
    width: 100%;
    color: #354450;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  `,
  securityOverviewTopRight: css`
    width: 50%;
    display: flex;
    //align-items: center;
    border-left: solid 1px #e3e6e8;

    @media (max-width: 720px) {
      width: 100%;
      border-bottom: solid 1px #e3e6e8;
      padding: 1em;
      align-items: flex-start;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      padding: 1em;
    }
  `,
  securityOverviewCard: css`
    @media (min-width: ${breakpoint}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      justify-content: start;
    }

    @media (max-width: ${breakpointMax}) {
      margin: 120px;
      margin: 2em;
    }
  `,
  securityOverviewTopLeftStart: css`
    width: 50%;
    display: flex;
    align-items: flex-start;

    @media (max-width: 720px) {
      width: 100%;
      padding: 1em;
      border-bottom: solid 1px #e3e6e8;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      padding: 1em;
    }
  `,
  securityOverviewBottom: css`
    display: flex;
    padding: 0px 80px;
    @media (max-width: ${breakpointMax}) {
      display: block;
      padding: 10px;
    }
  `,
  securityOverviewTopRightStart: css`
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding: 1em;
      border-bottom: solid 1px #e3e6e8;
      border-left: 0px;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      padding: 1em;
    }
  `,
  black: css`
    color: black;
  `,
  leftPart: css`
    min-width: 60px;
    max-width: 60px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 720px) {
      border-bottom: none;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      min-width: 30%;
      max-width: 30%;
      display: flex;
      justify-content: center;
    }
  `,
  leftPartLarge: css`
    min-width: 60px;
    margin-right: 16px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 720px) {
      border-bottom: none;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      min-width: 30%;
      max-width: 30%;
      display: flex;
      justify-content: center;
    }
  `,
  centerPart: css`
    display: flex;
    flex-direction: column;
    min-width: calc(100% - 120px);
    max-width: calc(100% - 120px);

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      min-width: 50%;
      max-width: 50%;
    }
  `,
  rightPartSmall: css`
    min-width: 60px;
    max-width: 60px;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 720px) {
      border-bottom: none;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      min-width: 30%;
      max-width: 30%;
      display: flex;
      justify-content: center;
    }
  `,
  rightPart: css`
    display: flex;
    flex-direction: column;
    min-width: calc(100% - 60px);
    max-width: calc(100% - 60px);

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      min-width: 70%;
      max-width: 70%;
    }
  `,
  rightPartTop: css`
    color: #354450;
    letter-spacing: 0.1em;
    line-height: 23px;
    font-size: 1.125em;
    font-weight: 500;
  `,
  rightPartMid: css`
    color: #354450;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 720px) {
      font-size: 1em;
      line-height: 20px;
    }
  `,
  rightPartMidSmall: css`
    color: #1a2934;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  `,
  rightPartBottom: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #354450;
  `,
  blocTitle: css`
    color: #354450;
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    @media (max-width: 720px) {
      font-size: 1.5em;
      line-height: 2em;
      flex-direction: column;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      font-size: 2em;
      line-height: 1.5em;
    }
  `,

  blocTitleRight: css`
    display: flex;

    @media (max-width: 720px) {
      justify-content: center;
    }
  `,
  blocTitleRightIcon: css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #22aa5f;
    padding-right: 32px;
  `,
  blocTitleRightContent: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,
  blocTitleRightTop: css`
    color: #22aa5f;
    font-weight: 800;
    font-size: 32px;
    display: flex;
    align-items: flex-end;

    @media (max-width: 720px) {
      font-size: 24px;
    }
  `,
  blocTitleRightBottom: css`
    color: #354450;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: flex-start;
    padding-top: 8px;

    @media (max-width: 720px) {
      font-size: 24px;
      padding-top: 0;
    }
  `,
  desktopData: css`
    @media (max-width: 720px) {
      display: none;
    }
  `,
  marketDataContainer: css`
    display: flex;
    margin-bottom: 3em;

    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,
  marketBoxLeft: css`
    @media (min-width: 720px) {
      width: 33%;
      margin-left: 5px;
    }
  `,
  marketBoxCenter: css`
    @media (min-width: 720px) {
      width: 33%;
      margin-left: 10px;
      margin-right: 10px;
    }
  `,
  marketBoxRight: css`
    @media (min-width: 720px) {
      width: 33%;
      margin-right: 5px;
    }
  `,
  marketBoxTwo: css`
    @media (min-width: 720px) {
      width: 50% !important;
      margin-left: 10px;
      margin-right: 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  `,
  marketBoxHeader: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #ffffff;
    border: solid 1px rgba(37, 54, 69, 0.07);
    box-shadow: 0 16px 24px rgba(37, 54, 69, 0.07);
    padding: 1.5em 1em;
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;
    height: 150px;

    @media (max-width: 720px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1em;
    }
    @media (min-width: 719px) {
      &:first-of-type {
        margin-left: 0;
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  `,

  marketBoxHeaderLabel: css`
    color: #354450;
    text-align: center;
    height: 1.5em;
    margin-bottom: 15px;
  `,
  marketDoubleCol: css`
    display: flex;
  `,
  marketDoubleColLeft: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 0.5em;
    text-align: center;
  `,
  marketDoubleColLeftLabel: css`
    padding-bottom: 1em;
    color: #354450;
    opacity: 0.7;
    font-weight: 500;
    font-size: 14px;
  `,
  marketDoubleColLeftAmount: css`
    color: #354450;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 720px) {
      padding-bottom: 0;
    }
  `,
  marketDoubleColLeftSubAmount: css`
    color: #354450;
    padding-top: 1em;
    font-weight: 500;
    font-size: 14px;

    @media (max-width: 720px) {
      padding-top: 0.5em;
    }
  `,
  marketDoubleColRight: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 0.5em;
    text-align: center;
    border-left: solid 1px #e3e6e8;
  `,
  marketOneCol: css`
    width: 100%;
    border-left: none;
  `,
  mobileData: css`
    @media (min-width: 579px) {
      display: none;
    }
  `,
  mobileDataContainer: css`
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    background: #ffffff;
    width: 100%;
  `,
  mobileDataContainerRow: css`
    border-bottom: solid 1px #e3e6e8;
    padding: 1.275em;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }
  `,
  mobileDataContainerRowSide: css`
    width: 50%;
    display: flex;
  `,
  mobileDataContainerRowSideSmall: css`
    width: 35%;
    display: flex;
    justify-content: center;
  `,
  mobileDataContainerRowSideLargeRight: css`
    width: 65%;
    flex-direction: column;
    padding-left: 2.5em;

    div {
      display: flex;
      justify-content: flex-start;
    }
  `,
  mobileDataContainerRowSideLargeLeft: css`
    width: 65%;
    flex-direction: column;
    border-right: solid 1px #e3e6e8;
    padding-right: 2.5em;

    div {
      display: flex;
      justify-content: flex-end;
    }
  `,
  mobileDataContainerRowSideLargeTop: css`
    color: #54666f;
    font-size: 13px;
    font-weight: 800;
    margin-bottom: 0.5em;
  `,
  mobileDataContainerRowSideLargeBottom: css`
    color: #54666f;
    font-size: 13px;
    font-weight: 600;
  `,
  mobileDataTitles: css`
    display: flex;
    width: 100%;

    span {
      color: #54666f;
      font-size: 13px;
      font-weight: 800;
      margin-bottom: 2em;
      width: 50%;

      &:first-of-type {
        display: flex;
        justify-content: flex-end;
        margin-right: 2.5em;
      }

      &:last-child {
        display: flex;
        justify-content: flex-start;
        margin-left: 2.5em;
      }
    }
  `,
  blocTitleSmall: css`
    color: #354450;
    font-weight: 800;
    font-size: 1.5em;
    line-height: 31px;
    margin-bottom: 1em;
  `,
  blocTab: css`
    padding: 1em;
    border: 1px solid #e6eef2;
    border-radius: 6px;
  `,
  blocRow: css`
    color: #354450;
    align-items: center;
    display: flex;
    justify-content: flex-start;
  `,
  blocRowSpace: css`
    color: #354450;
    align-items: center;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: solid 1px #e6eef2;
    justify-content: space-between;
  `,
  blocRowSpaceNoBorderTop: css`
    border-top: none;
  `,
  ecbParent: css`
    display: flex;
  `,
  ecbTag: css`
    border-radius: 24px;
    border: 1px solid #22aa5f;
    background: #e8faf0;
    align-items: center;
    display: flex;
    color: #22aa5f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px;
    align-self: flex-start;

    svg {
      margin-right: 4px;
    }
  `,
  blocRowSpaceFull: css`
    flex-direction: column;
    justify-content: left;
  `,
  blocRowInvert: css`
    color: #354450;
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: solid 1px #e6eef2;
    justify-content: flex-start;
  `,
  blocRowKey: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #354450;
    @media (max-width: 720px) {
      font-size: 1em;
      line-height: 1em;
    }
  `,
  blocRowKeyRed: css`
    color: #ca1234 !important;
  `,
  blocRowKeyFull: css`
    color: #354450;
    font-weight: 800;
    font-size: 1.0625em;
    line-height: 22px;
    width: 100%;

    @media (max-width: 720px) {
      font-size: 1em;
      line-height: 1em;
    }
  `,
  blocRowKeyAlt: css`
    color: #354450;
    font-weight: 800;
    font-size: 1.0625em;
    line-height: 22px;
    width: 30%;

    @media (max-width: 720px) {
      font-size: 1em;
      line-height: 1em;
    }
  `,
  blocRowValue: css`
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #354450;
    width: 230px;

    > div {
      margin-bottom: 12px;
      display: flex;
      flex-direction: row;

      > div {
        font-size: 16px;
        font-weight: 500;
      }
    }

    > p {
      margin: 5px 0px;
    }

    > p:first-child {
      margin-top: 0px;
    }

    > p:last-child {
      margin-bottom: 0px;
    }

    @media (max-width: 720px) {
      font-size: 0.75em;
      line-height: 1em;
      width: unset;
    }
  `,
  blocRowValueFull: css`
    display: flex;
    flex-direction: column;
    color: #273b4c;
    font-weight: 600;
    font-size: 1.0625em;
    line-height: 22px;
    width: 100%;
    padding-top: 1em;
    @media (max-width: 720px) {
      font-size: 0.75em;
      line-height: 1em;
    }
  `,
  blocRowValueAlt: css`
    display: flex;
    flex-direction: column;
    color: #273b4c;
    font-weight: 600;
    font-size: 1.0625em;
    line-height: 22px;
    width: 70%;
    @media (max-width: ${breakpointMax}) {
      font-size: 0.75em;
      line-height: 1em;
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3em;
    @media (max-width: ${breakpointMax}) {
      padding: 0px;
    }

    & > div,
    text,
    tspan {
      font-family: "Mont" !important;
    }
  `,
  link: css`
    color: #22aa5f;
    font-size: 1em;
    font-weight: 600;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;

    :hover {
      color: #0d8e46;
    }

    :active {
      color: #075128;
    }
  `,
  noData: css`
    padding: 10px 0px;
    color: rgb(37, 54, 69);
    font-weight: 600;
    line-height: 20px;
  `,
  paddingTop: css`
    padding-top: 2em;
  `,
  buttonLink: css`
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #22aa5f;
    transition: all 0.5s ease;
    background: none;
    border: none;
    pointer: cursor;

    &:hover,
    &:focus {
      color: #0d8e46;
    }
  `,
};
