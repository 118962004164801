import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../../SecurityOverview/securityOverview.style';
import { Security } from '../../../../../interfaces';
import { marketRuleLabel, QuotingConvention, quotingConventionCheck, quotingConventionLabel } from '../../../../../enums';
import { SecurityMarketTradingATT } from './trading-att';
import { formatToThousandsSeparator } from '../../../../../utils';
import { constants } from 'http2';
import { marketTradingGroupLabel } from '../../../../../enums/market-trading-group';
import { marketTradingTypeLabel } from '../../../../../enums/market-trading-type';
import { marketPostTradeLabel } from '../../../../../enums/market-post-trade';
import { marketSegmentLabel } from '../../../../../enums/market-segment';

export interface SecurityMarketProps {
  securityData?: Security;
}

export const SecurityMarketTradingDefault: React.FunctionComponent<SecurityMarketProps> =
   ({ securityData }: SecurityMarketProps) => {

  const marketData = React.useMemo(() => securityData ? securityData.marketData : null, [securityData]);
  const professionalGroup = React.useMemo(() => 
    ["W5", "WI", "WK", "WL", "WJ", "W6", "WM", "WN", "Z1", "Z2", "Z3", "Z4"].includes(marketData?.tradingGroupCode), [marketData]
  );
  const segmentGroup = React.useMemo(() => 
    ["SSS"].includes(marketData?.segmentCode), [marketData]
  );
  const marketMembers = React.useMemo(() => {
    const map = new Map<string, string[]>();
    if(marketData.marketMembers === null) return map;
    for(let i = 0; i < marketData.marketMembers.length; i++) {
      if(!map.has(marketData.marketMembers[i].type)) {
        map.set(marketData.marketMembers[i].type, []);
      }
      map.get(marketData.marketMembers[i].type).push(marketData.marketMembers[i].libele);
    }
    return map;
  }, [marketData]);

  return (
    <>


      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Market</div>
        <div css={style.blocRowValue}>
          {securityData?.marketRule ? marketRuleLabel(securityData?.marketRule) : 'N/A'}
        </div>
      </div>
      { marketData?.mnemoCode ?
        <div css={style.blocRowSpace}>
          <div css={style.blocRowKey}>Mnemo</div>
          <div css={style.blocRowValue}>{marketData?.mnemoCode}</div>
        </div>
        : <></>
      }
      { professionalGroup ?
        <div css={style.blocRowSpace}>
          <div css={[style.blocRowKey, style.blocRowKeyRed]}>Professional Investors only</div>
        </div>
        : 
        <></>
      }
      { segmentGroup?
        <div css={style.blocRowSpace}>
          <div css={style.blocRowKey}>Segment</div>
          <div css={style.blocRowValue}>
            {marketData?.segmentCode ? marketSegmentLabel(marketData.segmentCode) : 'N/A'}
          </div>
        </div>
        : 
        <></>
      }
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Trading code</div>
        <div css={style.blocRowValue}>{marketData?.tradingCode || 'N/A'}</div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Trading type</div>
        <div css={style.blocRowValue}>
          {marketData?.tradingTypeCode ? marketTradingTypeLabel(marketData.tradingTypeCode) : 'N/A'}
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Trading lot</div>
        <div css={style.blocRowValue}>{marketData?.tradingLot || 'N/A'}</div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Trading group</div>
        <div css={style.blocRowValue}>
          {marketData?.tradingGroupCode ? marketTradingGroupLabel(marketData.tradingGroupCode) : 'N/A'}
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Post trade</div>
        <div css={style.blocRowValue}>
          { marketData?.cotaGroupIndicator && marketData?.cotaGroupGarIndicator ?
            marketPostTradeLabel(`${marketData.cotaGroupIndicator}_${marketData.cotaGroupGarIndicator}`)
            : 'N/A'
          }
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Trade indicator</div>
        <div css={style.blocRowValue}>
        { marketData?.tradeIndicator ?
          marketData?.tradeIndicator == '%' ? '%' : "Unit"
          : 'N/A'}</div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Tick size index ID</div>
        <div css={style.blocRowValue}>
          {marketData?.tickSizeID || 'N/A'}
        </div>
      </div>

      { securityData?.quotingConvention 
        && ! quotingConventionCheck( securityData?.quotingConvention?.toString(), QuotingConvention.NA )
        && marketData?.tradeIndicator != "%" 
        &&
        <div css={style.blocRowSpace}>
          <div css={style.blocRowKey}>Interest quoting convention</div>
          <div css={style.blocRowValue}>{quotingConventionLabel(securityData.quotingConvention)}</div>
        </div>
      }
      { marketData?.minimumAmountTrade &&
        <div css={style.blocRowSpace}>
          <div css={style.blocRowKey}>Minimum trading amount</div>
          <div css={style.blocRowValue}>{formatToThousandsSeparator(marketData.minimumAmountTrade)}</div>
        </div>
      }
      { marketMembers
        && marketMembers.size>0
        &&
        Array.from(marketMembers, ([type, libeles]) => <div key={`${type}`} css={style.blocRowSpace}>
          <div css={style.blocRowKey}>{type}</div>
          <div css={style.blocRowValue}>{libeles.map(libele => <div>{libele}</div>)}</div>
        </div>)
      }
    </>
  );
};
