/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {renderLink} from "../../utils/helper";
import { breakpointMax } from "../../style";

export interface ImageComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const ImageComponent: React.FunctionComponent<ImageComponentProps> = (
  props: ImageComponentProps
): React.ReactElement => {
  const { fields } = props;

  const maxWidth: string = getFieldValue<string>(fields, "maxWidth", "auto");
  const maxHeight: string = getFieldValue<string>(fields, "maxHeight", "auto");
  const centered: number = getFieldValue<number>(fields, "centered", 0);
  const rightAligned: number = getFieldValue<number>(fields, "rightAligned", 0);
  const image: any = getFieldValue<any>(fields, "image", null);

  const container = css`
    width: 100%;
    text-align: ${centered ? "center" : rightAligned ? "right" : "left"};
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 128px;
    padding-right: 128px;
  
    @media (max-width: ${breakpointMax}) {
      padding-left: 0px;
      padding-right: 0px;
    }
  `;
  const imageStyle = css`
    width: 100%;
    max-width: ${maxWidth}px;
    max-height: ${maxHeight}px;
    border-radius: 16px;
  `;
  
  return (
    <div css={container}>
      {image && image.src && <img src={renderLink(image.src)} css={imageStyle} alt={"image"} />}
    </div>
  );
};
