/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";

export interface CaseStudiesBoxContainer {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const CaseStudiesBoxContainer: React.FunctionComponent<CaseStudiesBoxContainer> =
  (props: CaseStudiesBoxContainer): React.ReactElement => {
    const { rendering } = props;

    const parentContainer = css`
      width: 100%;
      max-width: 780px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 8px 0;

      & > div {
        flex: 1 1 auto;

        &:only-child {
          width: 100%;
        }

        &:first-child:nth-last-child(2),
        &:last-child:nth-child(2) {
          width: calc(50% - 12px);
        }

        &:first-child:nth-last-child(3),
        &:nth-child(2):nth-last-child(2),
        &:last-child:nth-child(3) {
          width: calc(33.33% - 16px);
        }

        &:first-child:nth-last-child(4),
        &:nth-child(2):nth-last-child(3),
        &:nth-child(3):nth-last-child(2),
        &:last-child:nth-child(4) {
          width: calc(25% - 18px);
        }
      }

      @media (max-width: 500px) {
        & > div {
          width: calc(50% - 8px) !important;
          flex: 0 0 auto;

          &:only-child {
            width: 100% !important; // Override for single box
          }
        }
      }

      @media (max-width: ${breakpointMax}) {
        & > div {
          width: 100%;
        }
      }
    `;

    return (
      <div css={parentContainer}>
        <Placeholder
          name="jss-case-studies-box-container"
          rendering={rendering}
        />
      </div>
    );
  };
